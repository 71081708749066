import React from 'react';
import cx from 'classnames';

const StatusLabel = ({ color, text, icon }) => {
  let iconType;
  // TODO: refactor to svg sprite and use type;
  if (color === 'red') iconType = 'triangle-error';
  if (color === 'red-opacity') iconType = 'triangle-red';
  if (color === 'gray-blue') iconType = 'triangle-info';
  if (color === 'gray') iconType = 'gray-check';
  if (color === 'gray-green') iconType = 'gray-check';
  if (color === 'blue') iconType = 'lock-blue';
  if (color === 'green') iconType = 'lock-green';
  if (color === 'disabled') iconType = 'lock';

  return (
    <div className={cx('status-label', color)}>
      {icon && <img src={`/icons/${iconType}.svg`} />}
      <span>{text}</span>
    </div>
  );
};

export default StatusLabel;

// TODO: usage
// <DashboardStatusLabel icon text="Очікується оплата" color="red" />
// <DashboardStatusLabel icon text="Оплачено 21 серпня" color="gray" />
// <DashboardStatusLabel text="5 точок підключення" color="green" />
// <DashboardStatusLabel text="5 точок підключення" color="blue" />
