import { Link } from 'react-router-dom';
import React from 'react';

const EmptyDocuments = ({ energy }) => {
  return (
    <div className="empty-profile">
      <div className="empty-profile__top">
        <img className="" src={`/icons/${energy ? 'confirm.svg' : 'confirm1.svg'}`} />
        <div className={`empty-profile__title ${energy && 'empty-profile__title--energy'}`}>
          Данні відсутні
        </div>
      </div>
    </div>
  );
};

export default EmptyDocuments;
