import { middlewareUrl } from './config';
import { instanceFetch } from '../utils/instanceFetch';

const ROUTES = {
  REGIONS: new URL(`${middlewareUrl}/regions`),
};

const regionsService = () => {
  return instanceFetch.get(ROUTES.REGIONS).then((response) => {
    return {
      regions: response,
    };
  });
};

export { regionsService };
