import React from 'react';
import { Link } from 'react-router-dom';

const SidebarStep2B2b = () => {
  return (
    <div className="registration-sidebar">
      <Link to="/" className="reg-sidebar-back-to">
        <img src="/icons/arrow-back.svg" />
        <span>Назад</span>
      </Link>

      <span className="reg-sidebar-title">Розпочинаємо реєстрацію</span>
      <span className="reg-sidebar-text">Як і обіцяли, реєстрація займе зовсім небагато часу.</span>
      <span className="reg-sidebar-text">
        Обирайте зручний варіант: створюйте особистий кабінет за номером вашого мобільного або
        електронною поштою, які зазначено в договорі.
      </span>
    </div>
  );
};

export default SidebarStep2B2b;
