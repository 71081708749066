import { middlewareUrl } from './config';
import { instanceFetch } from '../utils/instanceFetch';
import authHeader from '../utils/authHeader';

const ROUTES = {
  COMPANIES: new URL(`${middlewareUrl}/companies`),
  B2B_GAS: new URL(`${middlewareUrl}/gas-b2b/account-company/store`),
  B2B_ENERGY: new URL(`${middlewareUrl}/e-b2b/account-company/store`),
};

const getCompaniesService = () => {
  return instanceFetch.get(ROUTES.COMPANIES).then((response) => {
    return response;
  });
};

const getGasB2BCompaniesService = (payload) => {
  return instanceFetch.post(ROUTES.B2B_GAS, payload, authHeader()).then(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject({
        ...error,
      });
    }
  );
};

const getEnergyB2BCompaniesService = (payload) => {
  return instanceFetch.post(ROUTES.B2B_ENERGY, payload, authHeader(), { mode: 'opaque' }).then(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject({
        ...error,
      });
    }
  );
};

export { getCompaniesService, getGasB2BCompaniesService, getEnergyB2BCompaniesService };
