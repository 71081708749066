import React from 'react';
import SidebarStep4B2b from './SidebarStep4B2b';
import ContentStep4B2b from './ContentStep4B2b';

const RegistrationStep4B2b = ({ handleStep }) => {
  return (
    <>
      <SidebarStep4B2b handleStep={handleStep} />
      <ContentStep4B2b handleStep={handleStep} />
    </>
  );
};

export default RegistrationStep4B2b;
