import React from 'react';
import DashboardContractData from './DashboardContractData';
import DashboardHistory from './DashboardHistory';
import DistributorPaymentHistory from './DistributorPaymentHistory';

const DashboardGeneralInfo = () => {
  return (
    <>
      <div className="dashboard-contract">
        <DashboardContractData isGeneralTab />
      </div>
      <div className="dashboard-contract">
        <DashboardHistory />
      </div>
    </>
  );
};

export default DashboardGeneralInfo;
