import { isNull } from 'lodash';

const renderErrors = (form, errorMessages) => {
  if (isNull(errorMessages)) return;

  const checkErrors = Object.keys(form.getFieldsValue()).reduce((arr, value) => {
    if (errorMessages[value]) {
      arr.push({
        name: value,
        errors: [errorMessages[value]],
      });
    }

    return arr;
  }, []);

  form.setFields(checkErrors);
};

export const base64toBlob = (data) => {
  const pdfContentType = 'application/pdf';

  // Cut the prefix `data:application/pdf;base64` from the raw base 64
  const base64WithoutPrefix = data.substr(`data:${pdfContentType};base64,`.length);

  const bytes = atob(base64WithoutPrefix);
  let length = bytes.length;
  let out = new Uint8Array(length);

  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }

  return new Blob([out], { type: pdfContentType });
};

export default renderErrors;
