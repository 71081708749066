import React from 'react';
import FormDropdown from '../../common/FormDropdown/FormDropdown';
import InputText from '../../common/Inputs/InputText';
import Button from '../../common/Button/Button';

const contractArr = ['Договір 0017', 'Договір 0018', 'Договір 0019', 'Договір 0011', 'Договір 009'];
const periodYearArr = ['2021', '2020', '2019', '2018', '2017', '2016'];
const periodMonthArr = ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень'];

const AccountManagement = () => {
  return (
    <div className="account-management">
      <div className="order-gas-filter box-shadow">
        <span className="order-gas-filter-title">Керувати рахунками</span>
        <span className="order-gas-filter-sub-title">
          Сформуйте рахунок для оплати за споживання газу.
        </span>
        <span className="order-gas-filter-sub-title">
          Для кожного договору потрібно сформувати окремий рахунок.
        </span>

        <form className="form-selected-filter">
          <FormDropdown defaultValue="Договір" options={contractArr} />
          <FormDropdown defaultValue="Рік" options={periodYearArr} />
          <FormDropdown defaultValue="Місяць" options={periodMonthArr} />

          <button type="button" className="underline-link">
            Очистити фільтр
          </button>
        </form>
      </div>

      <div className="box-shadow box-shadow-padding">
        <div className="dashboard-contract-header__left">
          <div className="dashboard-contract-header__inner">
            <div className="dashboard-contract-header__title">
              Договір <span>№ 00354</span>
            </div>
          </div>

          <div className="dashboard-contract-header__date">Від 16.03.2019 р.</div>
        </div>
        <div className="amount-to-pay">
          <div className="amount-to-pay__title">Сума до оплати:</div>

          <InputText value="80" prefix={<div className="currency">грн.</div>} />

          {/*<div className="amount-to-pay__amount">*/}
          {/*  80 <span className='amount-to-pay__currency'>грн.</span>*/}
          {/*</div>*/}

          <Button type="primary" text="Сплатити" />
          <Button
            iconLeft="download2"
            className="dashboard-download"
            type=""
            text="Завантажити рахунок"
          />
        </div>
      </div>
    </div>
  );
};

export default AccountManagement;
