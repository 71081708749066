import React, { useMemo, useState } from 'react';
import cx from 'classnames';
import TabLabel from '../../common/Tab/TabLabel';
import DashboardContractInfo from './DashboardContractInfo';
import { contractInfo1, contractInfo2, contractInfo3 } from '../DashboardGas/dashbordContract';

const DashboardContractData = ({ isGeneralTab = false, documentsArr }) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const previousMonth = currentDate.getMonth();

  const periodTab = [
    {
      label: 'Загальний період',
      content: () => (
        <DashboardContractInfo
          date={`${currentYear}${currentMonth}`}
          activeTab={activeTab}
          totalDate={true}
          isGeneralTab={isGeneralTab}
          documentsArr={documentsArr}
        />
      ),
    },
    {
      label: 'Попередній місяць',
      content: () => (
        <DashboardContractInfo
          date={`${currentYear}${previousMonth}`}
          activeTab={activeTab}
          isGeneralTab={isGeneralTab}
          documentsArr={documentsArr}
        />
      ),
    },
    {
      label: 'Поточний місяць',
      content: () => (
        <DashboardContractInfo
          date={`${currentYear}${currentMonth}`}
          activeTab={activeTab}
          isGeneralTab={isGeneralTab}
          documentsArr={documentsArr}
        />
      ),
    },
  ];

  const [activeTab, setActiveTab] = useState(0);

  const Content = useMemo(() => {
    return periodTab[activeTab].content;
  }, [activeTab, periodTab]);

  return (
    <div className={cx('dashboard-contract-data', { 'general-info': isGeneralTab })}>
      <div className="dashboard-contract-data__header">
        <div className="dashboard-contract-data__title">Дані по всім договорам</div>

        <div className="tab-label-list skew">
          {periodTab.map(({ label }, index) => (
            <TabLabel
              type="secondary"
              key={index}
              label={label}
              onClick={() => setActiveTab(index)}
              active={activeTab === index}
            />
          ))}
        </div>
      </div>
      <Content />
    </div>
  );
};

export default DashboardContractData;
