import { PROFILE_ACTIONS } from '../types/types';
import { profileGetService, profileUserUpdateService } from '../../services/profile';

const getProfile = () => {
  return (dispatch) => {
    return profileGetService().then((response) => {
      dispatch({
        type: PROFILE_ACTIONS.GET,
        payload: {
          profile: response,
        },
      });
    });
  };
};

const updateUserProfile = (payload) => {
  return (dispatch) => {
    return profileUserUpdateService(payload).then((response) => {
      dispatch({
        type: PROFILE_ACTIONS.USER_UPDATE,
        payload: response,
      });

      return Promise.resolve(response);
    });
  };
};

export { getProfile, updateUserProfile };
