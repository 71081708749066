import { AUTH_ACTIONS } from '../types/types';
import { loginOtpSendMessageService, loginOtpService, loginService } from '../../services/auth';
import { defaultState } from '../index';

const success = (payload) => {
  const auth = {
    pending: false,
    error: false,
    token: payload.token,
    user: payload.user,
  };
  localStorage.setItem('auth', JSON.stringify(auth));

  return {
    type: AUTH_ACTIONS.LOGIN_SUCCESS,
    payload: {
      auth: auth,
    },
  };
};

const failure = (payload) => {
  return {
    type: AUTH_ACTIONS.LOGIN_FAILURE,
    payload: {
      auth: {
        pending: false,
        error: payload.error,
        token: null,
        user: null,
      },
    },
  };
};

const login = (payload) => {
  return (dispatch) => {
    return loginService({
      ...payload,
      app_code: '1',
    }).then(
      (response) => {
        dispatch(
          success({
            ...response,
            user: payload.login,
          })
        );

        return Promise.resolve();
      },
      (error) => {
        dispatch(
          failure({
            error: error,
          })
        );

        return Promise.reject(error);
      }
    );
  };
};

const loginOtp = (payload) => {
  return (dispatch) => {
    return loginOtpService({
      ...payload,
    }).then(
      (response) => {
        dispatch(
          success({
            ...response,
            user: payload.login,
          })
        );

        return Promise.resolve();
      },
      (error) => {
        dispatch(
          failure({
            error: error,
          })
        );

        return Promise.reject(error);
      }
    );
  };
};

const loginOtpSendMessage = (payload) => {
  return (dispatch) => {
    return loginOtpSendMessageService({
      ...payload,
    }).then(
      (response) => {
        dispatch(
          success({
            ...response,
            user: payload.login,
          })
        );

        return Promise.resolve();
      },
      (error) => {
        dispatch(
          failure({
            error: error,
          })
        );

        return Promise.reject(error);
      }
    );
  };
};

const logout = () => {
  localStorage.removeItem('auth');
  return (dispatch) => {
    dispatch({
      type: AUTH_ACTIONS.LOGOUT,
      payload: defaultState.application,
    });
    return Promise.resolve();
  };
};

export { login, loginOtp, loginOtpSendMessage, logout };
