import React, { useState } from 'react';
import FormDropdown from '../../common/FormDropdown/FormDropdown';
import NotificationModalItem from './NotificationModalItem';
import DrawerModal from '../../common/Drawer/Drawer';

const operatorArr = ['Всі договори', 'Договір 1', 'Договір 2', 'Договір 3'];

const MyNotification = ({ handleClick, openModal }) => {
  return (
    <DrawerModal
      width={379}
      placement="right"
      title="Мої сповіщення"
      iconTitle={<img src="/icons/notification.svg" alt="notification-icon" />}
      isDrawerVisible={openModal}
      handleClick={handleClick}
      className="notification-drawer"
    >
      <FormDropdown defaultValue="Всі договори" options={operatorArr} />

      <NotificationModalItem
        time="від 29 серпня 2020 року"
        title="12 вересня спливає термін дії договору"
        subTitle="Договір № 000013"
        more
        btn="Пролонгувати договір"
      />
      <NotificationModalItem
        time="від 9 серпня 2020 року"
        title="Рахунок сформовано"
        subTitle="Договір № 000015"
        btn="Оплатити"
      />
      <NotificationModalItem
        time="від 1 серпня 2020 року"
        title="У команду додано нового члена"
        subTitle="Ви успішно додали у команду Кудряшкіну Марію."
        moreFooter
      />
    </DrawerModal>
  );
};

export default MyNotification;
