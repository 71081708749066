import React from 'react';
import Switch from '../../common/Switch/Switch';
import { Link } from "react-router-dom";

const NotificationTab = () => {
  return (
    <div className="profile">
      <div className="tab-label-list">
        <Link to="/profile/my-profile" className="tab-label primary">
          <span className="tab-label__text">Мій профіль</span>
        </Link>
        <Link to="/profile/change-password" className="tab-label primary">
          <span className="tab-label__text">Змінити пароль</span>
        </Link>
        <Link to="/profile/companies" className="tab-label primary">
          <span className="tab-label__text">Мої компанії</span>
        </Link>
        <div className="tab-label primary active">
          <span className="tab-label__text">Сповіщення</span>
        </div>
      </div>
      <div className="profile-tab-content">
        <div className="notification-tab">
      <span className="notification-tab-title">
        Оберіть, яку актуальну інформацію ви б хотіли отримувати на свій email:
      </span>
          <div className="notification-tab-checkbox-list">
            <Switch text="Важливі сповіщення" />
            <Switch text="Новини компанії" />
            <Switch text="Документи" />
          </div>
        </div>
      </div>
    </div>

  );
};

export default NotificationTab;
