import React from 'react';
import { Checkbox, Form } from 'antd';

const CheckboxField = (props) => {
  const { name, rules, text, disabled } = props;

  return (
    <div className="field checkbox-field">
      <Form.Item name={name} valuePropName="checked" rules={rules}>
        <Checkbox disabled={disabled}>{text}</Checkbox>
      </Form.Item>
    </div>
  );
};

export default CheckboxField;
