import { USER_INFO_ACTIONS } from '../types/types';
import { getUserInfoService } from '../../services/userInfo';

const getUserInfo = () => {
  return (dispatch) => {
    return getUserInfoService().then((response) => {
      dispatch({
        type: USER_INFO_ACTIONS.GET,
        payload: {
          userInfo: response,
        },
      });
    });
  };
};

export { getUserInfo };
