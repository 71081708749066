import React, { useState } from 'react';
import { Table } from 'antd';
import FormDropdown from '../../common/FormDropdown/FormDropdown';
import { Link } from 'react-router-dom';

const downloadReceiptBtn = (
  // TODO: Mayby replace to text iconed btn
  <div className="download-receipt">
    <img src="/icons/download.svg" />
    <span>завантажити</span>
  </div>
);

const columns = [
  {
    title: 'Дата',
    dataIndex: 'date',
    className: 'actDate',
  },
  {
    title: 'Назва документу',
    dataIndex: 'title',
    className: 'title',
  },
  {
    title: 'Дії з документом',
    dataIndex: 'document',
    className: 'documents',
  },
];

const contractArr = ['Договір 0017', 'Договір 0018', 'Договір 0019', 'Договір 0011', 'Договір 009'];
const periodArr = ['2021', '2020', '2019', '2018', '2017', '2016'];

const DocumentsAdditionalAgreements = ({ setSelectedItem, tableData }) => {
  const signButton = (
    <button className="btn primary btn--sign" onClick={() => setSelectedItem(true)}>
      <span>Підписати</span>
    </button>
  );

  const data = [];
  for (let i = 0; i < 1; i++) {
    data.push({
      key: i,
      number: `ДУ1- ${i}`,
      contract: `ДУ1- ${i}`,
      date: '02.05.2022',
      gasVolume: `890,56 - м³`,
      document: signButton,
    });
  }

  return (
    <>
      {/*<form className="form-selected-filter">
        <FormDropdown defaultValue="Договір" options={contractArr} />
        <FormDropdown defaultValue="Період" options={periodArr} />

        <button type='button' className="underline-link">Очистити фільтр</button>
      </form>*/}
      <div className="scroll-wrap">
        <div className="custom-ant-table">
          <Table
            className="additional-agreement"
            bordered
            columns={columns}
            dataSource={tableData}
          />
        </div>
      </div>
    </>
  );
};

export default DocumentsAdditionalAgreements;
