import React from 'react';
import { Form, Select } from 'antd';
import InputPhone from '../../../common/Inputs/InputPhone';
import FieldEmail from '../FieldEmail';
const { Option } = Select;

const TypeSignInOtp = ({ options, selectedOption, setSelectedOption }) => {
  return (
    <>
      <div className="field">
        <Form.Item label="Увійти за допомогою:">
          <Select
            defaultValue={options[0].name}
            suffixIcon={<img src="/icons/arrow-select.svg" alt="arrow-icon" />}
            onChange={(value) => {
              setSelectedOption(value);
            }}
          >
            {options.map((option, index) => (
              <Option key={index} value={option.value}>
                {option.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      {selectedOption === options[0].value && <FieldEmail name="login" />}
      {selectedOption === options[1].value && <InputPhone name="login" />}
    </>
  );
};

export default TypeSignInOtp;
