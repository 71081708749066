import React from 'react';
import { NavLink } from 'react-router-dom';
import SidebarSupport from './SidebarSupport';
import cx from 'classnames';
import { connect } from 'react-redux';

const nav = [
  {
    link: '/gas-b2c/dashboard',
    icon: 'bill',
    name: 'Рахунки за газ',
  },
  {
    link: `/gas-b2c/documents`,
    icon: 'documents',
    name: 'Документи',
    disabled: true,
  },
  {
    link: `/gas-b2c/analytics`,
    icon: 'dashboard',
    name: 'Аналітика',
  },
  {
    link: '/gas-b2c/address',
    icon: 'union',
    name: 'Мої адреси',
  },
  {
    link: `/gas-b2c/requests`,
    icon: 'request',
    name: 'Мої заявки',
  },
];

const handleClick = (e, disabled) => {
  if (disabled) e.preventDefault();
};

const SidebarGasB2C = (userInfo) => {
  return (
    <aside className="sidebar">
      <div className="sidebar__nav">
        {nav.map(({ link, icon, name, disabled = !userInfo.userInfo.gasB2c }, index) => (
          <NavLink
            onClick={(e) => handleClick(e, disabled)}
            to={link}
            className={cx('sidebar-link', { disabled })}
            key={index}
          >
            <div className="sidebar-link__box">
              <img src={`/icons/${icon}.svg`} alt={icon} />
            </div>
            <span className="nav-skew">{name}</span>
          </NavLink>
        ))}
      </div>

      <SidebarSupport />
    </aside>
  );
};

const mapStateToProps = (store) => {
  const { application } = store;
  return {
    userInfo: application.userInfo,
  };
};

export default connect(mapStateToProps)(SidebarGasB2C);
