import React from 'react';
import SidebarSignIn from './SignIn/SidebarSignIn';
import ContentSignIn from './SignIn/ContentSignIn';

const SignIn = () => {
  return (
    <div className="registration">
      <SidebarSignIn />
      <ContentSignIn />
    </div>
  );
};

export default SignIn;
