import React, { useState, useCallback } from 'react';

const UploadFile = ({ label }) => {
  const [fileName, setFileName] = useState(false);

  const handleChange = useCallback((event) => {
    const file = event.target.files[0];
    setFileName(file.name);
  }, []);

  return (
    <div className="upload-file__wrapper">
      <input type="file" id="upload-file__input" onChange={handleChange} />
      <label className="upload-file__label" htmlFor="upload-file__input">
        {fileName ? <span>{fileName}</span> : <span>{label}</span>}
      </label>
    </div>
  );
};

export default UploadFile;
