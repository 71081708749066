const AUTH_ACTIONS = {
  LOGIN_PENDING: 'LOGIN_PENDING',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGOUT: 'LOGOUT',
};

const PROFILE_ACTIONS = {
  GET: 'PROFILE_GET',
  USER_UPDATE: 'PROFILE_USER_UPDATE',
};

const REGISTER_ACTIONS = {
  SUCCESS: 'REGISTER_SUCCESS',
  FAILURE: 'REGISTER_FAILURE',
};

const REFERENCE_BOOKS_ACTIONS = {
  REGIONS: 'REFERENCE_BOOK_REGIONS',
  ALL: 'REFERENCE_BOOK_ALL',
};

const USER_INFO_ACTIONS = {
  GET: 'USER_INFO_GET',
};

const COMPANIES = {
  GET: 'COMPANIES',
  GET_B2B: 'COMPANIES',
};

export {
  AUTH_ACTIONS,
  PROFILE_ACTIONS,
  REGISTER_ACTIONS,
  REFERENCE_BOOKS_ACTIONS,
  USER_INFO_ACTIONS,
  COMPANIES,
};
