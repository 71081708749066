import React from 'react';
import Button from '../../common/Button/Button';

const NotificationModalItem = ({ time, title, subTitle, btn, more, moreFooter }) => {
  return (
    <div className="notification-modal-item">
      <span className="notification-item-time">{time}</span>
      <span className="notification-item-title">{title}</span>
      <span className="notification-item-sub-title">{subTitle}</span>
      {more && (
        <a href="/" className="notification-item-more">
          Детальніше
        </a>
      )}

      <div className="notification-item-footer">
        {moreFooter && (
          <a href="/" className="notification-item-more more-footer">
            Детальніше
          </a>
        )}
        {btn && <Button type="primary" text={btn} />}
      </div>
    </div>
  );
};

export default NotificationModalItem;
