import React from 'react';
import { Form, Input } from 'antd';

const FieldEmail = ({
  name,
  label = 'E-mail',
  rules,
  disabled,
  withLink,
  onClickLink,
  isValue,
}) => {
  if (withLink) {
    return (
      <div className="field field--edited">
        <Form.Item>
          <Form.Item name={name} label={label} className="field__wrapper">
            <Input
              className="field__input"
              placeholder="Електронна пошта"
              prefix={<img src="/icons/email1.svg" />}
              disabled={disabled}
            />
          </Form.Item>

          <button type="button" className="field__edit" onClick={onClickLink}>
            {isValue ? 'Змінити' : 'Додати'}
          </button>
        </Form.Item>
      </div>
    );
  }

  return (
    <div className="field">
      <Form.Item name={name} rules={rules} label={label}>
        <Input
          className="field__input"
          placeholder="Електронна пошта"
          prefix={<img src="/icons/email1.svg" />}
          disabled={disabled}
        />
      </Form.Item>
    </div>
  );
};

FieldEmail.defaultProps = {
  name: 'email',
  rules: [
    {
      required: true,
      type: 'email',
      message: 'Не валідний e-mail',
    },
  ],
};

export default FieldEmail;
