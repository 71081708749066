import React, { useState } from 'react';
import ForgotPasswordSendOtp from './ForgotPasswordSendOtp';
import ForgotPasswordOtpReset from './ForgotPasswordOtpReset';

const ContentForgotPassword = () => {
  const options = [
    {
      name: 'E-mail',
      value: 1,
    },
    {
      name: 'Телефон',
      value: 2,
    },
  ];

  const [selectedOption, setSelectedOption] = useState(options[0].value);
  const [stepForgotPassword, setStepForgotPassword] = useState(0);
  const [forgotPasswordValue, setForgotPasswordValue] = useState('');

  const stepForgotPasswordMap = {
    0: (
      <ForgotPasswordSendOtp
        options={options}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        setForgotPasswordValue={setForgotPasswordValue}
        setStepForgotPassword={setStepForgotPassword}
      />
    ),
    1: (
      <ForgotPasswordOtpReset
        forgotPasswordValue={forgotPasswordValue}
        options={options}
        selectedOption={selectedOption}
      />
    ),
  };

  return <div className="registration-content">{stepForgotPasswordMap[stepForgotPassword]}</div>;
};

export default ContentForgotPassword;
