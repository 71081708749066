import React, { useState } from 'react';
import NewsNotification from './NewsNotification';
import MyNotification from './MyNotification';

const NotificationBlock = () => {
  const [openNewsModal, setOpenNewsModal] = useState(false);
  const [openMyNotifyModal, setOpenMyNotifyModal] = useState(false);

  const handleNewsClick = () => {
    setOpenNewsModal(!openNewsModal);
  };

  const handleMyNotifyClick = () => {
    setOpenMyNotifyModal(!openMyNotifyModal);
  };

  return (
    <>
      {/*<button type="button" className="header__box" onClick={handleNewsClick}>*/}
      {/*  <img src="/icons/message.svg" alt="message-icon" />*/}
      {/*</button>*/}

      {/*<button type="button" className="header__box" onClick={handleMyNotifyClick}>*/}
      {/*  <img src="/icons/notification.svg" alt="notification-icon" />*/}
      {/*</button>*/}

      <NewsNotification openModal={openNewsModal} handleClick={handleNewsClick} />
      <MyNotification openModal={openMyNotifyModal} handleClick={handleMyNotifyClick} />
    </>
  );
};

export default NotificationBlock;
