import { REGISTER_ACTIONS } from '../types/types';
import {
  registerService,
  registerServiceAccountAddress,
  registerServiceOtp,
} from '../../services/register';

const success = (payload) => {
  const auth = {
    pending: false,
    error: false,
    token: payload.access_token,
    user: payload.user,
  };
  localStorage.setItem('auth', JSON.stringify(auth));

  return {
    type: REGISTER_ACTIONS.SUCCESS,
    payload: {
      auth: auth,
    },
  };
};

const failure = (payload) => {
  return {
    type: REGISTER_ACTIONS.FAILURE,
    payload: {
      auth: {
        pending: false,
        error: payload.error,
        token: null,
        user: null,
      },
    },
  };
};

const failureAddress = (payload) => {
  return {
    type: REGISTER_ACTIONS.FAILURE,
    payload: {
      auth: {
        pending: false,
        error: payload.error,
      },
    },
  };
};

const register = (payload) => {
  return (dispatch) => {
    return registerService({
      ...payload,
      app_code: '1',
    }).then(
      (response) => {
        dispatch(
          success({
            ...response,
            token: payload.access_token,
            user: payload.login,
          })
        );

        return Promise.resolve();
      },
      (error) => {
        dispatch(
          failure({
            error: error,
          })
        );

        return Promise.reject(error);
      }
    );
  };
};

const registerOtp = (payload) => {
  return (dispatch) => {
    return registerServiceOtp({
      ...payload,
    }).then(
      (response) => {
        dispatch(
          success({
            ...response,
            token: payload.access_token,
            user: payload.login,
          })
        );

        return Promise.resolve();
      },
      (error) => {
        dispatch(
          failure({
            error: error,
          })
        );

        return Promise.reject(error);
      }
    );
  };
};

const registerAccountAddress = (payload) => {
  return (dispatch) => {
    return registerServiceAccountAddress({
      ...payload,
    }).then(
      (response) => {
        dispatch(
          success({
            ...response,
          })
        );

        return Promise.resolve();
      },
      (error) => {
        dispatch(
          failureAddress({
            error: error,
          })
        );

        return Promise.reject(error);
      }
    );
  };
};

export { register, registerOtp, registerAccountAddress };
