import React from 'react';
import DashboardContractData from './DashboardContractData';
import DashboardHistory from './DashboardHistory';
import DistributorPaymentHistory from './DistributorPaymentHistory';

const DashboardGeneralInfo = ({ documentsArr }) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  return (
    <>
      <div className="dashboard-contract">
        <DashboardContractData isGeneralTab documentsArr={documentsArr} />
      </div>
      <div className="dashboard-contract">
        <DashboardHistory documentsArr={documentsArr} date={`${currentYear}${currentMonth}`} />
      </div>
      {/*<div className="dashboard-contract">
        <DistributorPaymentHistory />
      </div>*/}
    </>
  );
};

export default DashboardGeneralInfo;
