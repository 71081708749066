import React, { useEffect, useState } from 'react';
import FormDropdown from '../../common/FormDropdown/FormDropdown';
import { Form } from 'antd';
import {
  getMonth,
  getYear,
  set,
  isThisMonth,
  formatISO,
  format,
  eachMonthOfInterval,
} from 'date-fns';
import { uk } from 'date-fns/locale';
import { instanceFetch } from '../../utils/instanceFetch';
import { middlewareUrl } from '../../services/config';
import authHeader from '../../utils/authHeader';
import EmptyData from '../../components/EmptyData/EmptyData';

let contractArr = [];
const periodYearArr = ['2022', '2023'];
const periodMonthArr = [
  'Січень',
  'Лютий',
  'Березень',
  'Квітень',
  'Травень',
  'Червень',
  'Липень',
  'Серпень',
  'Вересень',
  'Жовтень',
  'Листопад',
  'Грудень',
];
// const pointArr = ['Точка  0017', 'Точка  0018', 'Точка  0019', 'Точка  0011', 'Точка  009'];

const OrderGasFilter = ({ orderFilter, setOrderFilter, resetForm, setResetForm, contractsArr }) => {
  const [form] = Form.useForm();
  const currentDate = new Date();
  const currentMonth = getMonth(currentDate);
  const currentYear = getYear(currentDate);
  const localMonth = format(currentDate, 'LLLL', { locale: uk });
  const filteredMonth = periodMonthArr.filter((i, index) =>
    currentYear < orderFilter.filterYear ? i : index >= currentMonth
  );
  const lastMonthOfCurrentYear =
    orderFilter.filterYear <= currentYear && orderFilter.filterIndex < currentMonth;

  if (lastMonthOfCurrentYear) {
    setOrderFilter((currVal) => ({
      ...currVal,
      filterIndex: currentMonth,
      filterMonth: localMonth.charAt(0).toUpperCase() + localMonth.slice(1),
    }));
    form.setFieldsValue({
      month: localMonth.charAt(0).toUpperCase() + localMonth.slice(1),
    });
  }

  const resetFilterForm = () => {
    setResetForm(false);
    setOrderFilter({});
    form.setFieldsValue({
      contract: 'Договір',
      year: 'Рiк',
      month: 'Місяць',
    });
  };

  if (resetForm) {
    resetFilterForm();
  }

  return contractsArr ? (
    <div className="order-gas-filter box-shadow">
      <span className="order-gas-filter-title">Замовити газ</span>
      <span className="order-gas-filter-sub-title">
        Ви можете замовити газ або скорегувати його об'єм залежно від обраного місяця як для кожної
        точки окремо, так і для всіх точок у рамках договору.
      </span>
      <span className="order-gas-filter-sub-title">
        Звертаємо увагу, що за розбіжність обсягів замовлення й фактичного споживання ми вимушені
        будемо накласти штраф, тому під час замовлення обачно прорахуйте потрібні об'єми.
      </span>
      <Form
        form={form}
        name="order-gas"
        className="form-selected-filter"
        initialValues={{
          contract: 'Договір',
          year: 'Рiк',
          month: 'Місяць',
        }}
      >
        <FormDropdown
          name="contract"
          options={contractsArr}
          handleSelectChange={(value) =>
            setOrderFilter((currValue) => ({ ...currValue, filterContract: value.slice(9) }))
          }
        />
        <FormDropdown
          name="year"
          options={periodYearArr}
          handleSelectChange={(value) =>
            setOrderFilter((currValue) => ({ ...currValue, filterYear: Number(value) }))
          }
        />
        <FormDropdown
          name="month"
          options={filteredMonth}
          handleSelectChange={(value) =>
            setOrderFilter((currValue) => ({
              ...currValue,
              filterMonth: value,
              filterIndex: periodMonthArr.findIndex((month) => month === value),
            }))
          }
        />

        <button type="button" className="underline-link" onClick={resetFilterForm}>
          Очистити фільтр
        </button>
      </Form>
    </div>
  ) : (
    <EmptyData />
  );
};

export default OrderGasFilter;
