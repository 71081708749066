import React from 'react';

const DashboardContractInfo = ({ data, isGeneralTab }) => {
  return (
    <div className="dashboard-contract-info">
      {isGeneralTab && (
        <div className="dashboard-contract-info-block">
          <div className="dashboard-contract-info-block__title">Договори</div>
          <div className="dashboard-contract-info-block__inner black">
            <span className="dashboard-contract-info-block__amount">1</span>
          </div>
        </div>
      )}
      {data.map(({ title, amount, currency, color, key }) => (
        <div className="dashboard-contract-info-block" key={key}>
          <div className="dashboard-contract-info-block__title">{title}</div>
          <div className={`dashboard-contract-info-block__inner ${color}`}>
            {color === 'red' && (
              <img
                className="dashboard-contract-info-block__arrow"
                src="/icons/arrow-up-long.svg"
                alt="info-icon"
              />
            )}
            <span className="dashboard-contract-info-block__amount">{amount}</span>
            <span className="dashboard-contract-info-block__currency">{currency}</span>
            {color === 'red' && (
              <>
                <img
                  className="dashboard-contract-info-block__error"
                  src="/icons/triangle-red.svg"
                  alt="info-icon"
                />
              </>
            )}
            {color === 'red' && (
              <button type="button" className="underline-link">
                Корегувати
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default DashboardContractInfo;
