import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { isEqual, isNull, isUndefined } from 'lodash';
import './assets/styles/styles.scss';
import { Redirect, Route, Switch, useLocation, withRouter } from 'react-router-dom';
import Routes from './Routes';
import cx from 'classnames';
import Sidebar from './components/Sidebar/Sidebar';
import Header from './components/Header/Header';
import SidebarGasB2C from './components/Sidebar/SidebarGasB2C';
import SidebarProfile from './components/Sidebar/SidebarProfile';
import { allReferences } from './store/actions/referenceBooks';
import { getProfile } from './store/actions/profile';
import { getUserInfo } from './store/actions/userInfo';
import { defaultState } from './store';
import { ROUTE_HOME, ROUTES_BOT, ROUTES_UNAUTHORIZAED_ALLOWED } from './constants';
import { Spin } from 'antd';

export const pageName = ['/', 'gas-b2b', 'energiya', 'b2b-bot-auth'];

export const checkRoute = (pathname) => {
  const getPath = pathname.split('/')[1];

  // TODO: set normal name to page;
  // if (pathname === '/gas-b2b' || pathname === '/energiya') return 'dashboard';

  return pageName.includes(getPath) && `${getPath}-page`;
};

const AppLayout = (props) => {
  const {
    allReferencesAction,
    getProfileAction,
    getUserInfoAction,
    auth,
    profile,
    userInfo,
    location,
  } = props;

  const [loader, setLoader] = useState(true);

  useEffect(() => {
    allReferencesAction();
  }, []);

  useEffect(() => {
    if (!isNull(auth.token) && !isUndefined(auth.token)) {
      getProfileAction();
      getUserInfoAction();
    }
  }, [auth]);

  useEffect(() => {
    if (ROUTES_UNAUTHORIZAED_ALLOWED.includes(location.pathname)) setLoader(false);
    if (isNull(auth.token) && ROUTES_UNAUTHORIZAED_ALLOWED.includes(location.pathname) === false)
      return (window.location = '/');
    // if (isNull(auth.token) || ROUTES_UNAUTHORIZAED_ALLOWED.includes(location.pathname)) return null;

    const checkProfile = isEqual(profile, defaultState.application.profile);
    const checkUserInfo = isEqual(userInfo, defaultState.application.userInfo);

    if (!checkProfile && !checkUserInfo) setLoader(false);
  }, [profile, userInfo]);

  const { pathname } = useLocation();
  const getPath = pathname.split('/')[1];

  const pageClassName = cx('page', checkRoute(pathname));

  const renderSidebar = useMemo(() => {
    if (getPath === 'profile') return <SidebarProfile />;
    if (getPath === 'gas-b2b' || getPath === 'energiya')
      return (
        <Sidebar type={getPath === 'gas-b2b' ? 'gas' : 'energiya'} userInfo={props.userInfo} />
      );
    else return null;
  }, [pathname]);

  const [windowDimension, setWindowDimension] = useState(null);

  useEffect(() => {
    setWindowDimension(window.innerWidth);
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isTablet = windowDimension <= 1279;

  if (loader) return <Spin />;

  return (
    <div className={pageClassName}>
      {getPath !== 'b2b-bot' && <Header />}

      <div className="page__container">
        {!isTablet && renderSidebar}

        <section className="page__content">
          <Switch>
            <Routes />
          </Switch>
        </section>
      </div>
    </div>
  );
};

const mapStateToProps = (store) => {
  const { application } = store;
  return {
    auth: application.auth,
    profile: application.profile,
    userInfo: application.userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    allReferencesAction: () => dispatch(allReferences()),
    getProfileAction: () => dispatch(getProfile()),
    getUserInfoAction: () => dispatch(getUserInfo()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppLayout));
