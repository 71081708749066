import { REFERENCE_BOOKS_ACTIONS } from '../types/types';

const applicationRedicer = (state = {}, action) => {
  switch (action.type) {
    case REFERENCE_BOOKS_ACTIONS.REGIONS:
      return {
        ...state,
        referenceBooks: {
          ...state.referenceBooks,
          ...action.payload,
        },
      };
    default:
      return {
        ...state,
        ...action.payload,
      };
  }
};

export default applicationRedicer;
