import React, { useMemo, useState } from 'react';
import TabLabel from '../../common/Tab/TabLabel';
import StatusLabel from '../../common/StatusLabel/StatusLabel';
import { Tooltip } from 'antd';
import ConfirmStep from '../../components/ConfirmStep/ConfirmStep';
import ModalWrapper from '../../common/Modal/ModalWrapper';
import TariffPlan from '../../components/TariffPlan/TariffPlan';
import DashboardContractData from './DashboardContractData';
import DashboardContractInfo from './DashboardContractInfo';

const DashboardContract = ({ item }) => {
  const [isVisibleDashboard, setVisibleDashboard] = useState(false);
  const [isModalSuccessVisible, setModalSuccessVisible] = useState(false);
  const [isModalTariffVisible, setModalTariffVisible] = useState(false);

  const handleModalSuccess = () => {
    setModalSuccessVisible(!isModalSuccessVisible);
  };

  const handleModalTariff = () => {
    setModalTariffVisible(!isModalTariffVisible);
  };

  return (
    <div className="dashboard-contract">
      <div className="dashboard-contract-header">
        <div className="dashboard-contract-header__left">
          <div className="dashboard-contract-header__inner">
            <div className="dashboard-contract-header__title">
              Договір №<span>{item.number}</span>
            </div>

            {/*<StatusLabel color="blue" text="1 точка підключення" />*/}
          </div>

          <div className="dashboard-contract-header__date">
            Від {new Date(item.date).toLocaleDateString().replaceAll('/', '.')}
          </div>
        </div>

        <div className="dashboard-contract-header__right">
          <div className="dashboard-contract-header__inner right">
            <div className="dashboard-contract-header__rate">
              <span>Тариф</span>
            </div>
            <div className="dashboard-contract-header__price">
              {item.total_tariff_current_value} за кВт
            </div>
            <button type="button" className="dashboard-contract-header__help">
              <Tooltip
                placement="top"
                title="Перший платіж 01.2022. 100% вартості заявленого обсягу."
              >
                <img src="/icons/help.svg" alt="help-icon" />
              </Tooltip>
            </button>
          </div>
        </div>
      </div>

      <button
        type="button"
        onClick={() => setVisibleDashboard(!isVisibleDashboard)}
        className={`dashboard-contract__btn ${isVisibleDashboard ? 'active' : ''}`}
      >
        <img src="/icons/arrow-right.svg" alt="arrow-icon" />
        Показати аналітику
      </button>

      {isVisibleDashboard && (
        <div className="dashboard-contract-data">
          <div className="dashboard-contract-data__header">
            <div className="dashboard-contract-data__title">Дані споживання</div>
          </div>
          <div className="dashboard-contract-info">
            <div className="dashboard-contract-info-block">
              <div className="dashboard-contract-info-block__title">Об'єм спожитої електроенергії</div>
              <div className={`dashboard-contract-info-block__inner`}>
                <span className="dashboard-contract-info-block__amount">{item.accrued_value}</span>
                <span className="dashboard-contract-info-block__currency">
                  <sup>{item.accrued_measure_unit}</sup>
                </span>
              </div>
            </div>
            <div className="dashboard-contract-info-block">
              <div className="dashboard-contract-info-block__title">Об'єм замовленої електроенергії</div>
              <div className={`dashboard-contract-info-block__inner`}>
                <span className="dashboard-contract-info-block__amount">{item.ordered_value}</span>
                <span className="dashboard-contract-info-block__currency">
                  <sup>{item.ordered_measure_unit}</sup>
                </span>
              </div>
            </div>
            <div className="dashboard-contract-info-block">
              <div className="dashboard-contract-info-block__title">Стан рахунку</div>
              <div
                className={`dashboard-contract-info-block__inner ${
                  item.balance_value > 0 ? 'red' : 'green'
                }`}
              >
                <span
                  className={`dashboard-contract-info-block__amount ${
                    item.balance_value === 0 ? 'black' : ''
                  }`}
                >
                  {item.balance_value > 0 && `-${item.balance_value}`}
                  {item.balance_value < 0 && item.balance_value.toString().replace('-', '+')}
                  {item.balance_value === 0 && item.balance_value}
                </span>
                <span
                  className={`dashboard-contract-info-block__currency ${
                    item.balance_value === 0 ? 'black' : ''
                  }`}
                >
                  грн
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {/*{isVisibleDashboard && <DashboardContractData />}*/}

      {isModalTariffVisible && (
        <ModalWrapper
          className="tariff-modal"
          isModalVisible={isModalTariffVisible}
          handleClick={handleModalTariff}
          modalWidth={900}
          closeIcon={<img src="/icons/modal-close.svg" />}
        >
          <TariffPlan />
        </ModalWrapper>
      )}

      {isModalSuccessVisible && (
        <ModalWrapper
          className="confirm-step-modal success"
          isModalVisible={isModalSuccessVisible}
          handleClick={handleModalTariff}
          modalWidth={770}
        >
          <ConfirmStep title="Заявку відправлено" icon button>
            <div className="confirm-step-text">
              Ваша заявка на зміну тарифу прийнята та буде розглянута протягом 24 годин. У разі
              погодження тарифу, інформація буде оновлена в особистому кабінеті.
            </div>

            <div className="confirm-step-text">
              Нарахування по новому тарифу розпочнуться з наступного місяця.
            </div>
          </ConfirmStep>
        </ModalWrapper>
      )}
    </div>
  );
};

export default DashboardContract;
