import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import FormDropdown from '../../common/FormDropdown/FormDropdown';
import { instanceFetch } from '../../utils/instanceFetch';
import { middlewareUrl } from '../../services/config';
import authHeader from '../../utils/authHeader';

const columns = [
  {
    title: 'Дата',
    dataIndex: 'date',
    className: 'actDate',
  },
  {
    title: 'Назва документу',
    dataIndex: 'title',
    className: 'title',
  },
  {
    title: 'Дії з документом',
    dataIndex: 'document',
    className: 'documents',
  },
];

const DocumentsAct = ({ setSelectedItem, tableData }) => {
  const signButton = (
    <button className="btn primary btn--sign" onClick={() => setSelectedItem(true)}>
      <span>Підписати</span>
    </button>
  );

  return (
    <>
      {/*<form className="form-selected-filter">
        <FormDropdown defaultValue="Тип" options={typeDocArr} />
        <FormDropdown defaultValue="Договір" options={contractArr} />
        <FormDropdown defaultValue="Період" options={periodArr} />

        <button type='button' className="underline-link">Очистити фільтр</button>

      </form>*/}
      <div className="scroll-wrap">
        <div className="custom-ant-table">
          <Table className="documents-bill" bordered columns={columns} dataSource={tableData} />
        </div>
      </div>
    </>
  );
};

export default DocumentsAct;
