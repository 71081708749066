import React, { useEffect, useState } from 'react';
import StatusLabel from '../../common/StatusLabel/StatusLabel';
import InputText from '../../common/Inputs/InputText';
import { Tooltip, Radio, Form } from 'antd';
import Button from '../../common/Button/Button';
import ConfirmStep from '../../components/ConfirmStep/ConfirmStep';
import ModalWrapper from '../../common/Modal/ModalWrapper';
import OrderGasCalendar from './OrderGasCalendar';
import {
  addDays,
  eachWeekendOfMonth,
  endOfMonth,
  format,
  formatISO,
  getDaysInMonth,
  isThisMonth,
  startOfMonth,
} from 'date-fns';
import { uk } from 'date-fns/locale';
import { instanceFetch } from '../../utils/instanceFetch';
import { middlewareUrl } from '../../services/config';
import authHeader from '../../utils/authHeader';

const radioArr = [
  {
    name: 'Рівномірне',
    description: 'Рівномірне',
    checked: true,
  },
  {
    name: 'Вихідні дні — знижена витрата',
    description: 'Вихідні дні — знижена витрата',
  },
  {
    name: 'Вихідні дні — без витрати',
    description: 'Вихідні дні — без витрати',
  },
  {
    name: 'Розподілити самостійно',
    description: 'Розподілити самостійно',
  },
];

const OrderGasCreate = ({ orderFilter, setResetForm }) => {
  const [checked, setChecked] = useState(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [accountEdrpou, setAccountEdrpou] = useState('');
  const [gasVolume, setGasVolume] = useState('');
  const [gasPercentValue, setGasPercentValue] = useState('');
  const [calendarGasLeft, setCalendarGasLeft] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');

  const gasValue = Number(gasVolume);
  const gasConsumptionPercentValue = Number(gasPercentValue);
  const dateFormat = 'd';
  const currentDate = new Date(orderFilter.filterYear, orderFilter.filterIndex);
  const currentDay = new Date();
  const nextDay = currentDate.setDate(currentDay.getDate() + 1);
  const currentDayIndex = Number(format(currentDay, dateFormat));
  const currentMonth = isThisMonth(currentDate);
  const monthStart = startOfMonth(currentDate);
  const monthEnd = endOfMonth(monthStart);
  const holidays = eachWeekendOfMonth(currentDate);
  const daysInMonth = currentMonth
    ? getDaysInMonth(currentDate) - currentDayIndex
    : getDaysInMonth(currentDate);

  let holidaysIndexArray = [];
  let days = [];
  let daysData = days;

  let gasInDay = gasValue / daysInMonth;
  let weekendGasConsumption = (gasValue * ((100 - gasConsumptionPercentValue) / 100)) / daysInMonth;
  let weekdayGasConsumption =
    (gasValue - weekendGasConsumption * holidays.length) / (daysInMonth - holidays.length);
  let gasConsumptionWithoutHolidays = gasValue / (daysInMonth - holidays.length);

  const [form] = Form.useForm();

  const calculateHolidays = () => {
    holidays.map((day) => {
      holidaysIndexArray.push(Number(format(day, dateFormat, { locale: uk })) - 1);
    });
  };

  switch (checked) {
    case 0:
      for (let i = 0; i < daysInMonth; i++) {
        days.push({
          date: formatISO(addDays(currentMonth ? nextDay : monthStart, i), {
            representation: 'date',
          }),
          daylimit: Number(gasInDay.toFixed(2)),
        });
      }
      break;

    case 1:
      calculateHolidays();

      for (let i = 0; i < daysInMonth; i++) {
        days.push({
          date: formatISO(addDays(currentMonth ? nextDay : monthStart, i), {
            representation: 'date',
          }),
          daylimit: Number(
            holidaysIndexArray.includes(i)
              ? weekendGasConsumption.toFixed(2)
              : weekdayGasConsumption.toFixed(2)
          ),
        });
      }
      break;

    case 2:
      calculateHolidays();
      for (let i = 0; i < daysInMonth; i++) {
        days.push({
          date: formatISO(addDays(currentMonth ? nextDay : monthStart, i), {
            representation: 'date',
          }),
          daylimit: Number(
            holidaysIndexArray.includes(i) ? 0 : gasConsumptionWithoutHolidays.toFixed(2)
          ),
        });
      }
      break;

    case 3:
      for (let i = 0; i < daysInMonth; i++) {
        const index = currentMonth ? i + currentDayIndex + 1 : i;
        days.push({
          indx: index,
          date: formatISO(addDays(currentMonth ? nextDay : monthStart, i), {
            representation: 'date',
          }),
          daylimit: Number(Math.floor(gasValue / daysInMonth)),
        });
      }
  }

  const updateGasOutput = (e) => {
    setGasVolume(e.target.value);
  };

  const updateGasPercentOutput = (e) => {
    setGasPercentValue(e.target.value);
  };

  const onFinish = (values) => {
    if (checked === 3) {
      handleCalendar();
    } else {
      instanceFetch
        .post(
          `${middlewareUrl}/gas-b2b/gas-limits`,
          {
            contract: orderFilter.filterContract,
            startPeriod: currentMonth
              ? formatISO(nextDay, { representation: 'date' })
              : formatISO(monthStart, { representation: 'date' }),
            endPeriod: formatISO(monthEnd, { representation: 'date' }),
            company: accountEdrpou,
            totalLimit: Number(values.gasVolume),
            dayLimits: JSON.stringify(days),
          },
          authHeader()
        )
        .then(() => handleModal())
        .catch((error) => setErrorMessage(error.message));
    }
  };

  const submitCalendarForm = () => {
    instanceFetch
      .post(
        `${middlewareUrl}/gas-b2b/gas-limits`,
        {
          contract: orderFilter.filterContract,
          startPeriod: currentMonth
            ? formatISO(nextDay, { representation: 'date' })
            : formatISO(monthStart, { representation: 'date' }),
          endPeriod: formatISO(monthEnd, { representation: 'date' }),
          company: accountEdrpou,
          totalLimit: calendarGasLeft,
          dayLimits: JSON.stringify(daysData),
        },
        authHeader()
      )
      .then(() => {
        handleCalendar();
        handleModal();
      })
      .catch((error) => setErrorMessage(error.message));
  };

  const onChange = (e) => {
    setChecked(e.target.value);
  };

  const handleCalendar = () => {
    setCalendarVisible(!calendarVisible);
  };

  const handleModal = () => {
    setModalVisible(!isModalVisible);
  };

  const afterClose = () => {
    setResetForm(true);
  };

  if (errorMessage) {
    setTimeout(() => setErrorMessage(''), 5000);
  }

  useEffect(() => {
    setChecked(0);
    instanceFetch.get(`${middlewareUrl}/user/info`, authHeader()).then((response) => {
      setAccountEdrpou(response.accountCompanies[0].edrpou);
    });
  }, []);

  return (
    <div className="order-gas-create box-shadow">
      {!calendarVisible ? (
        <div className="order-gas-content">
          <div className="order-gas-create-header">
            <span className="order-gas-create-header-title">
              Сформувати замовлення на {orderFilter.filterMonth} {orderFilter.filterYear}
            </span>
            <StatusLabel
              icon
              color="red-opacity"
              text="Замовлення можна подати до 19 числа поточного місяця"
            />
          </div>
          <div className="order-gas-create-total">
            <span className="order-gas-create-total-title">
              Замовлений об’єм: <span>{gasVolume ? gasVolume : '0'} м³</span>
            </span>
            {/*<span className="order-gas-create-total-title">Розподілення об’єму: <span>рівномірно між усіма ТКО</span></span>*/}
          </div>
          <Form form={form} name="order-gas" className="order-gas-create-form" onFinish={onFinish}>
            <InputText
              name="gasVolume"
              labelText="1. Необхідний об’єм газу:"
              suffix="м³"
              placeholder="Об’єм газу"
              onChange={updateGasOutput}
              rules={[{ required: true, message: 'Будь ласка введіть необхідний об’єм газу' }]}
            />
            <div className="field">
              <div className="radio-list">
                <span className="radio-list-title">2. Оберіть графіть споживання:</span>
                <div className="radio-list-content">
                  <Radio.Group onChange={onChange} value={checked}>
                    {radioArr.map(({ name, description, checked }, index) => {
                      return (
                        <div className={`radio-with-help-text radio-with-help-text--${checked}`}>
                          <Radio key={index} value={index}>
                            {name}
                            <Tooltip placement="top" title={description}>
                              <img src="/icons/help.svg" />
                            </Tooltip>
                          </Radio>
                        </div>
                      );
                    })}
                  </Radio.Group>
                </div>
              </div>
            </div>
            {checked === 1 && (
              <InputText
                name="gasPercent"
                labelText="3. Зазначте % зниження витрат:"
                suffix="%"
                placeholder="Зазначте відсоток "
                onChange={updateGasPercentOutput}
                rules={[
                  { required: true, message: 'Будь ласка, зазначте % зниження витрат у вихідні.' },
                  () => ({
                    validator(_, value) {
                      const regexp = /^[1-9]$|^[1-9][0-9]$|^(100)$/;
                      if (regexp.test(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Будь ласка, зазначте % Від 0 до 100!'));
                    },
                  }),
                ]}
              />
            )}
            <div className="order-gas-submit-wrap">
              <Button type="primary" typeHtml="submit" text="Сформувати замовлення" />
              {errorMessage && <div className="error-message">{errorMessage}</div>}
            </div>
          </Form>

          <ModalWrapper
            className="confirm-step-modal"
            isModalVisible={isModalVisible}
            handleClick={handleModal}
            modalWidth={770}
            afterClose={afterClose}
          >
            <ConfirmStep
              title="Замовлення сформовано"
              subtitle={
                <span>
                  Ми отримали ваше замовлення й вже починаємо над ним працювати. Незабаром ви
                  отримаєте повідомлення з результатом.
                </span>
              }
              button
              icon
              onClose={handleModal}
            >
              <div className="confirm-step-list">
                <div className="confirm-step-item active">
                  <div className="confirm-step-item-round">
                    <span>1</span>
                  </div>
                  <span className="confirm-step-item-title">Сформовано</span>
                </div>
                <div className="confirm-step-item">
                  <div className="confirm-step-item-round">
                    <span>2</span>
                  </div>
                  <span className="confirm-step-item-title">На погодженні</span>
                </div>
                <div className="confirm-step-item">
                  <div className="confirm-step-item-round">
                    <span>3</span>
                  </div>
                  <span className="confirm-step-item-title">Погоджено</span>
                </div>
              </div>
            </ConfirmStep>
          </ModalWrapper>
        </div>
      ) : (
        <OrderGasCalendar
          daysData={daysData}
          orderFilter={orderFilter}
          gasVolume={gasVolume}
          setCalendarGasLeft={setCalendarGasLeft}
          setCalendarVisible={handleCalendar}
          submitCalendarForm={submitCalendarForm}
          errorMessage={errorMessage}
        />
      )}
    </div>
  );
};

export default OrderGasCreate;
