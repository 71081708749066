import { middlewareUrl } from './config';
import { instanceFetch } from '../utils/instanceFetch';
import authHeader from '../utils/authHeader';

const ROUTES = {
  LOGIN: new URL(`${middlewareUrl}/user/login`),
  OTP_SEND: new URL(`${middlewareUrl}/user/otp-send`),
  OTP_LOGIN: new URL(`${middlewareUrl}/user/otp-login`),
  UPDATE_PASSWORD: new URL(`${middlewareUrl}/user/password/update`),
  APPROVE_NEW_LOGIN: new URL(`${middlewareUrl}/user/approve-new-login`),
  OTP_FORGOT_PASSWORD_SEND: new URL(`${middlewareUrl}/user/reset-password/send-otp`),
  OTP_FORGOT_PASSWORD_RESET: new URL(`${middlewareUrl}/user/reset-password/reset-by-otp`),
};

const loginService = (payload) => {
  return instanceFetch.post(ROUTES.LOGIN, payload).then(
    (response) => {
      return {
        token: response.access_token,
      };
    },
    (error) => {
      return Promise.reject({
        ...error,
      });
    }
  );
};

const loginOtpService = (payload) => {
  return instanceFetch.post(ROUTES.OTP_LOGIN, payload).then(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject({
        ...error,
      });
    }
  );
};

const forgotPasswordOtpResetService = (payload) => {
  return instanceFetch.post(ROUTES.OTP_FORGOT_PASSWORD_RESET, payload).then(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject({
        ...error,
      });
    }
  );
};

const loginOtpSendMessageService = (payload) => {
  return instanceFetch.post(ROUTES.OTP_SEND, payload).then(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject({
        ...error,
      });
    }
  );
};

const forgotPasswordOtpSendMessageService = (payload) => {
  return instanceFetch.post(ROUTES.OTP_FORGOT_PASSWORD_SEND, payload).then(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject({
        ...error,
      });
    }
  );
};

const passwordUpdate = (payload, token) => {
  return instanceFetch.patch(ROUTES.UPDATE_PASSWORD, payload, token).then(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject({
        ...error,
      });
    }
  );
};

const approveNewLoginService = (payload) => {
  return instanceFetch.patch(ROUTES.APPROVE_NEW_LOGIN, payload, authHeader()).then(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject({
        ...error,
      });
    }
  );
};

export {
  loginService,
  loginOtpService,
  loginOtpSendMessageService,
  forgotPasswordOtpSendMessageService,
  forgotPasswordOtpResetService,
  passwordUpdate,
  approveNewLoginService,
};
