import React, { useRef, useEffect } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 */
const useOutsideAlerter = (ref, close) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        close(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
};

/**
 * Component that alerts if you click outside of it
 */
const OutsideClickWrapper = ({ children, close }) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, close);

  return (
    <div className="outside-click-wrapper" ref={wrapperRef}>
      {children}
    </div>
  );
};

export default OutsideClickWrapper;
