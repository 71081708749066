import { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, withRouter, useHistory } from 'react-router-dom';
import { isNull } from 'lodash';
import DashboardGas from './pages/DashboardGas/DashboardGas';
import DashboardEnergiya from './pages/DashboardEnergiya/DashboardEnergiya';
import OrderGas from './pages/OrderGas/OrderGas';
import OrderEnergiya from './pages/OrderEnergiya/OrderEnergiya';
import SignIn from './pages/Registration/SignIn';
import SignUp from './pages/Registration/SignUp';
import ForgotPassword from './pages/Registration/ForgotPassword/ForgotPassword';
import Profile from './pages/Profile/Profile';
import MainPage from './pages/MainPage';
import AccountManagement from './pages/AccountManagement/AccountManagement';
import Team from './pages/Team/Team';
import { technicalWork } from './services/config';
import {
  ROUTE_HOME,
  ROUTE_UNAUTHORIZED,
  ROUTES_UNAUTHORIZAED_ALLOWED,
  ROUTE_MODERATION,
  ROUTE_GAS_B2C_DASHBOARD,
  ROUTE_GAS_B2B_DASHBOARD,
  ROUTE_ENERGIYA_B2B,
  ROUTE_GAS_B2B,
  ROUTE_DASHBOARD,
  ROUTE_DOCUMENTS,
  ROUTE_DOCUMENTS_CONTRACTS,
  ROUTE_ENERGY_DOCUMENTS,
  ROUTE_ENERGY_DOCUMENTS_CONTRACTS,
  ROUTE_TECHNICAL_WORK,
} from './constants';
import TeamCreatePassword from './pages/Team/TeamCreatePassword/TeamCreatePassword';
import SignInOtp from './pages/Registration/SigninOtp/SignInOtp';
import GasB2B from './pages/DashboardGas/GasB2B';
import BotB2B_IIT from './pages/Bot/BotB2B_IIT';
import NewInvoiceGasB2B from './pages/NewInvoiceGasB2B/NewInvoiceGasB2B';
import NewInvoiceEnergiya from './pages/NewInvoiceEnergiya/NewInvoiceEnergiya';
import DocumentsSignature from './pages/DocumentsGas/DocumentsSignature';
import MainPageB2b from './pages/MainPageB2b';
import SignUpB2b from './pages/Registration/SignUpB2b';
import DocumentsGasContracts from './pages/DocumentsGas/DocumentsGasContracts';
import DocumentsGasSubcontracts from './pages/DocumentsGas/DocumentsGasSubcontracts';
import DocumentsGasActs from './pages/DocumentsGas/DocumentsGasActs';
import DocumentsGasInvoices from './pages/DocumentsGas/DocumentsGasInvoices';
import DocumentsEnergiyaContracts from './pages/DocumentsEnergiya/DocumentsEnergiyaContracts';
import DocumentsEnergiyaSubcontracts from './pages/DocumentsEnergiya/DocumentsEnergiyaSubcontacts';
import DocumentsEnergiyaActs from './pages/DocumentsEnergiya/DocumentsEnergiyaActs';
import DocumentsEnergiyaInvoices from './pages/DocumentsEnergiya/DocumentsEnergiyaInvoices';
import TemporaryUnavailable from './pages/TemporaryUnavailable/TemporaryUnavailable';
import MyProfileTab from "./pages/Profile/MyProfileTab";
import ChangePasswordTab from "./pages/Profile/ChangePasswordTab";
import NotificationTab from "./pages/Profile/NotificationTab";

const mainRoutes = [
  {
    path: '/',
    component: MainPage,
    exact: true,
  },
  {
    path: '/b2b',
    component: MainPageB2b,
    exact: true,
  },
  {
    path: '/gas-b2b',
    exact: true,
    component: GasB2B,
    routes: [
      {
        path: '/dashboard',
        component: DashboardGas,
        exact: false,
      },
      {
        path: '/order',
        component: OrderGas,
        exact: false,
      },
      {
        path: '/documents',
        component: DocumentsGasContracts,
        exact: true,
      },
      {
        path: '/documents/contracts',
        component: DocumentsGasContracts,
        exact: true,
      },
      {
        path: '/documents/subcontracts',
        component: DocumentsGasSubcontracts,
        exact: true,
      },
      {
        path: '/documents/acts',
        component: DocumentsGasActs,
        exact: true,
      },
      {
        path: '/documents/invoices',
        component: DocumentsGasInvoices,
        exact: true,
      },
      {
        path: '/account-management',
        component: AccountManagement,
        exact: false,
      },
      {
        path: '/new-invoice',
        component: NewInvoiceGasB2B,
        exact: false,
      },
    ],
  },
  {
    path: '/energiya',
    component: DashboardEnergiya,
    exact: true,
    routes: [
      {
        path: '/dashboard',
        component: DashboardEnergiya,
        exact: false,
      },
      {
        path: '/new-invoice',
        component: NewInvoiceEnergiya,
        exact: false,
      },
      {
        path: '/order',
        component: OrderEnergiya,
        exact: false,
      },
      {
        path: '/documents',
        component: DocumentsEnergiyaContracts,
        exact: true,
      },
      {
        path: '/documents/contracts',
        component: DocumentsEnergiyaContracts,
        exact: true,
      },
      {
        path: '/documents/subcontracts',
        component: DocumentsEnergiyaSubcontracts,
        exact: true,
      },
      {
        path: '/documents/acts',
        component: DocumentsEnergiyaActs,
        exact: true,
      },
      {
        path: '/documents/invoices',
        component: DocumentsEnergiyaInvoices,
        exact: true,
      },
    ],
  },
  {
    path: '/sign-in',
    component: SignIn,
    exact: true,
    routes: [
      {
        path: '/team',
        component: TeamCreatePassword,
        exact: false,
      },
    ],
  },
  {
    path: '/sign-up',
    component: SignUp,
    exact: true,
  },
  {
    path: '/b2b/sign-up',
    component: SignUpB2b,
    exact: true,
  },
  {
    path: '/sign-in-otp',
    component: SignInOtp,
    exact: false,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    exact: false,
  },
  {
    path: '/profile',
    component: MyProfileTab,
    exact: true,
    routes: [
      {
        path: '/my-profile',
        component: MyProfileTab,
        exact: false,
      },{
        path: '/change-password',
        component: ChangePasswordTab,
        exact: false,
      },{
        path: '/companies',
        component: Team,
        exact: false,
      },{
        path: '/notifications',
        component: NotificationTab,
        exact: false,
      },
    ],
  },
  {
    path: '/b2b-bot',
    component: BotB2B_IIT,
    exact: true,
    routes: [
      {
        path: '/auth',
        component: BotB2B_IIT,
        exact: false,
      },
      {
        path: '/documents',
        component: DocumentsSignature,
        exact: false,
      },
    ],
  },
  {
    path: '/technical-work',
    component: TemporaryUnavailable,
    exact: false,
  },
];

const RenderRoutes = ({ auth, location, userInfo }) => {
  const history = useHistory();

  useEffect(() => {
    if (technicalWork) {
      console.log(technicalWork);
      history.push('/technical-work');
    }
  }, [technicalWork]);
  const renderAuthorizedComponent = (Component) => () => {
    const { gasB2b, eB2b, gasB2c } = userInfo;
    const { token } = auth;

    const activeGasB2c = !isNull(gasB2c) && userInfo.gasB2c;
    const activeGasB2b = !isNull(gasB2b) && userInfo.gasB2b;
    const activeEnergiyaB2b = !isNull(eB2b) && userInfo.eB2b;

    const homeRoute = location.pathname === ROUTE_HOME;
    const signinRoute = location.pathname === ROUTE_UNAUTHORIZED;
    const b2bRoute = location.pathname === ROUTE_GAS_B2B;
    const dashboardB2CRoute = location.pathname === ROUTE_GAS_B2C_DASHBOARD;
    const dashboardB2BRoute = location.pathname === ROUTE_GAS_B2B_DASHBOARD;
    const energiyaB2bRoute = location.pathname === ROUTE_ENERGIYA_B2B;
    const gasB2bRoute = location.pathname === ROUTE_GAS_B2B_DASHBOARD;
    const gasB2bDocuments = location.pathname === ROUTE_DOCUMENTS;
    const energiyaB2bDocuments = location.pathname === ROUTE_ENERGY_DOCUMENTS;

    if (dashboardB2CRoute) {
      return <Redirect to={ROUTE_DOCUMENTS_CONTRACTS} />;
    }

    if ((signinRoute || homeRoute || dashboardB2BRoute) && token && !activeGasB2b) {
      return <Redirect to={ROUTE_GAS_B2B} />;
    }

    if ((signinRoute || homeRoute || b2bRoute) && token && activeGasB2b) {
      return <Redirect to={ROUTE_GAS_B2B_DASHBOARD} />;
    }

    if (gasB2bDocuments) {
      return <Redirect to={ROUTE_DOCUMENTS_CONTRACTS} />;
    }

    if (energiyaB2bDocuments) {
      return <Redirect to={ROUTE_ENERGY_DOCUMENTS_CONTRACTS} />;
    }

    /*if ((isNull(gasB2b) || !activeGasB2b) && gasB2bRoute) return <Redirect to={ROUTE_HOME}/>;
    if ((isNull(eB2b) || !activeEnergiyaB2b) && energiyaB2bRoute) return <Redirect to={ROUTE_HOME}/>

    if (signinRoute && token && activeGasB2c) {
      return <Redirect to={ROUTE_GAS_B2C_DASHBOARD}/>
    }

    if (signinRoute && token && (activeGasB2b && activeEnergiyaB2b)) {
      return <Redirect to={ROUTE_GAS_B2B_DASHBOARD}/>
    }

    if (signinRoute && token && activeGasB2b) {
      return <Redirect to={ROUTE_GAS_B2B_DASHBOARD}/>
    }

    if (signinRoute && token && activeEnergiyaB2b) {
      return <Redirect to={ROUTE_ENERGIYA_B2B}/>
    }

    if (!isNull(gasB2c)) {
      if (signinRoute && token && (activeGasB2b && activeEnergiyaB2b)) {
        return <Redirect to={ROUTE_GAS_B2B_DASHBOARD}/>
      }

      if (token && signinRoute && location.pathname !== ROUTE_GAS_B2B && location.pathname !== ROUTE_GAS_B2C && !gasB2c) {
        return <Redirect to={ROUTE_GAS_B2C}/>;
      }

      if (token && location.pathname === ROUTE_GAS_B2C && gasB2c) {
        return <Redirect to={ROUTE_GAS_B2C_DASHBOARD}/>;
      }

      if (token && ROUTES_UNAUTHORIZAED_ALLOWED.includes(location.pathname) && gasB2c) {
        // return <Redirect to={ROUTE_GAS_B2C_DASHBOARD}/>;
      }
    }*/

    return !token && ROUTES_UNAUTHORIZAED_ALLOWED.includes(location.pathname) === false ? (
      <Redirect to={ROUTE_HOME} />
    ) : (
      <Component />
    );
  };

  return mainRoutes.map((router) => {
    if (router.routes) {
      const Child = router.component;
      const { path, exact } = router;
      return (
        <Switch key={router.path}>
          <Route {...{ path, exact }} render={renderAuthorizedComponent(Child)} />
          {router.routes.map((childrenRouter) => {
            const Child = childrenRouter.component;
            return (
              <Route
                key={router.path + childrenRouter.path}
                path={router.path + childrenRouter.path}
                exact={childrenRouter.exact}
                render={renderAuthorizedComponent(Child)}
              />
            );
          })}
        </Switch>
      );
    }

    const Child = router.component;
    return (
      <Route
        key={router.path}
        exact={router.exact}
        path={router.path}
        render={renderAuthorizedComponent(Child)}
      />
    );
  });
};

const mapStateToProps = (store) => {
  const { application } = store;
  return {
    auth: application.auth,
    userInfo: application.userInfo,
  };
};

export default memo(withRouter(connect(mapStateToProps, null)(RenderRoutes)));
