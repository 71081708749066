/*
import React, { useState, useMemo } from 'react';
import TabLabel from '../../common/Tab/TabLabel';
import DashboardContract from './DashboardContract';
import DashboardDots from './DashboardDots';
import DashboardGeneralInfo from './DashboardGeneralInfo';
import { useEnergyConsumerDocument } from './useEnnergyConsumerDocument';
import { Spin } from 'antd';
import EmptyData from '../../components/EmptyData/EmptyData';

const DashboardEnergiya = () => {
  const { documentsArr, loader, dataIsEmpty } = useEnergyConsumerDocument();
  // console.log(documentsArr)
  const arrDashboardTab = [
    {
      label: 'Загальна інформація',
      content: () => {
        return loader ? (
          <div className="dashboard__loader">
            <Spin />
          </div>
        ) : (
          <DashboardGeneralInfo documentsArr={documentsArr} />
        );
      },
    },
    {
      label: 'Договір',
      content: () => {
        return loader ? (
          <div className="dashboard__loader">
            <Spin />
          </div>
        ) : (
          <div className="dashboard__contracts">
            {documentsArr.length &&
              documentsArr.map((item) => (
                <DashboardContract itemInfo={item} documentsArr={documentsArr} />
              ))}
          </div>
        );
      },
    },
    /!*{
      label: 'Точки',
      content: () => <DashboardDots />,
    },*!/
  ];

  // console.log(documentsArr)
  const [activeTab, setActiveTab] = useState(0);

  const Content = useMemo(() => {
    return arrDashboardTab[activeTab].content;
  }, [activeTab, arrDashboardTab]);

  return (
    <div className="dashboard dashboard_energiya">
      <div className="tab-label-list">
        {arrDashboardTab.map(({ label }, index) => (
          <TabLabel
            key={index}
            label={label}
            onClick={() => setActiveTab(index)}
            active={activeTab === index}
          />
        ))}
      </div>

      <Content />
    </div>
  );
};

export default DashboardEnergiya;
*/
import React, { useState, useMemo } from 'react';
import DashboardContract from './DashboardContract';
import DashboardGeneralInfo from './DashboardGeneralInfo';
import { useEnergyConsumerDocument } from './useEnnergyConsumerDocument';
import { Spin } from 'antd';
import EmptyData from '../../components/EmptyData/EmptyData';

const DashboardEnergiya = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { documentsArr, loader, dataIsEmpty } = useEnergyConsumerDocument();

  const agreementsFilterValues = [38, 65];
  const filteredAgreements = documentsArr.filter(
    (val) => agreementsFilterValues.includes(val.contract_type) && val.is_contract_active === 1
  );

  // console.log('documentsArr', documentsArr)
  // console.log('filteredAgreements', filteredAgreements)

  const arrDashboardTab = [
    {
      label: 'Загальна інформація',
      content: () => <DashboardGeneralInfo />,
    },
    {
      label: `Договори (${filteredAgreements.length})`,
      content: () => <DashboardContracts />,
    },
  ];

  // console.log(documentsArr);

  const DashboardContracts = () => {
    return (
      <div className="dashboard__contracts">
        <DashboardContract filteredAgreements={documentsArr} />
      </div>
    );
  };

  const Content = useMemo(() => {
    return arrDashboardTab[activeTab].content;
  }, [activeTab, arrDashboardTab]);

  return (
    <div className="dashboard dashboard_gas">
      <div className="dashboard__contracts">
        {loader ? (
          <div className="dashboard__loader">
            <Spin />
          </div>
        ) : (
          documentsArr.map((item, key) => <DashboardContract key={key} item={item} />)
        )}
        {dataIsEmpty && <EmptyData />}
      </div>
    </div>
  );
};

export default DashboardEnergiya;
