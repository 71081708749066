import React from 'react';
import PhoneInput from 'react-phone-input-2';
import { Form } from 'antd';

const InputPhone = ({
  name,
  label = 'Номер телефону',
  disabled,
  withLink,
  onClickLink,
  isValue,
}) => {
  if (withLink) {
    return (
      <div className="field field--edited">
        <Form.Item>
          <Form.Item name={name} label={label}>
            <PhoneInput
              autoFormat={false}
              placeholder="Номер телефону"
              inputClass="ant-input field__input"
              disabled={disabled}
            />
          </Form.Item>

          <button type="button" className="field__edit" onClick={onClickLink}>
            {isValue ? 'Змінити' : 'Додати'}
          </button>
        </Form.Item>
      </div>
    );
  }

  return (
    <div className="field">
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: true,
            message: 'Не валідний номер телефону',
          },
        ]}
      >
        <div className="field__wrapper">
          <PhoneInput
            country="ua"
            autoFormat={false}
            placeholder="Номер телефону"
            inputClass="ant-input field__input"
            disabled={disabled}
          />
        </div>
      </Form.Item>
    </div>
  );
};

export default InputPhone;
