import React, { useEffect, useState } from 'react';
import Button from '../../../common/Button/Button';
import { withRouter } from 'react-router-dom';
import { Form } from 'antd';
import renderErrors from '../../../helper/functions';
import InputText from '../../../common/Inputs/InputText';
import { registerOtp, register } from '../../../store/actions/register';
import { connect } from 'react-redux';

const ContentStep3 = ({
  registerAction,
  regions,
  loginValue,
  sendVia,
  handleStep,
  reRegisterAction,
}) => {
  const [form] = Form.useForm();

  const [error, setError] = useState({
    message: null,
    messages: null,
  });

  const handlerSubmit = ({ values, otp }) => {
    registerAction({
      ...values,
      ...(sendVia === '1' ? { email: loginValue } : { phone: loginValue }),
      source: '1',
      client_type: '1',
      portal_type: '1',
      otp: otp,
      otp_source: sendVia,
    })
      .then(() => {
        handleStep((p) => p + 1);
      })
      .catch((error) => {
        error.errors
          ? setError({
              messages: error.errors,
              message: null,
            })
          : setError({
              message: error.message,
              messages: null,
            });
      });
  };

  const reRegisterHandler = () => {
    reRegisterAction({
      send_via: sendVia,
      login: loginValue,
    }).catch((error) => {
      error.errors
        ? setError({
            messages: error.errors,
            message: null,
          })
        : setError({
            message: error.message,
            messages: null,
          });
    });
  };

  useEffect(() => {
    renderErrors(form, error.messages);
  }, [error.messages]);

  return (
    <div className="registration-content step-1">
      <img className="" src="/icons/confirm1.svg" />
      <span className="reg-content-title-black">
        На <span className="email">{loginValue}</span>
        <br></br> надіслано код для активації особистого кабінету
      </span>
      <Form
        name="registration"
        onFinish={handlerSubmit}
        form={form}
        initialValues={{
          region_id: regions.length > 0 ? regions[0].regionId : null,
        }}
      >
        <InputText name="otp" labelText="Введіть код у поле нижче" />

        <Button typeHtml="submit" type="primary" text="Підтвердити" />

        <div className="reg-links">
          <div className="reg-link">
            Якщо не отримали код, <span onClick={reRegisterHandler}>запросіть його повторно</span>
          </div>
          <div className="reg-link">
            або{' '}
            <span
              onClick={() => {
                handleStep((p) => p - 1);
              }}
            >
              зареєструйтеся за номером телефону
            </span>
          </div>
        </div>

        {error.message && <div className="error">{error.message}</div>}
      </Form>
    </div>
  );
};

const mapStateToProps = (store) => {
  const { referenceBooks } = store.application;
  return {
    regions: referenceBooks.regions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    registerAction: (payload) => dispatch(registerOtp(payload)),
    reRegisterAction: (payload) => dispatch(register(payload)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContentStep3));
