import React from 'react';
import SidebarTeamCreatePassword from './SidebarTeamCreatePassword';
import ContentTeamCreatePassword from './ContentTeamCreatePassword';

const TeamCreatePassword = () => {
  return (
    <div className="registration">
      <SidebarTeamCreatePassword />
      <ContentTeamCreatePassword />
    </div>
  );
};

export default TeamCreatePassword;
