import React, { useState } from 'react';
import StatusLabel from '../../../common/StatusLabel/StatusLabel';
import Button from '../../../common/Button/Button';
import InputText from '../../../common/Inputs/InputText';
import { Form } from 'antd';
import { instanceFetch } from '../../../utils/instanceFetch';
import { middlewareUrl } from '../../../services/config';
import { formatISO } from 'date-fns';
import authHeader from '../../../utils/authHeader';
import ConfirmStep from '../../../components/ConfirmStep/ConfirmStep';
import ModalWrapper from '../../../common/Modal/ModalWrapper';

const OrderEnergiyaStep3 = ({ orderFilter, selectedPoint, accountId }) => {
  const [energyVolume, setEnergyVolume] = useState('');
  const [isModalVisible, setModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [form] = Form.useForm();
  // console.log('orderFilter',orderFilter)
  // console.log('accountId',accountId)
  // console.log('selectedPoint',selectedPoint)

  const updateEnergyVolume = (e) => {
    setEnergyVolume(e.target.value);
  };

  const onFinish = (value) => {
    const month = (orderFilter.filterIndex + 1)
      .toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
      .toString();
    const year = orderFilter.filterYear.toString();
    const range = year + month;
    const temp = {
      accountId: accountId,
      date: new Date(),
      note: 'string',
      orders: [
        {
          eic: selectedPoint[0].eic,
          values: [
            {
              value: Number(value.energyVolume),
              period: Number(range),
            },
          ],
        },
      ],
    };
    instanceFetch
      .put(
        `${middlewareUrl}/e-b2b/consumption-orders`,
        {
          accountId: accountId,
          date: new Date(),
          note: 'string',
          orders: [
            {
              eic: selectedPoint[0].eic,
              values: [
                {
                  value: Number(value.energyVolume),
                  period: Number(range),
                },
              ],
            },
          ],
        },
        authHeader()
      )
      .then(() => handleModal())
      .catch((error) => setErrorMessage(error.message));
  };

  const handleModal = () => {
    setModalVisible(!isModalVisible);
  };

  if (errorMessage) {
    setTimeout(() => setErrorMessage(''), 5000);
  }

  return (
    <>
      <div className="order-gas-create-header">
        <span className="order-gas-create-header-title">
          {`Сформувати замовлення на ${orderFilter.filterMonth} ${orderFilter.filterYear}`}
        </span>
        <StatusLabel
          icon
          color="red-opacity"
          text="Замовлення можна подати до 19 числа поточного місяця"
        />
      </div>
      <div className="info-green-box info-box-grid">
        <div>
          <span className="info-box-grid-title">
            ТКО: <span>{selectedPoint[0].eic}</span>
          </span>
          <span className="info-box-grid-title">
            ОСР: <span>{selectedPoint[0].operator.name}</span>
          </span>
        </div>
        <div>
          <span className="info-box-grid-title">
            Група: <span>Б</span>
          </span>
          <span className="info-box-grid-title">
            Клас: <span>1</span>
          </span>
        </div>
      </div>

      <div className="order-energiya-form">
        <div className="order-energiya-form-input">
          <Form form={form} name="order-gas" className="order-gas-create-form" onFinish={onFinish}>
            <InputText
              name="energyVolume"
              labelText="необхідний об’єм електроенергії:"
              suffix="кВт"
              placeholder="Об’єм електоенергії"
              onChange={updateEnergyVolume}
              rules={[
                { required: true, message: 'Будь ласка введіть необхідний об’єм електроенергії' },
                () => ({
                  validator(_, value) {
                    const regexp = /^\d+$/;
                    if (regexp.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Будь ласка, введіть чисельне значення'));
                  },
                }),
              ]}
            />
            <Button text="Сформувати замовлення" type="primary" typeHtml="submit" />
            {errorMessage && <div className="error-message">{errorMessage}</div>}
          </Form>
        </div>
      </div>
      <ModalWrapper
        className="confirm-step-modal"
        isModalVisible={isModalVisible}
        handleClick={handleModal}
        modalWidth={770}
        // afterClose={afterClose}
      >
        <ConfirmStep
          title="Замовлення сформовано"
          subtitle={
            <span>
              Ми отримали ваше замовлення й вже починаємо над ним працювати. Незабаром ви отримаєте
              повідомлення з результатом.
            </span>
          }
          button
          icon
          onClose={handleModal}
        >
          <div className="confirm-step-list">
            <div className="confirm-step-item active">
              <div className="confirm-step-item-round">
                <span>1</span>
              </div>
              <span className="confirm-step-item-title">Сформовано</span>
            </div>
            <div className="confirm-step-item">
              <div className="confirm-step-item-round">
                <span>2</span>
              </div>
              <span className="confirm-step-item-title">На погодженні</span>
            </div>
            <div className="confirm-step-item">
              <div className="confirm-step-item-round">
                <span>3</span>
              </div>
              <span className="confirm-step-item-title">Погоджено</span>
            </div>
          </div>
        </ConfirmStep>
      </ModalWrapper>
    </>
  );
};

export default OrderEnergiyaStep3;
