import React from 'react';

const SidebarStep3B2b = ({ handleStep }) => {
  return (
    <div className="registration-sidebar">
      <div
        className="reg-sidebar-back-to"
        onClick={() => {
          handleStep((p) => p - 1);
        }}
      >
        <img src="/icons/arrow-back.svg" />
        <span>Реєстрація в онлайн-кабінеті -B2b-</span>
      </div>

      <span className="reg-sidebar-title">Ми на фінішній прямій</span>
      <span className="reg-sidebar-text">
        Для безпеки ваших даних створіть пароль, що містить не менше 8 символів, а також в ньому
        мають бути букви і цифри.
      </span>
      <span className="reg-sidebar-text">І останній крок — введіть номер особового рахунку.</span>
      <span className="reg-sidebar-text">До зв'язку!</span>
    </div>
  );
};

export default SidebarStep3B2b;
