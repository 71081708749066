import React from 'react';
import { Form, Select } from 'antd';

const FormDropdown = ({ options, defaultValue, labelText, handleSelectChange, name }) => {
  return (
    <div className="field">
      <Form.Item label={labelText && labelText} name={name}>
        <Select
          onChange={handleSelectChange}
          defaultValue={defaultValue ? defaultValue : options[0]}
          suffixIcon={<img src="/icons/arrow-select.svg" alt="arrow-icon" />}
        >
          {options.map((value, index) => (
            <Select.Option key={index} value={value}>
              {value}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};

export default FormDropdown;
