import React from 'react';
import { Table } from 'antd';
import Button from '../../common/Button/Button';
import StatusLabel from '../../common/StatusLabel/StatusLabel';
import FormDropdown from '../../common/FormDropdown/FormDropdown';

const downloadReceiptBtn = (
  // TODO: Mayby replace to text iconed btn
  <div className="download-receipt">
    <img src="/icons/download.svg" />
    <span>PDF_Квитанція-завантажити</span>
  </div>
);

const columns = [
  {
    title: 'Дата',
    dataIndex: 'date',
    className: 'actDate',
  },
  {
    title: 'Назва документу',
    dataIndex: 'title',
    className: 'title',
  },
  {
    title: 'Дії з документом',
    dataIndex: 'document',
    className: 'documents',
  },
];

const data = [];
for (let i = 0; i < 1; i++) {
  data.push({
    key: i,
    accountNumber: `41AP797-17-22- ${i}`,
    contract: `41AP797-17-22`,
    date: '01.05.2022',
    gasVolume: `890,56 - м³`,
    paymentAmount: `501,06 грн.`,
    paymentStatus: <StatusLabel icon text="Очікується оплата" color="red" />,
    receipt: <Button type="primary" text="Сплатити рахунок" />,
    // receipt: downloadReceiptBtn,
  });
}

const contractArr = ['Договір 0017', 'Договір 0018', 'Договір 0019', 'Договір 0011', 'Договір 009'];
const periodArr = ['2021', '2020', '2019', '2018', '2017', '2016'];

const DocumentsReceipt = ({ setSelectedItem, tableData }) => {
  return (
    <>
      {/* <form className="form-selected-filter">
        <FormDropdown defaultValue="Договір" options={contractArr} />
        <FormDropdown defaultValue="Період" options={periodArr} />

        <button type='button' className="underline-link">Очистити фільтр</button>
      </form>*/}
      <div className="scroll-wrap">
        <div className="custom-ant-table">
          <Table
            className="documents-receipt-gas"
            bordered
            columns={columns}
            dataSource={tableData}
          />
        </div>
      </div>
    </>
  );
};

export default DocumentsReceipt;
