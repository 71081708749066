import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { forgotPasswordOtpSendMessageService } from '../../../services/auth';
import Button from '../../../common/Button/Button';
import TypeSignInOtp from '../SigninOtp/TypeSignInOtp';
import renderErrors from '../../../helper/functions';
import InputText from '../../../common/Inputs/InputText';
import { EMAIL_REGEXP } from '../../../constants';

const ForgotPasswordSendOtp = (props) => {
  const { setForgotPasswordValue, setStepForgotPassword } = props;

  const [form] = Form.useForm();
  const [error, setError] = useState({
    message: null,
    messages: null,
  });

  const handlerSubmit = (values) => {
    forgotPasswordOtpSendMessageService({
      ...values,
      send_via: EMAIL_REGEXP.test(values.login) ? '1' : '2',
      app_code: '1',
    })
      .then(() => {
        setForgotPasswordValue(values.login);
        setStepForgotPassword(1);
      })
      .catch((error) => {
        error.errors
          ? setError({
              messages: error.errors,
              message: null,
            })
          : setError({
              message: error.message,
              messages: null,
            });
      });
  };

  useEffect(() => {
    renderErrors(form, error.messages);
  }, [error.messages]);

  return (
    <div className="registration-content">
      <span className="reg-content-title-blue">Відновлення паролю</span>
      <Form form={form} onFinish={handlerSubmit}>
        <InputText
          name="login"
          prefix={<img src="/icons/user-form.svg" />}
          tooltipText="Номер телефону у форматі +380"
          labelText="Email або номер телефону"
        />

        <Button type="primary" typeHtml="submit" text="Надіслати пароль" />
        {error.message && <div className="error">{error.message}</div>}
      </Form>
    </div>
  );
};

export default ForgotPasswordSendOtp;
