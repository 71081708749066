import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form } from 'antd';
import { loginOtp } from '../../../store/actions/auth';
import Button from '../../../common/Button/Button';
import InputText from '../../../common/Inputs/InputText';
import renderErrors from '../../../helper/functions';

const SignInOtpPasswordForm = ({ loginValue, loginOtpAction }) => {
  const [form] = Form.useForm();

  const [error, setError] = useState({
    message: null,
    messages: null,
  });

  const handlerSubmit = ({ otp }) => {
    loginOtpAction({
      login: loginValue,
      otp: otp,
      app_code: '1',
    }).catch((error) => {
      error.errors
        ? setError({
            messages: error.errors,
            message: null,
          })
        : setError({
            message: error.message,
            messages: null,
          });
    });
  };

  useEffect(() => {
    renderErrors(form, error.messages);
  }, [error.messages]);

  return (
    <div className="registration-content">
      <span className="reg-content-title-black">
        На ваш email <span className="email">{loginValue}</span> надіслано код для активації
        особистого кабінету
      </span>
      <Form onFinish={handlerSubmit}>
        <InputText
          placeholder="Введіть код у поле нижче"
          labelText="Введіть код у поле нижче"
          name="otp"
          rules={[{ required: true, message: 'Будь ласка введіть код' }]}
        />

        <Button type="primary" typeHtml="submit" text="Зберегти і ввійти" />
        {error.message && <div className="error">{error.message}</div>}
      </Form>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginOtpAction: (payload) => dispatch(loginOtp(payload)),
  };
};

export default connect(null, mapDispatchToProps)(SignInOtpPasswordForm);
