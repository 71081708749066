import React, { useState, useEffect } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import { instanceFetch } from '../../utils/instanceFetch';
import authHeader from '../../utils/authHeader';
import { middlewareUrl } from '../../services/config';
import {
  getEnergyB2BCompaniesService,
  getGasB2BCompaniesService,
  postEgasService,
} from '../../services/companies';
import { connect } from 'react-redux';

const BotB2B_IIT = ({ history }) => {
  const { search } = useLocation();

  const [signData, setSignData] = useState('');
  const [signSend, setSignSend] = useState(false);

  const token = new URLSearchParams(search).get('token');
  const viberId = new URLSearchParams(search).get('viberId');
  const auth = {
    token: token,
  };
  localStorage.setItem('auth', JSON.stringify(auth));

  const ROUTES = {
    VIBER_REDIRECT: 'viber://pa?chatURI=gaszbutb2b&context=skip',
  };

  let euSign;

  useEffect(() => {
    euSign = new EndUser(
      'sign-widget-parent' /* Ідентифікатор батківського елементу */,
      'sign-widget' /* Ідентифікатор елементу iframe */,
      'https://eu.iit.com.ua/sign-widget/v20200922/' /* URI для завантаження iframe */,
      EndUser.FormType.ReadPKey /* Тип форми iframe */
    );

    let data = document.getElementById('textAreaData').value;
    let previousSign = null;
    let external = false;
    let asBase64String = true;
    let signAlgo = EndUser.SignAlgo.DSTU4145WithGOST34311;
    let signType = EndUser.SignType.CAdES_X_Long;

    euSign
      .ReadPrivateKey()
      .then(() => {
        euSign
          .SignData(data, external, asBase64String, signAlgo, null, signType)
          .then((sign) => {
            getGasB2BCompaniesService({
              company: sign,
              viberId: viberId,
            });
            getEnergyB2BCompaniesService({
              company: sign,
              viberId: viberId,
            }).then(() => {
              setSignSend(true);
              localStorage.removeItem('auth');
              setTimeout(() => {
                window.location = ROUTES.VIBER_REDIRECT;
              }, 3000);
            });
          })
          .catch(function (e) {
            console.log(
              'Виникла помилка при підписі даних. ' + 'Опис помилки: ' + (e.message || e)
            );
          });
      })
      .catch(function (e) {
        console.log(
          'Виникла помилка при зчитуванні ос. ключа. ' + 'Опис помилки: ' + (e.message || e)
        );
      });
  }, [token]);

  return (
    <div className="bot-wrap">
      {token && !signSend && (
        <div>
          <div id="sign-widget-parent" className="iit-widget"></div>
          <textarea id="textAreaData"></textarea>
        </div>
      )}
      {token && signSend && (
        <div className="registration-content">
          <img className="" src="/icons/confirm1.svg" />
          <span className="reg-content-title-blue">
            "Дякуємо, ваша компанія активована, поверніться до чат-боту"
          </span>
        </div>
      )}
    </div>
  );
};

export default withRouter(connect()(BotB2B_IIT));
