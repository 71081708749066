import React from 'react';
import { Tooltip } from 'antd';

const FieldLabel = ({ labelText, tooltipText }) => (
  <>
    {labelText}

    {tooltipText && (
      <Tooltip className="tooltip" placement="top" title={tooltipText}>
        <img src="/icons/help.svg" alt="help-icon" />
      </Tooltip>
    )}
  </>
);

export default FieldLabel;
