import React, { useState } from 'react';
import SignInOtpLoginForm from './SignInOtpLoginForm';
import SignInOtpPasswordForm from './SignInOtpSendPasswordForm';

const SignInOtpContent = () => {
  const options = [
    {
      name: 'E-mail',
      value: 1,
    },
    {
      name: 'Телефон',
      value: 2,
    },
  ];

  const [selectedOption, setSelectedOption] = useState(options[0].value);
  const [stepLogin, setStepLogin] = useState(0);
  const [loginValue, setLoginValue] = useState('');

  const stepLoginMap = {
    0: (
      <SignInOtpPasswordForm
        options={options}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        setLoginValue={setLoginValue}
        setStepLogin={setStepLogin}
      />
    ),
    1: <SignInOtpLoginForm loginValue={loginValue} selectedOption={selectedOption} />,
  };

  return <div className="registration-content">{stepLoginMap[stepLogin]}</div>;
};

export default SignInOtpContent;
