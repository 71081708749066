import React, { useEffect, useState } from 'react';
import Button from '../../common/Button/Button';
import { Form } from 'antd';
import renderErrors from '../../helper/functions';
import InputText from '../../common/Inputs/InputText';
import { approveNewLoginService } from '../../services/auth';
import ModalWrapper from '../../common/Modal/ModalWrapper';
import { withRouter } from 'react-router-dom';
import { getProfile } from '../../store/actions/profile';
import { useDispatch } from 'react-redux';

const UpdateUserContacts = ({ loginType, newUserContact, setRenderContent }) => {
  const [successModal, setSuccessModal] = useState(false);
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const [error, setError] = useState({
    message: null,
    messages: null,
  });

  const handleForm = ({ otpCode }) => {
    approveNewLoginService({
      // login_type: loginType,
      otp: +otpCode,
    })
      .then(() => {
        dispatch(getProfile());
      })
      .then(() => setSuccessModal(true))
      .catch((error) => {
        error.errors
          ? setError({
              messages: error.errors,
              message: null,
            })
          : setError({
              message: error.message,
              messages: null,
            });
      });
  };

  useEffect(() => {
    renderErrors(form, error.messages);
  }, [error.messages]);

  return (
    <div className="form">
      <span className="form__title">
        Введіть код, що відправлений на <div className="email">{newUserContact}</div>
      </span>

      <Form onFinish={handleForm}>
        <InputText type="number" name="otpCode" labelText="Код-підтверждення" />

        <Button type="primary" typeHtml="submit" text="Продовжити" />
        {error.message && <div className="error">{error.message}</div>}
      </Form>

      <button className="underline-link" onClick={() => setRenderContent('userInfo')}>
        Повернутись назад
      </button>

      <ModalWrapper
        className="success-modal"
        isModalVisible={successModal}
        handleClick={() => setSuccessModal(false)}
      >
        <div className="success-modal__wrapper">
          <div className="success-modal__title">Данні успішно оновлено.</div>
          <Button
            onClick={() => setRenderContent('userInfo')}
            type="primary"
            text="Повернутись до профайлу"
          />
        </div>
      </ModalWrapper>
    </div>
  );
};

export default withRouter(UpdateUserContacts);
