import React from 'react';
import cx from 'classnames';

const Progressbar = ({ text, type, className, progress }) => {
  const progressbarClassName = cx('progressbar', type, className);

  return (
    <div className={progressbarClassName}>
      <div className="progressbar__text">{text}</div>
      <div className="progressbar__line-bg">
        <div className="progressbar__line" style={{ width: `${progress}%` }} />
      </div>
    </div>
  );
};

export default Progressbar;
