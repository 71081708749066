import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form } from 'antd';
import { loginOtpSendMessage } from '../../../store/actions/auth';
import Button from '../../../common/Button/Button';
import TypeSignInOtp from './TypeSignInOtp';
import renderErrors from '../../../helper/functions';

const SignInOtpPasswordForm = (props) => {
  const {
    options,
    selectedOption,
    setSelectedOption,
    setLoginValue,
    setStepLogin,
    loginOtpSendMessageAction,
  } = props;
  const [form] = Form.useForm();
  const [error, setError] = useState({
    message: null,
    messages: null,
  });

  const handlerSubmit = (values) => {
    loginOtpSendMessageAction({
      ...values,
      send_via: selectedOption,
      app_code: '1',
    })
      .then(() => {
        setLoginValue(values.login);
        setStepLogin(1);
      })
      .catch((error) => {
        error.errors
          ? setError({
              messages: error.errors,
              message: null,
            })
          : setError({
              message: error.message,
              messages: null,
            });
      });
  };

  useEffect(() => {
    renderErrors(form, error.messages);
  }, [error.messages]);

  return (
    <div className="registration-content">
      <span className="reg-content-title-black">Вхід в особистий кабінет</span>
      <Form onFinish={handlerSubmit}>
        <TypeSignInOtp
          options={options}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />

        <Button type="primary" typeHtml="submit" text="Зберегти і ввійти" />
        {error.message && <div className="error">{error.message}</div>}
      </Form>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginOtpSendMessageAction: (payload) => dispatch(loginOtpSendMessage(payload)),
  };
};

export default connect(null, mapDispatchToProps)(SignInOtpPasswordForm);
