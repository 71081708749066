import React, { useState } from 'react';
import { Form, InputNumber, Modal, Spin } from 'antd';
import Button from '../../common/Button/Button';
import NewInvoiceGasB2BModal from './NewInvoiceGasB2BModal';
import { middlewareUrl } from '../../services/config';
import { instanceFetch } from '../../utils/instanceFetch';
import authHeader from '../../utils/authHeader';
import { base64toBlob } from '../../helper/functions';
import { useGasConsumerDocument } from '../DashboardGas/useGasConsumerDocument';
import DasboardGasInvoiceItem from '../DashboardGas/DasboardGasInvoiceItem';
import EmptyData from '../../components/EmptyData/EmptyData';

const NewInvoiceGasB2B = () => {
  const { documentsArr, loader, accountEdrpou, dataIsEmpty, isFop } = useGasConsumerDocument();
  const agreementsFilterValues = [7, 39, 40, 41, 42, 62, 63, 64];
  const filteredAgreements = documentsArr.filter(
    (val) => agreementsFilterValues.includes(val.contract_type) && val.is_contract_active === 1
  );

  return (
    <div className="dashboard-b2c new-invoice-gas">
      <div className="dashboard-b2c-item new-invoice-message">
        <div className="dashboard-b2c-item__inner">
          <div className="new-invoice-message__title">Сформувати рахунок</div>

          <div className="new-invoice-message__text">
            Сформуйте рахунок для оплати за постачання газу. Для кожного договору потрібно
            сформувати окремий рахунок.
          </div>
        </div>
      </div>
      {loader ? (
        <div className="invoice-wrap box-shadow">
          <Spin />
        </div>
      ) : (
        // filteredAgreements.map((item, key) => <DasboardGasInvoiceItem key={key} item={documentsArr} accountEdrpou={accountEdrpou} isFop={isFop} />)
        documentsArr.map((item) => (
          <DasboardGasInvoiceItem item={item} accountEdrpou={accountEdrpou} isFop={isFop} />
        ))
      )}
      {dataIsEmpty && <EmptyData />}
    </div>
  );
};

export default NewInvoiceGasB2B;
