import React, { useState } from 'react';
import { Form, Input, Popover } from 'antd';
import FieldLabel from './FieldLabel';
import cx from 'classnames';

const InputText = (props) => {
  const {
    labelText,
    tooltipText,
    iconText,
    type = 'text',
    placeholder,
    id,
    error,
    prompt,
    value,
    defaultValue,
    onChange,
    prefix,
    suffix,
    password,
    name,
    rules,
    disabled,
  } = props;
  const passwordRulesArr = ['Довжина паролю має бути не менше восьми символів'];

  const passwordRules = () => {
    return passwordRulesArr.map((value, index) => <span key={index}>{value}</span>);
  };

  const fieldClass = cx('field');

  if (password)
    return (
      <div className={fieldClass}>
        <Form.Item
          validateStatus={error && 'error'}
          help={error && error}
          label={labelText && <FieldLabel labelText={labelText} tooltipText={tooltipText} />}
          tooltip={iconText && { title: 'Tooltip input', icon: iconText }}
          name={name}
          rules={rules}
        >
          <div className="field__wrapper">
            <Popover placement="right" content={passwordRules} trigger="click">
              <Input.Password
                type={type}
                id={id}
                className="field__input"
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                prefix={prefix}
                name={name}
                disabled={disabled}
              />
            </Popover>
          </div>
        </Form.Item>

        {!error && prompt && <div className="field__prompt">{prompt}</div>}
      </div>
    );

  return (
    <div className={fieldClass}>
      <Form.Item
        validateStatus={error && 'error'}
        help={error && error}
        label={labelText && <FieldLabel labelText={labelText} tooltipText={tooltipText} />}
        tooltip={iconText && { title: 'Tooltip input', icon: iconText }}
        name={name}
        rules={rules}
      >
        <Input
          defaultValue={defaultValue}
          type={type}
          id={id}
          className="field__input"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          prefix={prefix}
          suffix={suffix}
          name={name}
          disabled={disabled}
        />
      </Form.Item>

      {!error && prompt && <div className="field__prompt">{prompt}</div>}
    </div>
  );
};

InputText.defaultProps = {
  name: '',
  rules: [],
};

export default InputText;
