import React from 'react';
import InputText from '../../../common/Inputs/InputText';
import Button from '../../../common/Button/Button';

const ContentTeamCreatePassword = () => {
  return (
    <div className="registration-content">
      <span className="reg-content-title-black">Створіть пароль</span>
      <form>
        <InputText
          password
          placeholder="Введіть пароль"
          labelText="Введіть пароль"
          prefix={<img src="/icons/lock.svg" />}
        />
        <InputText
          password
          placeholder="Повторіть пароль"
          labelText="Повторіть пароль"
          prefix={<img src="/icons/lock.svg" />}
        />
        <Button type="primary" text="Зберегти і ввійти" disabled />
      </form>
    </div>
  );
};

export default ContentTeamCreatePassword;
