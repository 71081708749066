import React, { useEffect, useReducer, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import InputText from '../../common/Inputs/InputText';
import Button from '../../common/Button/Button';
import { Form, Input } from 'antd';
import InputPhone from '../../common/Inputs/InputPhone';
import FieldEmail from '../Registration/FieldEmail';
import ProfileModeration from './ProfileModeration';
import { updateUserProfile } from '../../store/actions/profile';
import ModalWrapper from '../../common/Modal/ModalWrapper';
import renderErrors from '../../helper/functions';
import { isEqual } from 'lodash';

const UserInfo = ({ setRenderContent }) => {
  const [successModal, setSuccessModal] = useState(false);
  const [isDisabledFormButton, setDisabledFormButton] = useState(true);
  const [error, setError] = useState({
    message: null,
    messages: null,
  });

  const [form] = Form.useForm();

  useEffect(() => {
    renderErrors(form, error.messages);
  }, [error.messages]);

  const state = useSelector(({ application }) => application.profile);
  const dispatch = useDispatch();

  const { firstname, middlename, lastname, email, phone } = state;

  const handleForm = ({ phone, email, ...values }) => {
    dispatch(updateUserProfile(values))
      .then(() => setSuccessModal(true))
      .catch((error) => {
        error.errors
          ? setError({
              messages: error.errors,
              message: null,
            })
          : setError({
              message: error.message,
              messages: null,
            });
      });
  };

  const initialValues = {
    first_name: firstname,
    middle_name: middlename,
    last_name: lastname,
    email: email,
    phone: phone,
  };

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onValuesChange={(_, changedValues) =>
        setDisabledFormButton(isEqual(initialValues, changedValues))
      }
      onFinish={handleForm}
      className="form-grid form-grid__profile"
    >
      <InputText
        name="first_name"
        placeholder="Ім’я"
        labelText="Ім’я*"
        prefix={<img src="/icons/user-form.svg" />}
      />

      <FieldEmail
        name="email"
        isValue={email}
        withLink
        onClickLink={() => setRenderContent('email')}
        disabled
      />

      <InputText
        name="middle_name"
        placeholder="По батькові"
        labelText="По батькові"
        prefix={<img src="/icons/user-form.svg" />}
      />

      <InputPhone
        name="phone"
        isValue={phone}
        withLink
        onClickLink={() => setRenderContent('phone')}
        disabled
      />

      <InputText
        name="last_name"
        placeholder="Прізвище"
        labelText="Прізвище*"
        prefix={<img src="/icons/user-form.svg" />}
      />

      <Button typeHtml="submit" type="primary" text="Зберегти" disabled={isDisabledFormButton} />

      <ModalWrapper
        className="success-modal"
        isModalVisible={successModal}
        handleClick={() => setSuccessModal(false)}
      >
        <div className="success-modal__wrapper">
          <div className="text-black-medium-f22-l30">Данні успішно оновлено.</div>
        </div>
      </ModalWrapper>
    </Form>
  );
};

const mapStateToProps = (store) => {
  const { application } = store;
  return {
    userInfo: application.userInfo,
  };
};

export default UserInfo;
