import React, { useState } from 'react';
import SidebarStep2 from './SidebarStep2';
import ContentStep2 from './ContentStep2';

const RegistrationStep2 = ({ handleStep, loginValue, setLoginValue, sendVia, setSendVia }) => {
  return (
    <>
      <SidebarStep2 />
      <ContentStep2
        handleStep={handleStep}
        setLoginValue={setLoginValue}
        loginValue={loginValue}
        sendVia={sendVia}
        setSendVia={setSendVia}
      />
    </>
  );
};

export default RegistrationStep2;
