import React from 'react';
import ModalWrapper from '../../common/Modal/ModalWrapper';
import Button from '../../common/Button/Button';
import ConfirmStep from '../../components/ConfirmStep/ConfirmStep';

const TeamAdd = ({ isModalRemoveVisible, handleRemoveModal, member }) => {
  return (
    isModalRemoveVisible && (
      <ModalWrapper
        className="classic-modal"
        isModalVisible={isModalRemoveVisible}
        handleClick={handleRemoveModal}
        modalWidth={600}
        closeIcon={<img src="/icons/modal-close-black.svg" />}
      >
        <div className="team-remove">
          <ConfirmStep
            title="Видалити контакт?"
            subtitle={
              <span>
                Ви збираєтесь видалити користувача{' '}
                <span className="confirm-step-sub-title-bold">{member.name}</span> зі своєї команди.
              </span>
            }
          />

          <div className="classic-modal-footer-btn double">
            <Button type="primary" text="Не видаляти" onClick={handleRemoveModal} />
            <Button type="outline-primary" text="Видалити" />
          </div>
        </div>
      </ModalWrapper>
    )
  );
};
export default TeamAdd;
