import React, { useEffect, useMemo, useState } from 'react';
import InputText from '../../../common/Inputs/InputText';
import Button from '../../../common/Button/Button';
import { Form, Radio, Tooltip } from 'antd';
import { instanceFetch } from '../../../utils/instanceFetch';
import { middlewareUrl } from '../../../services/config';
import authHeader from '../../../utils/authHeader';
import ConfirmStep from '../../../components/ConfirmStep/ConfirmStep';
import ModalWrapper from '../../../common/Modal/ModalWrapper';

const radioArr = [
  {
    name: 'Зазначити загальний об’єм, який буде розподілений рівномірно між усіма точками',
    description: 'Зазначити загальний об’єм, який буде розподілений рівномірно між усіма точками',
    checked: true,
  },
  {
    name: "Відредагувати об'єм у кожній точці окремо",
    description: "Відредагувати об'єм у кожній точці окремо",
  },
];

const OrderEnergiyaStep6 = ({ orderFilter, selectedPoint, accountId }) => {
  const [checked, setChecked] = useState(null);
  const [energyVolume, setEnergyVolume] = useState('');
  const [inputsObject, setInputsObject] = useState({});
  const [isModalVisible, setModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [form] = Form.useForm();
  const inputValues = Object.values(inputsObject);
  let totalEnergy = inputValues.reduce((acc, number) => acc + number, 0);

  const onChange = (e) => {
    setChecked(e.target.value);
  };

  const updateEnergyVolume = (e) => {
    const { name, value } = e.target;
    setEnergyVolume(e.target.value);
    setInputsObject({ ...inputsObject, [name]: Number(value) });
  };

  const updateTotalEnergyVolume = (e) => {
    setEnergyVolume(e.target.value);
  };

  const onFinish = (value) => {
    const totalPointsObject = selectedPoint.flatMap((item) => ({
      [item.eic]: value.energyVolume / selectedPoint.length,
    }));
    const totalPoints = Object.assign(...totalPointsObject);
    const month = (orderFilter.filterIndex + 1)
      .toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
      .toString();
    const year = orderFilter.filterYear.toString();
    const range = year + month;
    let pointsValuesArr = [];

    Object.entries(checked === 0 ? totalPoints : value).forEach((entry) => {
      const [key, value] = entry;

      const inputObj = {
        eic: key,
        values: [
          {
            value: Number(value),
            period: Number(range),
          },
        ],
      };
      pointsValuesArr.push(inputObj);
    });

    instanceFetch
      .put(
        `${middlewareUrl}/e-b2b/consumption-orders`,
        {
          accountId: accountId,
          date: new Date(),
          note: 'string',
          orders: pointsValuesArr,
        },
        authHeader()
      )
      .then(() => handleModal())
      .catch((error) => setErrorMessage(error.message));
  };

  const handleModal = () => {
    setModalVisible(!isModalVisible);
  };

  if (errorMessage) {
    setTimeout(() => setErrorMessage(''), 5000);
  }

  const resetFields = () => {
    form.resetFields();
    setInputsObject({});
  };

  /*const PointInputsRender = useMemo(() => {
    selectedPoint.map(point => {
      return <InputText placeholder="78" labelText={point} suffix="кВт" />
    })
    /!*for (let i = 0; i < 7; i++) {
      pointInputs.push(<InputText placeholder="78" labelText={`ТКО 23492-${i}:`} suffix="кВт" />);
    }
    return pointInputs;*!/
  }, []);*/

  useEffect(() => {
    setChecked(0);
  }, []);

  return (
    <div>
      <div className="order-gas-create-header">
        <span className="order-gas-create-header-title">
          {`Сформувати замовлення на ${orderFilter.filterMonth} ${orderFilter.filterYear}`}
        </span>
      </div>
      <div className="info-green-box info-box-grid">
        <div>
          <span className="info-box-grid-title">
            Замовлений об’єм: <span>987 кВт</span>
          </span>
          <span className="info-box-grid-title">
            Розподілення об’єму:{' '}
            <span>{checked === 0 ? 'рівномірно між усіма ТКО' : 'у кожній точці окремо'}</span>
          </span>
        </div>
      </div>

      <span className="order-energiya-create-sub-title step5">
        Ви можете відредагувати об'єм у кожній точці окремо або ж зазначити загальний об'єм, який
        буде рівномірно розповіделений між усіма точками обраного оператора розподілу системи (ОСР):
      </span>

      <Form form={form} name="order-energy" className="order-energiya-form" onFinish={onFinish}>
        <div className="order-energiya-form-radio">
          <Radio.Group onChange={onChange} value={checked}>
            {radioArr.map(({ name, description, checked }, index) => {
              return (
                <div className={`radio-with-help-text radio-with-help-text--${checked}`}>
                  <Radio key={index} value={index} checked={checked}>
                    {name}
                    <Tooltip placement="top" title={description}>
                      <img src="/icons/help.svg" />
                    </Tooltip>
                  </Radio>
                </div>
              );
            })}
          </Radio.Group>
        </div>

        {checked === 0 && (
          <div className="order-energiya-form-input">
            <InputText
              name="energyVolume"
              labelText="необхідний об’єм електроенергії:"
              suffix="кВт"
              placeholder="Об’єм електоенергії"
              onChange={updateTotalEnergyVolume}
              value={energyVolume}
              rules={[
                { required: true, message: 'Будь ласка введіть необхідний об’єм електроенергії' },
                () => ({
                  validator(_, value) {
                    const regexp = /^\d+$/;
                    if (regexp.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Будь ласка, введіть чисельне значення'));
                  },
                }),
              ]}
            />
            <Button type="primary" typeHtml="submit" text="Сформувати замовлення" />
            {errorMessage && <div className="error-message">{errorMessage}</div>}
          </div>
        )}
        {checked === 1 && (
          <div className="order-energiya-form-wrap">
            <div className="order-energiya-inputs">
              {selectedPoint.map((point, index) => (
                <InputText
                  labelText={point.eic}
                  index={index}
                  name={point.eic}
                  suffix="кВт"
                  placeholder="Об’єм електоенергії"
                  onChange={updateEnergyVolume}
                  value={energyVolume}
                  rules={[
                    {
                      required: true,
                      message: 'Будь ласка введіть необхідний об’єм електроенергії',
                    },
                    () => ({
                      validator(_, value) {
                        const regexp = /^\d+$/;
                        if (regexp.test(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('Будь ласка, введіть чисельне значення'));
                      },
                    }),
                  ]}
                />
              ))}
            </div>
            <div className="info-green-box info-box-grid">
              <span className="info-box-grid-title">
                Новий об’єм замовлення: <span>{totalEnergy ? totalEnergy : 0} кВт</span>
              </span>
              <button type="button" className="underline-link" onClick={resetFields}>
                Очистити всі поля
              </button>
            </div>
            <Button
              type="primary"
              typeHtml="submit"
              className="energiya-btn-submit"
              text="Сформувати замовлення"
            />
            {errorMessage && <div className="error-message">{errorMessage}</div>}
          </div>
        )}
      </Form>
      <ModalWrapper
        className="confirm-step-modal"
        isModalVisible={isModalVisible}
        handleClick={handleModal}
        modalWidth={770}
        // afterClose={afterClose}
      >
        <ConfirmStep
          title="Замовлення сформовано"
          subtitle={
            <span>
              Ми отримали ваше замовлення й вже починаємо над ним працювати. Незабаром ви отримаєте
              повідомлення з результатом.
            </span>
          }
          button
          icon
          onClose={handleModal}
        >
          <div className="confirm-step-list">
            <div className="confirm-step-item active">
              <div className="confirm-step-item-round">
                <span>1</span>
              </div>
              <span className="confirm-step-item-title">Сформовано</span>
            </div>
            <div className="confirm-step-item">
              <div className="confirm-step-item-round">
                <span>2</span>
              </div>
              <span className="confirm-step-item-title">На погодженні</span>
            </div>
            <div className="confirm-step-item">
              <div className="confirm-step-item-round">
                <span>3</span>
              </div>
              <span className="confirm-step-item-title">Погоджено</span>
            </div>
          </div>
        </ConfirmStep>
      </ModalWrapper>
    </div>
  );
};

export default OrderEnergiyaStep6;
