import React from 'react';
import SidebarStep1B2b from './Registration/Step1/SidebarStep1B2b';
import ContentStep1B2b from './Registration/Step1/ContentStep1B2b';

const MainPageB22b = () => {
  return (
    <div className="registration registration__main">
      <SidebarStep1B2b />
      <ContentStep1B2b />
    </div>
  );
};

export default MainPageB22b;
