import React from 'react';
import SidebarForgotPassword from './SidebarForgotPassword';
import ContentForgotPassword from './ContentForgotPassword';

const ForgotPassword = () => {
  return (
    <div className="registration">
      <SidebarForgotPassword />
      <ContentForgotPassword />
    </div>
  );
};

export default ForgotPassword;
