import React from 'react';
import { Link } from 'react-router-dom';

const SidebarSignIn = () => {
  return (
    <div className="registration-sidebar">
      <Link to="/" className="reg-sidebar-back-to">
        <img src="/icons/arrow-back.svg" />
        <span>Назад</span>
      </Link>

      <span className="reg-sidebar-title">Вітаємо!</span>
      <span className="reg-sidebar-text">
        Раді бачити вас! Введіть свою електронну пошту або номер телефону і пароль, щоб увийти в
        особистий онлайн-кабінет.
      </span>
    </div>
  );
};

export default SidebarSignIn;
