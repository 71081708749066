import { useEffect, useState } from 'react';
import { instanceFetch } from '../../utils/instanceFetch';
import { middlewareUrl } from '../../services/config';
import authHeader from '../../utils/authHeader';

export const useGasConsumerDocument = () => {
  const [documentsArr, setDocumentsArr] = useState([]);
  const [loader, setLoader] = useState(true);
  const [accountEdrpou, setAccountEdrpou] = useState('');
  const [dataIsEmpty, setDataIsEmpty] = useState(false);
  const [isFop, setIsFop] = useState(false);

  useEffect(() => {
    /*const fetchDocs = async () => {
      const response = await instanceFetch.get(`${middlewareUrl}/user/info`, authHeader())
      setAccountEdrpou(response.accountCompanies[0].edrpou)
      setIsFop(response.accountCompanies[0].legalFormType === 'fop')
      instanceFetch.get(`${middlewareUrl}/gas-b2b/consumer/by-edrpou?edrpou=${response.accountCompanies[0].edrpou}`, authHeader())
        .then(contract => {
          if (contract.contracts !== undefined) {
            console.log(contract.contracts)
            setDocumentsArr(contract.contracts) // TODO: TEMP
            setLoader(false)  // TODO: TEMP
            /!*let iterations = contract.contracts.length
            for(let contr of contract.contracts) {
              instanceFetch.get(`${middlewareUrl}/gas-b2b/contract/indicators?contractId=${contr.id}`, authHeader())
                .then(response => setDocumentsArr(array => [...array, {...contr, ...response}]))
                .then(() => !--iterations && setLoader(false))
            }*!/
          } else {
            setLoader(false)
            setDataIsEmpty(true)
          }

        })
    }*/

    const fetchDocs = async () => {
      const response = await instanceFetch.get(`${middlewareUrl}/user/info`, authHeader());
      const accountCompanies = response.accountCompanies;
      // console.log('accountCompanies',accountCompanies);
      setIsFop(response.accountCompanies[0].legalFormType === 'fop');

      const fetchContractsPromises = accountCompanies.map((company) =>
        instanceFetch.get(
          `${middlewareUrl}/gas-b2b/consumer/by-edrpou?edrpou=${company.edrpou}`,
          authHeader()
        )
      );

      Promise.all(fetchContractsPromises).then((contractsArray) => {
        const updatedContractsArray = contractsArray.map((contracts, index) => {
          const legalFormType = accountCompanies[index].legalFormType;
          return contracts.contracts.map((contract) => ({ ...contract, legalFormType }));
        }).flat();

        // console.log('updatedContractsArray', updatedContractsArray);

        if (updatedContractsArray.length > 0) {
          setDocumentsArr(updatedContractsArray);
          setLoader(false);
        } else {
          setLoader(false);
          setDataIsEmpty(true);
        }
      });

      /*Promise.all(fetchContractsPromises).then((contractsArray) => {
        const documentsArr = contractsArray.map((contracts) => contracts.contracts).flat();
        console.log('documentsArr', documentsArr);
        if (documentsArr.length > 0) {
          setDocumentsArr(documentsArr); // TODO: TEMP
          setLoader(false); // TODO: TEMP
        } else {
          setLoader(false);
          setDataIsEmpty(true);
        }
      });*/
    };

    /*const fetchDocs = async () => {
      const response = await instanceFetch.get(`${middlewareUrl}/user/info`, authHeader())
      setAccountEdrpou(response.accountCompanies[0].edrpou)
      setIsFop(response.accountCompanies[0].legalFormType === 'fop')

      const contractsPromises = response.accountCompanies.map(company => {
        return instanceFetch.get(`${middlewareUrl}/gas-b2b/consumer/by-edrpou?edrpou=${company.edrpou}`, authHeader())
          .then(contract => {
            if (contract.contracts !== undefined) {
              console.log(contract.contracts)
              setDocumentsArr(contract.contracts) // TODO: TEMP
              setLoader(false)  // TODO: TEMP
              let iterations = contract.contracts.length
              const promises = contract.contracts.map(contr => {
                return instanceFetch.get(`${middlewareUrl}/gas-b2b/contract/indicators?contractId=${contr.id}`, authHeader())
                  .then(response => ({ ...contr, ...response }))
                  .finally(() => !--iterations && setLoader(false))
              })
              return Promise.all(promises)
            } else {
              return []
            }
          })
      })

      Promise.all(contractsPromises)
        .then(documentsArrays => {
          const allDocuments = documentsArrays.flat()
          setDocumentsArr(allDocuments)
          setLoader(false)
        })
        .catch(error => {
          console.error(error)
          setLoader(false)
          setDataIsEmpty(true)
        })
    }*/

    void fetchDocs();
  }, []);

  return {
    documentsArr,
    loader,
    accountEdrpou,
    dataIsEmpty,
    isFop,
  };
};
