import store from '../store';
import { logout } from '../store/actions/auth';

const handleServiceResponse = (response) => {
  if (typeof response === 'string') {
    try {
      return JSON.parse(response);
    } catch (error) {
      // console.log(error);
    }
  }

  return response.text().then((text) => {
    let data = false;
    try {
      data = JSON.parse(text);
    } catch {
      data = text;
    }
    const error = data || response.statusText;

    if (response.status === 401) {
      store.dispatch(logout());

      return Promise.reject(error);
    }
    if (response.ok === false) {
      return Promise.reject(error);
    }

    if (typeof data === 'string') {
      try {
        return JSON.parse(data);
      } catch (error) {
        return Promise.reject('unhandlable response');
      }
    }

    return data;
  });
};

export { handleServiceResponse };
