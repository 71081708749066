import React from 'react';
import OrderEnergiyaStep1 from './OrderEnergiyaCreateSteps/OrderEnergiyaStep1';
import OrderEnergiyaStep2 from './OrderEnergiyaCreateSteps/OrderEnergiyaStep2';
import OrderEnergiyaStep3 from './OrderEnergiyaCreateSteps/OrderEnergiyaStep3';
import OrderEnergiyaStep4 from './OrderEnergiyaCreateSteps/OrderEnergiyaStep4';
import OrderEnergiyaStep5 from './OrderEnergiyaCreateSteps/OrderEnergiyaStep5';
import OrderEnergiyaStep6 from './OrderEnergiyaCreateSteps/OrderEnergiyaStep6';
import OrderEnergiyaStep7 from './OrderEnergiyaCreateSteps/OrderEnergiyaStep7';
import OrderGasFilter from '../OrderGas/OrderGasFilter';
import OrderGasCreate from '../OrderGas/OrderGasCreate';

const OrderEnergiyaCreate = ({ contract, orderFilter, selectedPoint, accountId }) => {
  return (
    <div className="order-gas-create order-energiya box-shadow">
      {selectedPoint.length === 1 && selectedPoint[0].hasAMIS && (
        <OrderEnergiyaStep1
          accountId={accountId}
          orderFilter={orderFilter}
          selectedPoint={selectedPoint}
        />
      )}
      {selectedPoint.length === 1 && !selectedPoint[0].hasAMIS && (
        <OrderEnergiyaStep3
          accountId={accountId}
          orderFilter={orderFilter}
          selectedPoint={selectedPoint}
        />
      )}
      {selectedPoint.length > 1 && (
        <OrderEnergiyaStep6
          accountId={accountId}
          orderFilter={orderFilter}
          selectedPoint={selectedPoint}
        />
      )}
    </div>
  );
};

export default OrderEnergiyaCreate;
