import React from 'react';
import SidebarStep1 from './Registration/Step1/SidebarStep1';
import ContentStep1 from './Registration/Step1/ContentStep1';

const MainPage = () => {
  return (
    <div className="registration registration__main">
      <SidebarStep1 />
      <ContentStep1 />
    </div>
  );
};

export default MainPage;
