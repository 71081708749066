import React from 'react';
import { Spin } from 'antd';
import { useEnergyConsumerDocument } from '../DashboardEnergiya/useEnnergyConsumerDocument';
import EnergyInvoiceItem from './EnergyInvoiceItem';
import EmptyData from '../../components/EmptyData/EmptyData';

const NewInvoiceEnergiya = () => {
  const { documentsArr, loader, accountEdrpou, dataIsEmpty, isFop } = useEnergyConsumerDocument();

  const agreementsFilterValues = [38, 65];

  const filteredAgreements =
    !loader &&
    documentsArr.reduce((prev, current) => (prev.period > current.period ? prev : current));

  return (
    <div className="dashboard-b2c new-invoice-energiya">
      <div className="dashboard-b2c-item new-invoice-message">
        <div className="dashboard-b2c-item__inner">
          <div className="new-invoice-message__title">Сформувати рахунок</div>

          <div className="new-invoice-message__text">
            Сформуйте рахунок для оплати за постачання електроенергії. Для кожного договору потрібно
            сформувати окремий рахунок.
          </div>
        </div>
      </div>
      {
        loader ? (
          <div className="invoice-wrap box-shadow">
            <Spin />
          </div>
        ) : (
          <EnergyInvoiceItem
            item={filteredAgreements}
            accountEdrpou={accountEdrpou}
            isFop={isFop}
          />
        )
        // filteredAgreements.map((item, key) => <EnergyInvoiceItem key={key} item={item} accountEdrpou={accountEdrpou} /> )
      }
      {dataIsEmpty && <EmptyData energy={true} />}
    </div>
  );
};

export default NewInvoiceEnergiya;
