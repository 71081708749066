import React from 'react';
import { Form, Input, Popover } from 'antd';

const PasswordAndConfirm = () => {
  const passwordRulesArr = ['Довжина паролю має бути не менше восьми символів'];
  const passwordRules = () => {
    return passwordRulesArr.map((value, index) => <span key={index}>{value}</span>);
  };
  return (
    <>
      <div className="field">
        <Popover placement="right" content={passwordRules} trigger="click">
          <Form.Item
            name="password"
            label="Пароль"
            rules={[
              {
                min: 8,
                message: 'Довжина паролю має бути не менше восьми символів',
              },
              {
                required: true,
                message: 'Введіть пароль',
              },
            ]}
          >
            <Input.Password
              type="password"
              className="field__input"
              placeholder="Пароль"
              prefix={<img src="/icons/lock.svg" />}
            />
          </Form.Item>
        </Popover>
      </div>

      <div className="field">
        <Popover placement="right" content={passwordRules} trigger="click">
          <Form.Item
            name="password_confirmation"
            label="Пароль Повторно"
            dependencies={['password']}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    (!value && getFieldValue('password') === undefined) ||
                    getFieldValue('password') === value
                  ) {
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error('Введені паролі не збігаються'));
                },
              }),
            ]}
          >
            <Input.Password
              type="password"
              className="field__input"
              placeholder="Пароль Повторно"
              prefix={<img src="/icons/lock.svg" />}
            />
          </Form.Item>
        </Popover>
      </div>
    </>
  );
};

export default PasswordAndConfirm;
