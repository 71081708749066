import React, { useState } from 'react';
import {
  addDays,
  startOfMonth,
  endOfMonth,
  startOfISOWeek,
  endOfISOWeek,
  format,
  isSameMonth,
  getDaysInMonth,
  formatISO,
  isBefore,
  isThisMonth,
} from 'date-fns';
import { uk } from 'date-fns/locale';
import CalendarInput from './CalendarInput';

const Calendar = ({
  orderFilter,
  gasVolume,
  gasLeft,
  setGasLeft,
  setGasCalendarValues,
  daysData,
}) => {
  const [inputValue, setInputValue] = useState();
  const currentDate = new Date(orderFilter.filterYear, orderFilter.filterIndex);
  const gasValue = Number(gasVolume);
  const currentDay = new Date();
  const currentMonth = isThisMonth(currentDate);
  const currentDayIndex = Number(format(currentDay, 'd'));

  const daysInMonth = currentMonth
    ? getDaysInMonth(currentDate) - currentDayIndex
    : getDaysInMonth(currentDate);
  let gasInDayValue = Math.floor(gasValue / daysInMonth);
  const [gasDistributed, setGasDistributed] = useState(gasInDayValue * daysInMonth);

  setGasLeft(gasDistributed);

  const daysOfWeek = () => {
    const dateFormat = 'EEEE';
    const days = [];
    let startDate = startOfISOWeek(currentDate);

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="calendar-week-item" key={i}>
          {format(addDays(startDate, i), dateFormat, { locale: uk })}
        </div>
      );
    }
    return <div className="calendar-weeks">{days}</div>;
  };

  const cells = () => {
    const monthStart = startOfMonth(currentDate);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfISOWeek(monthStart);
    const endDate = endOfISOWeek(monthEnd);
    const dateFormat = 'd';
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = '';

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        days.push(
          <div
            className={`calendar-day 
            ${!isSameMonth(day, monthStart) || isBefore(day, currentDay) ? 'disabled' : ''} 
            ${isSameMonth(day, monthStart) && i === 3 ? 'active-day' : ''}`}
            key={day}
            id={isSameMonth(day, monthStart) && formattedDate}
          >
            <span>{formattedDate}</span>

            {isSameMonth(day, monthStart) && !isBefore(day, currentDay) && (
              <CalendarInput
                index={Number(formattedDate)}
                gasInDayValue={gasInDayValue}
                gasValue={gasValue}
                gasLeft={gasLeft}
                setGasLeft={setGasLeft}
                gasDistributed={gasDistributed}
                setGasDistributed={setGasDistributed}
                setGasCalendarValues={setGasCalendarValues}
                setInputValue={setInputValue}
                daysData={daysData}
              />
            )}
          </div>
        );
        day = addDays(day, 1);
      }
      rows.push(
        <div className="calendar-day-row" key={day}>
          {days}
        </div>
      );
      days = [];
    }
    return rows;
  };

  return (
    <>
      {/*<span>{format(currentDate, 'LLLL yyyy', { locale: uk })}</span>*/}
      <div className="scroll-wrap">
        <div className="calendar">
          <div>{daysOfWeek()}</div>
          <div>{cells()}</div>
        </div>
      </div>
    </>
  );
};
export default Calendar;
