import React, { useState } from 'react';
import ModalWrapper from '../../common/Modal/ModalWrapper';
import InputText from '../../common/Inputs/InputText';
import FormDropdown from '../../common/FormDropdown/FormDropdown';
import Button from '../../common/Button/Button';
import { roles } from './Team';
import { Form } from 'antd';
import FieldEmail from '../Registration/FieldEmail';

const TeamAdd = ({ isModalAddVisible, handleAddModal }) => {
  return (
    isModalAddVisible && (
      <ModalWrapper
        className="classic-modal team-edit"
        isModalVisible={isModalAddVisible}
        handleClick={handleAddModal}
        modalWidth={600}
        closeIcon={<img src="/icons/modal-close-black.svg" />}
      >
        <div className="form-head">
          <div className="form-title">Додати новий контакт</div>
        </div>
        <form className="team-form">
          <InputText
            placeholder="Ім’я Контакту"
            labelText="Ім’я Контакту*"
            prefix={<img src="/icons/user-form.svg" />}
          />
          <InputText
            placeholder="Прізвище Контакту"
            labelText="Прізвище Контакту*"
            prefix={<img src="/icons/user-form.svg" />}
          />
          <FieldEmail />
          <InputText placeholder="Посада" labelText="Посада" />
          <FormDropdown
            labelText="Права доступу до кабінету"
            defaultValue="Права доступу до кабінету"
            options={roles}
          />

          <div className="classic-modal-footer-btn">
            <Button type="primary" text="Зберегти" disabled />
          </div>
        </form>
      </ModalWrapper>
    )
  );
};
export default TeamAdd;
