import React from 'react';
import SidebarStep4 from './SidebarStep4';
import ContentStep4 from './ContentStep4';

const RegistrationStep4 = ({ handleStep }) => {
  return (
    <>
      <SidebarStep4 handleStep={handleStep} />
      <ContentStep4 handleStep={handleStep} />
    </>
  );
};

export default RegistrationStep4;
