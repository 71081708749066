import React, { useMemo, useState } from 'react';
import TabLabel from '../../common/Tab/TabLabel';
import FormDropdown from '../../common/FormDropdown/FormDropdown';
import Chart from '../../common/Chart/Chart';
import { useLocation } from 'react-router-dom';
import chartMock from './chartMock.json';
import moment from 'moment';

const months = [
  'Січень',
  'Лютий',
  'Березень',
  'Квітень',
  'Травень',
  'Червень',
  'Липень',
  'Серпень',
  'Вересень',
  'Жовтень',
  'Листопад',
  'Грудень',
];

const DashboardHistory = () => {
  const [currentFilterYear, setCurrentFilterYear] = useState('2022');
  const { pathname } = useLocation();
  const page = pathname.split('/')[1];

  const filteredDataForChartConfig = chartMock
    .filter(({ created_at }) => {
      const year = moment(created_at).format('YYYY');

      return year === currentFilterYear;
    })
    .sort((prev, next) => {
      return moment(prev.created_at).format('M') - moment(next.created_at).format('M');
    })
    .reduce(
      (data, currentValue) => {
        const { payment, accruals, recalculation } = currentValue;
        const calcAccruals = accruals + recalculation;

        data.paymentData.push(payment.toFixed(2));
        data.accrualsData.push(calcAccruals.toFixed(2));
        return data;
      },
      { paymentData: [], accrualsData: [] }
    );

  const chartConfigData = [
    {
      label: 'Платежі',
      data: months.map((month, index) => filteredDataForChartConfig.paymentData[index]),
      backgroundColor: '#E87461',
      barPercentage: 0.7,
      categoryPercentage: 0.3,
      borderRadius: 10,
    },
    {
      label: 'Нарахування',
      data: months.map((month, index) => filteredDataForChartConfig.accrualsData[index]),
      backgroundColor: '#229C71',
      barPercentage: 0.7,
      categoryPercentage: 0.3,
      borderRadius: 10,
    },
  ];

  // const periodTab = [
  //   {
  //     label: 'грн.',
  //     content: () => (
  //       <Chart
  //         tableData={chartMock}
  //         chartConfigData={chartConfigData}
  //         currentFilterYear={currentFilterYear}
  //         setCurrentFilterYear={setCurrentFilterYear}
  //       />
  //     )
  //   },
  //   {
  //     label: page === 'energiya' ? 'кВт' : 'м³',
  //     content: () => <Chart  tableData={chartMock} page={page} />,
  //   },
  // ];

  return (
    <div className="dashboard-b2c-item analytics-b2c">
      <div className="analytics-b2c-header">
        <div className="dashboard-b2c-item-header__title">
          Історія споживання {page === 'energiya' ? 'електоенергії' : 'газу'}
        </div>
      </div>

      <div className="analytics-b2c-history-chart">
        <Chart
          tableData={chartMock}
          chartConfigData={chartConfigData}
          chartConfigLabels={months}
          currentFilterYear={currentFilterYear}
          setCurrentFilterYear={setCurrentFilterYear}
        />
      </div>
    </div>
  );
};

export default DashboardHistory;
