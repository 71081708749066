import { handleServiceResponse } from './service.utils';

const fetchData = (url, method, body, headers = null) => {
  const defaultHeaders = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  };
  const options = {
    method: method,
    headers: headers ? Object.assign(defaultHeaders, headers) : defaultHeaders,
  };
  if (body) {
    Object.assign(options, { body: JSON.stringify(body) });
  }

  return fetch(url, { ...options }).then(handleServiceResponse);
};

const instanceFetch = {
  get: async (url, headers = null) => await fetchData(url, 'GET', null, headers),
  post: async (url, body, headers = null) => await fetchData(url, 'POST', body, headers),
  patch: async (url, body, headers = null) => await fetchData(url, 'PATCH', body, headers),
  put: async (url, body, headers = null) => await fetchData(url, 'PUT', body, headers),
  delete: async (url, headers = null) => await fetchData(url, 'DELETE', headers),
};

export { instanceFetch };
