import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'antd';
import { withRouter } from 'react-router-dom';
import { forgotPasswordOtpResetService } from '../../../services/auth';
import Button from '../../../common/Button/Button';
import InputText from '../../../common/Inputs/InputText';
import renderErrors from '../../../helper/functions';

const ForgotPasswordOtpReset = ({ history, options, selectedOption, forgotPasswordValue }) => {
  const [form] = Form.useForm();
  const [successModal, setSuccessModal] = useState(false);
  const [error, setError] = useState({
    message: null,
    messages: null,
  });

  const handlerSubmit = ({ otp, password }) => {
    forgotPasswordOtpResetService({
      otp: otp,
      login: forgotPasswordValue,
      password: password,
    })
      .then(() => {
        setSuccessModal(true);
      })
      .catch((error) => {
        error.errors
          ? setError({
              messages: error.errors,
              message: null,
            })
          : setError({
              message: error.message,
              messages: null,
            });
      });
  };

  const emailOption = options[0].name;
  const phoneOption = options[1].name;

  useEffect(() => {
    renderErrors(form, error.messages);
  }, [error.messages]);

  return (
    <div className="registration-content">
      <span className="reg-content-title-black">
        На ваш{' '}
        <span className="email lowercase">{selectedOption === 1 ? emailOption : phoneOption}</span>{' '}
        {forgotPasswordValue} надіслано код для активації особистого кабінету
      </span>

      <Form form={form} onFinish={handlerSubmit}>
        <InputText
          placeholder="Введіть код у поле нижче"
          labelText="Введіть код у поле нижче"
          name="otp"
          rules={[{ required: true, message: 'Будь ласка введіть код' }]}
        />

        <InputText
          password
          placeholder="Введіть пароль"
          labelText="Введіть пароль"
          name="password"
          rules={[{ required: true, message: 'Будь ласка введіть код' }]}
        />

        <InputText
          password
          placeholder="Повторіть пароль"
          labelText="Повторіть пароль"
          rules={[
            {
              required: true,
              message: 'Будь ласка введіть код',
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('Введені вами паролі не збігаються!');
              },
            }),
          ]}
        />

        <Button type="primary" typeHtml="submit" text="Зберегти і ввійти" />
        {error.message && <div className="error">{error.message}</div>}
      </Form>

      <Modal
        className="success-modal"
        visible={successModal}
        footer={null}
        onCancel={() => setSuccessModal(false)}
      >
        <div className="success-modal__wrapper">
          <div className="success-modal__title">Ваш пароль успішно змінений!</div>

          <Button onClick={() => history.push('/sign-in')} type="primary" text="Авторизуватися" />
        </div>
      </Modal>
    </div>
  );
};

export default withRouter(ForgotPasswordOtpReset);
