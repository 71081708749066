import { AUTH_ACTIONS, COMPANIES } from '../types/types';
import { getCompaniesService, getGasB2BCompaniesService } from '../../services/companies';

const success = (payload) => {
  const companies = {
    accountCompanies: payload.accountCompanies,
  };
  localStorage.setItem('accountCompanies', JSON.stringify(companies));

  return {
    type: COMPANIES.GET_B2B,
    payload: {
      accountCompanies: companies,
    },
  };
};

const getCompanies = () => {
  return (dispatch) => {
    return getCompaniesService().then((response) => {
      dispatch({
        type: COMPANIES.GET,
        payload: {
          companies: response,
        },
      });
    });
  };
};

const getGasB2BCompanies = (payload) => {
  return (dispatch) => {
    return getGasB2BCompaniesService({
      ...payload,
    }).then((response) => {
      dispatch({
        type: COMPANIES.GET_B2B,
        payload: {
          accountCompanies: response,
        },
      });
    });
  };
};

export { getCompanies, getGasB2BCompanies };
