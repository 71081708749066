import React from 'react';
import { Form, Switch } from 'antd';

const SwitchField = ({ name, rules, text, checked }) => {
  return (
    <div className="field switch-field">
      <Form.Item name={name} valuePropName="checked" rules={rules} label={text}>
        <Switch checked={checked} defaultChecked />
      </Form.Item>
    </div>
  );
};

export default SwitchField;
