import React from 'react';
import SidebarStep3B2b from './SidebarStep3B2b';
import ContentStep3B2b from './ContentStep3B2b';

const RegistrationStep3B2b = ({ handleStep, loginValue, sendVia, setSendVia }) => {
  return (
    <>
      <SidebarStep3B2b handleStep={handleStep} sendVia={sendVia} />
      <ContentStep3B2b
        handleStep={handleStep}
        loginValue={loginValue}
        sendVia={sendVia}
        setSendVia={setSendVia}
      />
    </>
  );
};

export default RegistrationStep3B2b;
