import React, { useEffect, useMemo, useState } from 'react';
import OrderEnergiyaFilter from './OrderEnergiyaFilter';
import OrderEnergiyaCreate from './OrderEnergiyaCreate';
import { instanceFetch } from '../../utils/instanceFetch';
import { middlewareUrl } from '../../services/config';
import authHeader from '../../utils/authHeader';

const OrderEnergiya = () => {
  const [contractsArr, setContractsArr] = useState([]);
  const [pointsArr, setPointsArr] = useState([]);
  const [pointsName, setPointsName] = useState([]);
  const [orderFilter, setOrderFilter] = useState({});
  const [resetForm, setResetForm] = useState(false);
  const [contract, setContract] = useState([]);
  const [selectedPoint, setSelectedPoint] = useState([]);
  const filtersLength = Object.keys(orderFilter).length;
  let accountEdrpou;
  let act;

  useEffect(() => {
    /*instanceFetch.get(`${middlewareUrl}/user/info`, authHeader())
      .then(response => {
          accountEdrpou = response.accountCompanies[0].edrpou
        }
      )
      .then(() => {
        instanceFetch.get(`${middlewareUrl}/e-b2b/contract/organization/code/${accountEdrpou}`, authHeader())
          .then(response => {
            setContractsArr(response.map(item => item.number))
            setContract(response)
          })
      })*/

    instanceFetch
      .get(`${middlewareUrl}/user/info`, authHeader())
      .then((response) => {
        const accountCompanies = response.accountCompanies;
        const fetchPromises = accountCompanies.map((company) => {
          const accountEdrpou = company.edrpou;
          return instanceFetch.get(
            `${middlewareUrl}/e-b2b/contract/organization/code/${accountEdrpou}`,
            authHeader()
          );
        });
        return Promise.all(fetchPromises);
      })
      .then((responses) => {
        const contractsArr = responses.flatMap((response) => response.map((item) => item.number));
        const contract = responses;
        setContractsArr(contractsArr);
        setContract(contract);
      })
      .catch((error) => {
        console.log(error);
        // Обробка помилок
      });
  }, []);
  // console.log(selectedPoint)
  useEffect(() => {
    if (orderFilter.filterContract) {
      const selectedContract = contract.filter(
        (contr) => contr.number === orderFilter.filterContract
      );
      setContract(selectedContract);
      setPointsArr(selectedContract.map((item) => item.meteringPoints));
      setPointsName(
        selectedContract.map((item) => item.meteringPoints.map((name) => name.eic)).flat()
      );
      setPointsName((prevState) => ['Всі', ...prevState]);
    }

    if (filtersLength === 5) {
      act = contract.filter((act) => {
        const selectedPoint = act.meteringPoints.filter((item) => item.eic === orderFilter.point);
        setSelectedPoint(selectedPoint.length ? selectedPoint : act.meteringPoints);

        return act.number === orderFilter.filterContract;
      });

      // console.log(act)

      if (act !== undefined) {
        const month = (orderFilter.filterIndex + 1)
          .toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
          .toString();
        const year = orderFilter.filterYear.toString();
        const range = year + month;

        instanceFetch
          .post(
            `${middlewareUrl}/e-b2b/consumption/search`,
            {
              accountId: act[0].id,
              periodFrom: Number(range),
              periodTo: Number(range),
            },
            authHeader()
          )
          .then((res) => {
            // console.log(res)
          });
      }
    }
  }, [orderFilter]);

  // console.log('orderFilter', orderFilter)
  // console.log('pointsName', pointsName)
  // console.log('contract', contract)

  return (
    <div>
      <OrderEnergiyaFilter
        setResetForm={setResetForm}
        resetForm={resetForm}
        setOrderFilter={setOrderFilter}
        orderFilter={orderFilter}
        contractsArr={contractsArr}
        pointsArr={pointsArr}
        pointsName={pointsName}
      />
      {filtersLength === 5 && (
        <OrderEnergiyaCreate
          orderFilter={orderFilter}
          setResetForm={setResetForm}
          contract={contract}
          selectedPoint={selectedPoint}
          accountId={contract[0].id}
        />
      )}
    </div>
  );
};

export default OrderEnergiya;
