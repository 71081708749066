import React from 'react';
import Button from '../../common/Button/Button';

const ConfirmStep = ({ title, subtitle, button, icon, children, onClose }) => {
  return (
    <div className="confirm-step">
      {icon && (
        <div className="confirm-step-icon">
          <img src="/icons/confirm1.svg" />
        </div>
      )}

      {title && <div className="confirm-step-title">{title}</div>}

      {children}

      {subtitle && <div className="confirm-step-sub-title">{subtitle}</div>}

      {button && <Button type="primary" text="Зрозуміло" onClick={onClose} />}
    </div>
  );
};

export default ConfirmStep;
