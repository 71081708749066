import React, { useState } from 'react';
import { connect } from 'react-redux';
import ProfileModeration from './ProfileModeration';
import UserInfo from './UserInfo';
import UpdateUserContacts from './UpdateUserContacts';
import { Link } from "react-router-dom";

const MyProfileTab = ({ userInfo }) => {
  const [renderContent, setRenderContent] = useState('userInfo');

  if (!userInfo.gasB2b) return <ProfileModeration />;

  const contentList = {
    userInfo: <UserInfo setRenderContent={setRenderContent} />,
    email: <UpdateUserContacts loginTypeName="email" setRenderContent={setRenderContent} />,
    phone: <UpdateUserContacts loginTypeName="phone" setRenderContent={setRenderContent} />,
  };

  return (
    <div className="profile">
      <div className="tab-label-list">
        <div className="tab-label primary active">
          <span className="tab-label__text">Мій профіль</span>
        </div>
        <Link to="/profile/change-password" className="tab-label primary">
          <span className="tab-label__text">Змінити пароль</span>
        </Link>
        <Link to="/profile/companies" className="tab-label primary">
          <span className="tab-label__text">Мої компанії</span>
        </Link>
        <Link to="/profile/notifications" className="tab-label primary">
          <span className="tab-label__text">Сповіщення</span>
        </Link>
      </div>
      <div className="profile-tab-content">
        <div className="profile__block">{contentList[renderContent]}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (store) => {
  const { application } = store;
  return {
    userInfo: application.userInfo,
  };
};

export default connect(mapStateToProps)(MyProfileTab);
