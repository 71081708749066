import React from 'react';
import { Modal } from 'antd';
import cx from 'classnames';
import { checkRoute } from '../../AppLayout';
import { useLocation } from 'react-router-dom';

const ModalWrapper = ({
  className,
  title,
  modalWidth,
  children,
  isModalVisible,
  handleClick,
  closeIcon,
  afterClose,
}) => {
  const { pathname } = useLocation();
  const modalClassName = cx(className, checkRoute(pathname));

  return (
    <Modal
      className={modalClassName}
      visible={isModalVisible}
      title={title}
      footer={null}
      onCancel={handleClick}
      width={modalWidth}
      closeIcon={closeIcon}
      afterClose={afterClose}
    >
      {children}
    </Modal>
  );
};

export default ModalWrapper;
