import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/index';

const auth = JSON.parse(localStorage.getItem('auth'));

export const defaultState = {
  application: {
    auth: auth
      ? auth
      : {
          pending: false,
          error: false,
          token: null,
          user: null,
        },
    profile: {
      firstname: '',
      middlename: '',
      lastname: '',
      phone: '',
      email: '',
    },
    referenceBooks: {
      regions: [],
    },
    userInfo: {
      firstName: '',
      lastName: '',
      email: '',
      accountsList: null,
      gasB2c: null,
      gasB2b: null,
      eB2b: null,
      accountCompanies: null,
    },
    companies: [],
  },
};

const storeCreator = (initialState = {}) => {
  return createStore(rootReducer, initialState, applyMiddleware(thunk));
};

const store = storeCreator(defaultState);

export default store;
