import React, { useEffect, useState } from 'react';
import { Form, InputNumber, Modal, Select, Spin } from 'antd';
import Button from '../../common/Button/Button';
import NewInvoiceGasB2BModal from '../NewInvoiceGasB2B/NewInvoiceGasB2BModal';
import moment from 'moment';
import { middlewareUrl } from '../../services/config';
import { instanceFetch } from '../../utils/instanceFetch';
import authHeader from '../../utils/authHeader';
import { Link } from 'react-router-dom';

const EnergyInvoiceItem = ({ item, accountEdrpou, isFop }) => {
  const [isRenderErrorModal, setRenderErrorModal] = useState(false);
  const [renderSuccessModal, setRenderSuccessModal] = useState(false);
  const [renderInvoiceModal, setRenderInvoiceModal] = useState(false);
  const [renderInvoiceSuccess, setRenderInvoiceSuccess] = useState(false);
  const [errorModalText, setErrorModalText] = useState('');
  const [isLoading, setLoading] = useState({
    pay: false,
    check: false,
  });
  const [currentValue, setCurrentValue] = useState(item.to_pay_value || 0);
  const [previousValue, setPreviousValue] = useState(item.to_pay_value || 0);
  const [isDisabledButton, setDisabledButton] = useState(false);
  const [form] = Form.useForm();

  const ROUTES = {
    INVOICE: new URL(`${middlewareUrl}/e-b2b/kosht/generate-invoice`),
    PDF: new URL(`${middlewareUrl}/b2b/request-contract-invoice`),
    INVOICE_CONTRACT: new URL(`${middlewareUrl}/b2b/request-contract-invoice`),
  };

  const handleSubmit = (values) => {
    const amountPrepare = `${values.amount * 100}`;
    setLoading({
      ...isLoading,
      pay: true,
    });

    instanceFetch
      .post(
        ROUTES.INVOICE,
        {
          edrpou: item.consumer_edrpou,
          contract: item.number,
          amount: amountPrepare,
          contractDate: new Date(item.date).toLocaleDateString().replaceAll('/', '.'),
          redirect_url_success: 'https://my.gaszbut.com.ua/e-b2b/new-invoice',
          redirect_url_error: 'https://my.gaszbut.com.ua/e-b2b/new-invoice',
        },
        authHeader()
      )
      .then((response) => {
        setLoading({
          ...isLoading,
          pay: false,
        });
        window.open(`${response.url}?success`);
      })
      .catch((e) => {
        setLoading({
          ...isLoading,
          pay: false,
        });
        setErrorModalText(e.message);
        setRenderErrorModal(true);
      });
  };

  const handleSubmitInvoice = (values) => {
    const amountPrepare = Number(values.amount);
    setLoading({
      ...isLoading,
      pay: true,
    });

    instanceFetch
      .post(
        ROUTES.INVOICE_CONTRACT,
        {
          invoiceType: 1,
          consumerAmount: amountPrepare,
          counterpartyId: item.consumer_edrpou,
          contractNumber: item.number,
          contractDate: new Date(item.date).toLocaleDateString('en-CA'),
          month: values.date,
        },
        authHeader()
      )
      .then((response) => {
        setLoading({
          ...isLoading,
          pay: false,
        });
        setRenderInvoiceModal(false);
        setRenderInvoiceSuccess(true);
        // window.open(`${response.url}?success`)
      })
      .catch((e) => {
        setLoading({
          ...isLoading,
          pay: false,
        });
        setErrorModalText(e.message);
        setRenderErrorModal(true);
      });
  };

  const isValidInput = (value) => {
    const regex = /^[0-9]+(\.[0-9]{0,2})?$/;
    return regex.test(value);
  };

  const formatter = (value) => {
    if (isValidInput(value)) {
      setPreviousValue(value);
      return value;
    }
    return previousValue;
  };

  const formatterModal = (value) => {
    if (isValidInput(value)) {
      setPreviousValue(value);
      return value;
    }
    return previousValue;
  };

  const handleFormChange = (value) => {
    if (isValidInput(value)) {
      setCurrentValue(value);
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  };

  const getPdf = () => {
    const currentDate = moment(item.date).format('YYYY-MM-DD');

    setLoading({
      ...isLoading,
      check: true,
    });

    instanceFetch
      .post(
        ROUTES.PDF,
        {
          invoiceType: 0,
          counterpartyId: item.consumer_edrpou,
          contractNumber: item.number,
          contractDate: currentDate,
        },
        authHeader()
      )
      .then((response) => {
        setLoading({
          ...isLoading,
          check: false,
        });

        setRenderSuccessModal(true);
      })
      .catch(() => {
        setLoading({
          ...isLoading,
          check: false,
        });
        setRenderErrorModal(true);
      });
  };

  const monthNames = [
    'Січень',
    'Лютий',
    'Березень',
    'Квітень',
    'Травень',
    'Червень',
    'Липень',
    'Серпень',
    'Вересень',
    'Жовтень',
    'Листопад',
    'Грудень',
  ];

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  const dates = [];

  for (let i = 6; i >= 1; i--) {
    const prevMonthDate = new Date(currentYear, currentMonth - i, 1);
    const monthIndex = prevMonthDate.getMonth();
    const monthName = monthNames[monthIndex];
    const year = prevMonthDate.getFullYear();
    const prevMonthDateString = `${year}-${(monthIndex + 1).toString().padStart(2, '0')}-01`;
    dates.push({ name: `${monthName} ${year}`, id: prevMonthDateString });
  }

  const currentMonthName = monthNames[currentMonth];
  const currentMonthYear = currentYear;
  const currentMonthDate = `${currentYear}-${(currentMonth + 1).toString().padStart(2, '0')}-01`;
  dates.push({ name: `${currentMonthName} ${currentMonthYear}`, id: currentMonthDate });

  const futureMonthDate = new Date(currentYear, currentMonth + 1, 1);
  const futureMonthIndex = futureMonthDate.getMonth();
  const futureMonthName = monthNames[futureMonthIndex];
  const futureMonthYear = futureMonthDate.getFullYear();
  const futureMonthDateString = `${futureMonthYear}-${(futureMonthIndex + 1)
    .toString()
    .padStart(2, '0')}-01`;
  dates.push({ name: `${futureMonthName} ${futureMonthYear}`, id: futureMonthDateString });

  useEffect(() => {
    setPreviousValue(currentValue);
  }, [currentValue]);

  return (
    <div className="dashboard-b2c-item new-invoice-item">
      <div className="dashboard-b2c-item__inner">
        <div className="dashboard-b2c-item-header">
          <div className="dashboard-b2c-item-header__title">Договір №{item.number}</div>

          <div className="dashboard-b2c-item-header__text">
            Від {new Date(item.date).toLocaleDateString().replaceAll('/', '.')} р.
          </div>
        </div>

        <div className="amount-to-pay">
          {isFop && (
            <>
              <div className="amount-to-pay__title">Сплатити онлайн</div>

              <Form
                onValuesChange={handleFormChange}
                onFinish={handleSubmit}
                initialValues={{ amount: currentValue }}
              >
                <div className="amount-to-pay-position">
                  <div className="amount-to-pay__input">
                    <Form.Item name="amount" className="field">
                      <InputNumber
                        value={currentValue}
                        className="field__input"
                        parser={(value) => value.replace(',', '.')}
                        formatter={formatter}
                        onChange={handleFormChange}
                      />
                    </Form.Item>

                    <div className="currency">грн.</div>
                  </div>

                  {/*<div className='amount-to-pay-position__label'>Поставка 3 м3 газу</div>*/}
                </div>

                <Button
                  disabled={isDisabledButton}
                  typeHtml="submit"
                  type="primary primary-green"
                  text="Сплатити"
                >
                  {isLoading.pay && (
                    <div className="btn__loading">
                      <Spin />
                    </div>
                  )}
                </Button>
              </Form>
            </>
          )}
          {/*<Button
            onClick={() => getPdf()}
            className={`dashboard-download ${!isFop && 'dashboard-download--tov'}`}
            type="outline-secondary"
          >
            <img src="/icons/download.svg" className="btn__icon-left" alt="plus-icon"/>
            <span>Рахунок на оплату</span>
            {isLoading.check && <div className='btn__loading'>
              <Spin />
            </div>}
          </Button>*/}
        </div>
        <div className="amount-to-pay generate-invoice">
          <div className="amount-to-pay__title">Замовити рахунок</div>
          <Button onClick={() => getPdf()} className="dashboard-download" type="outline-secondary">
            <img src="/icons/download.svg" className="btn__icon-left" alt="plus-icon" />
            <span>Фактичне споживання</span>
            {/*{isLoading.check && <div className='btn__loading'>*/}
            {/*  <Spin />*/}
            {/*</div>}*/}
          </Button>
          <Button
            onClick={() => setRenderInvoiceModal(true)}
            className="dashboard-download"
            type="outline-secondary"
          >
            <img src="/icons/download.svg" className="btn__icon-left" alt="plus-icon" />
            <span>Передплата</span>
            {/*{isLoading.check && <div className='btn__loading'>*/}
            {/*  <Spin />*/}
            {/*</div>}*/}
          </Button>
        </div>
        <Modal
          className="success-modal"
          visible={isRenderErrorModal}
          footer={null}
          onCancel={() => setRenderErrorModal(false)}
        >
          <div className="success-modal__wrapper">
            <div className="success-modal__title">Помилка!</div>
            <div className="success-modal__body">{errorModalText}</div>
          </div>
        </Modal>

        <Modal
          className="success-modal"
          visible={renderSuccessModal}
          footer={null}
          onCancel={() => setRenderSuccessModal(false)}
        >
          <div className="success-modal__wrapper">
            <div className="success-modal__title success-modal__title--green">
              Ваш рахунок згенеровано
            </div>
            <div className="success-modal__body">
              Рахунок незабаром буде доступним у розділі “Документи” і зберігатиметься протягом 3х
              років
            </div>
            <div className="success-modal__buttons">
              <Link to="/energiya/documents/invoices">
                <button type="button" className="btn primary primary-green">
                  <span>До документів</span>
                </button>
              </Link>
              <Button className="btn outline-primary outline-primary-green" onClick={() => setRenderSuccessModal(false)}>
                <span className="btn-text">Закрити</span>
              </Button>
            </div>
          </div>
        </Modal>

        <Modal
          className="success-modal success-modal--invoice-success"
          visible={renderInvoiceSuccess}
          footer={null}
          onCancel={() => setRenderInvoiceSuccess(false)}
        >
          <div className="success-modal__wrapper">
            <div className="success-modal__title success-modal__title--green">
              Ваш рахунок згенеровано
            </div>
            <div className="success-modal__body">
              Рахунок незабаром буде доступним у розділі “Документи” і зберігатиметься протягом 3х
              років
            </div>
            <div className="success-modal__buttons">
              <Button className="btn outline-primary outline-primary-green" onClick={() => getPdf()}>
                <span className="btn-text">Завантажити</span>
              </Button>
              <Link to="/energiya/documents/invoices">
                <button type="button" className="btn primary primary-green">
                  <span>До документів</span>
                </button>
              </Link>
            </div>
          </div>
        </Modal>

        <Modal
          className="invoice-pay-modal"
          visible={renderInvoiceModal}
          footer={null}
          onCancel={() => setRenderInvoiceModal(false)}
        >
          <div className="success-modal__wrapper">
            <div className="success-modal__title success-modal__title--green">
              Вказати сумму рахунку
            </div>
            <div className="success-modal__body">
              На дану суму буде сформовано рахунок. В разі сплати рахунку сума якого перевищує
              фактичний спожитий обʼєм, гроші будуть зараховані в якості передплати за майбутнє
              споживання
            </div>
            <Form
              form={form}
              onFinish={handleSubmitInvoice}
              className="amount-to-pay-form"
              initialValues={{ amount: item.to_pay_value ? item.to_pay_value : 0 }}
              // onValuesChange={handleFormChange}
              // onFinish={handleSubmit}
            >
              <div className="amount-to-pay-position">
                <div className="amount-to-pay__input">
                  <div>
                    <label htmlFor="date">Місяць споживання</label>
                    <Form.Item name="date" className="field">
                      <Select
                        placeholder="Місяць споживання"
                        suffixIcon={<img src="/icons/arrow-select.svg" alt="arrow-icon" />}
                      >
                        {dates.map(({ name, id }) => (
                          <Select.Option key={id} value={id}>
                            {name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="amount-to-pay__input">
                  <div>
                    <label htmlFor="amount">Сума рахунку</label>
                    <div className="field__input-wrap">
                      <Form.Item name="amount" className="field">
                        <InputNumber
                          className="field__input"
                          parser={(value) => value.replace(',', '.')}
                          formatter={formatter}
                          onChange={handleFormChange}
                        />
                      </Form.Item>

                      <div className="currency">грн.</div>
                    </div>
                  </div>
                </div>

                {/*<div className='amount-to-pay-position__label'>Поставка 3 м3 газу</div>*/}
              </div>

              <Button
                disabled={isDisabledButton}
                typeHtml="submit"
                type="primary primary-green"
                text="Підтвердити"
              >
                {isLoading.pay && (
                  <div className="btn__loading">
                    <Spin />
                  </div>
                )}
              </Button>
            </Form>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default EnergyInvoiceItem;
