import React from 'react';
import SidebarStep1 from '../Registration/Step1/SidebarStep1';

const TemporaryUnavailable = () => {
  return (
    <div className="registration">
      <SidebarStep1 />
      <div className="registration-content">
        <span className="registration-content__title">Шановні клієнти!</span>
        <span className="registration-content__sub-title">
          Наші технічні спеціалісти покращують роботу Особистого кабінету і їм необхідно сьогодні
          ненадовго обмежити ваш доступ до нього. Технічні роботи триватимуть з 14:00 до 23:00.
        </span>
      </div>
    </div>
  );
};

export default TemporaryUnavailable;
