import React from 'react';

const SidebarStep3B2b = ({ handleStep, sendVia }) => {
  return (
    <div className="registration-sidebar">
      <div
        className="reg-sidebar-back-to"
        onClick={() => {
          handleStep((p) => p - 1);
        }}
      >
        <img src="/icons/arrow-back.svg" />
        <span>Реєстрація в онлайн-кабінеті -B2b-</span>
      </div>

      <span className="reg-sidebar-title">Рухаємося далі</span>
      <span className="reg-sidebar-text">
        {sendVia === '1'
          ? 'Щоб продовжити реєстрацію, будь ласка, відкрийте наш лист на пошті й введіть зазначений код активації.'
          : 'Щоб продовжити реєстрацію, будь ласка, відкрийте СМС на телефоні й введіть зазначений код активації.'}
      </span>
    </div>
  );
};

export default SidebarStep3B2b;
