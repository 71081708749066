import React, { useMemo, useState } from 'react';
import RegistrationStep3B2b from './Step3/RegistrationStep3B2b';
import RegistrationStep4B2b from './Step4/RegistrationStep4B2b';
import RegistrationStep2B2b from './Step2/RegistrationStep2B2b';

const REGISTRATION_STEP = [RegistrationStep2B2b, RegistrationStep3B2b, RegistrationStep4B2b];

const SignUpB2B = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [loginValue, setLoginValue] = useState('');
  const [sendVia, setSendVia] = useState('');

  const Content = useMemo(() => REGISTRATION_STEP[activeStep], [activeStep]);

  return (
    <div className="registration">
      <Content
        handleStep={setActiveStep}
        loginValue={loginValue}
        setLoginValue={setLoginValue}
        sendVia={sendVia}
        setSendVia={setSendVia}
      />
    </div>
  );
};

export default SignUpB2B;
