import React from 'react';
import cx from 'classnames';

const TabLabel = ({ active, onClick, className, disabled, label, type = 'primary', children }) => {
  const tabClassName = cx(
    'tab-label',
    active && 'active',
    disabled && 'disabled',
    type && type,
    className
  );

  return (
    <div className={tabClassName} onClick={onClick}>
      <span className="tab-label__text">{label}</span>
      {children}
    </div>
  );
};

export default TabLabel;
