import React from 'react';
import './assets/styles/styles.scss';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/';
import AppLayout from './AppLayout';
import { history } from './utils/history.utils';

const App = () => {
  return (
    <Provider store={store}>
      <Router history={history}>
        <AppLayout />
      </Router>
    </Provider>
  );
};

export default App;
