import React, { useEffect, useState } from 'react';
import Chart from '../../common/Chart/Chart';
import { useLocation } from 'react-router-dom';
import chartMock from './chartMock.json';
import moment from 'moment';
import { instanceFetch } from '../../utils/instanceFetch';
import { middlewareUrl } from '../../services/config';
import authHeader from '../../utils/authHeader';

const months = [
  'Січень',
  'Лютий',
  'Березень',
  'Квітень',
  'Травень',
  'Червень',
  'Липень',
  'Серпень',
  'Вересень',
  'Жовтень',
  'Листопад',
  'Грудень',
];

const DashboardHistory = ({ documentsArr, date }) => {
  const [currentFilterYear, setCurrentFilterYear] = useState('2022');
  const [consumptionData, setConsumptionData] = useState([]);
  const { pathname } = useLocation();
  const chartTemplate = consumptionData.map((item) => {
    const year = item.period.toString().slice(0, 4);
    const month = item.period.toString().slice(4);
    const consumptionsArray = item.consumptions.map((consumption) => consumption.amount);
    return {
      created_at: `${year}-${month}-01`,
      saldo_month_begin: 0,
      saldo_month_end: 0,
      payment: 0,
      total_exemption: 0,
      recalculation: 0,
      fine: 0,
      accruals: consumptionsArray.reduce((sum, num) => sum + num, 0),
    };
  });
  const page = pathname.split('/')[1];

  useEffect(() => {
    for (let dashboardItem of documentsArr) {
      instanceFetch
        .post(
          `${middlewareUrl}/e-b2b/consumption/search`,
          {
            accountId: dashboardItem.id,
            periodFrom: 202101,
            periodTo: Number(date),
          },
          authHeader()
        )
        .then((response) => {
          setConsumptionData((array) => [...array, ...response]);
        });
    }
  }, []);

  const filteredDataForChartConfig = chartTemplate
    .filter(({ created_at }) => {
      const year = moment(created_at).format('YYYY');

      return year === currentFilterYear;
    })
    .sort((prev, next) => {
      return moment(prev.created_at).format('M') - moment(next.created_at).format('M');
    })
    .reduce(
      (data, currentValue) => {
        const { payment, accruals, recalculation } = currentValue;
        const calcAccruals = accruals + recalculation;

        data.paymentData.push(payment.toFixed(2));
        data.accrualsData.push(calcAccruals.toFixed(2));
        return data;
      },
      { paymentData: [], accrualsData: [] }
    );

  const chartConfigData = [
    {
      label: 'Використано',
      data: months.map((month, index) => filteredDataForChartConfig.accrualsData[index]),
      backgroundColor: '#229C71',
      barPercentage: 0.4,
      categoryPercentage: 0.3,
      borderRadius: 10,
    },
  ];

  return (
    <div className="dashboard-b2c-item analytics-b2c">
      <div className="analytics-b2c-header">
        <div className="dashboard-b2c-item-header__title">
          Історія споживання {page === 'energiya' ? 'електоенергії' : 'газу'}
        </div>
      </div>

      <div className="analytics-b2c-history-chart">
        <Chart
          tableData={chartTemplate}
          chartConfigData={chartConfigData}
          chartConfigLabels={months}
          currentFilterYear={currentFilterYear}
          setCurrentFilterYear={setCurrentFilterYear}
        />
      </div>
    </div>
  );
};

export default DashboardHistory;
