import { REFERENCE_BOOKS_ACTIONS } from '../types/types';
import { regionsService } from '../../services/referenceBooks';

const regions = () => {
  return (dispatch) => {
    return regionsService().then((response) => {
      dispatch({
        type: REFERENCE_BOOKS_ACTIONS.REGIONS,
        payload: response,
      });
      return response;
    });
  };
};

const allReferences = () => {
  return (dispatch) => {
    return Promise.all([regions()(dispatch)]).then((values) => {
      dispatch({
        type: REFERENCE_BOOKS_ACTIONS.ALL,
      });
    });
  };
};

export { regions, allReferences };
