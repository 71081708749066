import React, { useRef, useEffect, useState } from 'react';
import { Chart, registerables } from 'chart.js';
import FormDropdown from '../FormDropdown/FormDropdown';
import moment from 'moment';

Chart.register(...registerables);

const AnalyticsChart = ({
  tableData = [],
  chartConfigData,
  chartConfigLabels,
  setCurrentFilterYear,
}) => {
  const [chartInstance, setChartInstance] = useState(null);
  const chartContainer = useRef(null);

  const renderTooltipTitle = (chartItem) => {
    return `${chartItem[0].raw} грн`;
  };

  const renderTooltipLabel = (chartItem) => {
    return chartItem.dataset.label;
  };

  const chartConfig = {
    type: 'bar',
    data: {
      labels: chartConfigLabels,
      datasets: chartConfigData,
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'bottom',
        },
        tooltip: {
          callbacks: {
            title: renderTooltipTitle,
            label: renderTooltipLabel,
          },
        },
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    },
  };

  const updateDataset = () => {
    if (!chartInstance) return null;
    chartInstance.data.datasets = chartConfigData;
    chartInstance.update();
  };

  const handleSelectChange = (value) => {
    setCurrentFilterYear(value);
  };

  // console.log(chartInstance)
  // console.log(chartContainer)
  // console.log(chartContainer.current !== null)

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const newChartInstance = new Chart(chartContainer.current, chartConfig);
      setChartInstance(newChartInstance);
    }
  }, [chartContainer]);

  useEffect(() => {
    updateDataset();
  }, [chartConfigData]);

  const periods = tableData.reduce((years, currentValue) => {
    const year = moment(currentValue.created_at).format('YYYY');

    if (!years.includes(year)) years.push(year);

    return years;
  }, []);

  // if (!tableData.length) return null;

  return (
    <>
      <div className="analytics-b2c-history-chart__header">
        <div className="analytics-b2c-history-chart__title">Історія споживання</div>
        {periods.length && (
          <form className="form-selected-filter">
            <FormDropdown handleSelectChange={handleSelectChange} options={periods} />
          </form>
        )}
      </div>

      <div className="dashboard-history-volume">
        <canvas ref={chartContainer} height="222" />
      </div>
    </>
  );
};

export default AnalyticsChart;
