import React from 'react';
import SidebarStep3 from './SidebarStep3';
import ContentStep3 from './ContentStep3';

const RegistrationStep3 = ({ handleStep, loginValue, sendVia, setSendVia }) => {
  return (
    <>
      <SidebarStep3 handleStep={handleStep} sendVia={sendVia} />
      <ContentStep3
        handleStep={handleStep}
        loginValue={loginValue}
        sendVia={sendVia}
        setSendVia={setSendVia}
      />
    </>
  );
};

export default RegistrationStep3;
