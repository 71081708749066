import React from 'react';
import { Link } from 'react-router-dom';

const SidebarForgotPassword = () => {
  return (
    <div className="registration-sidebar">
      <Link to="/sign-in" className="reg-sidebar-back-to">
        <img src="/icons/arrow-back.svg" />
        <span>Назад</span>
      </Link>

      <span className="reg-sidebar-title">Забули пароль?</span>
      <span className="reg-sidebar-text">
        Не хвилюйтеся! Укажіть вашу електронну адресу або номер телефону, і ми нагадаємо його Вам.
      </span>
    </div>
  );
};

export default SidebarForgotPassword;
