import React from 'react';
import Button from '../../common/Button/Button';
import StatusLabel from '../../common/StatusLabel/StatusLabel';

const TeamMember = ({ member, handleEditModal, handleRemoveModal }) => {
  return (
    <div className="box-shadow my-team-item item-rounded">
      <div className="header-item-wrap">
        <div className="headers-wrap">
          <div className="my-team-item-header">
            <div className="box-shadow-title">{member.name}</div>
            <div className="my-team-item-sub-header box-shadow-sub-title">
              {member.legalFormType === 'fop' ? 'ЄДРПОУ:' : 'ІПН:'} <span>{member.edrpou}</span>
            </div>
          </div>
          <div className="subheader-wrap">
            <StatusLabel text="Редагування" color="disabled" icon />

            <Button
              className="link link--red link link--disabled"
              onClick={() => handleRemoveModal(member)}
              disabled
            >
              <span>
                <img src="/icons/triangle-red.svg" />
              </span>
              <span>Видалити компанію</span>
            </Button>
          </div>
        </div>
        <div className="button-wrap">

          <Button disabled className="edit-btn" onClick={() => handleEditModal(member)}>
            <span>Редагувати назву</span>
          </Button>
        </div>
      </div>
      {/*<div className="info-box-grid">
        <div>
          <span className="info-box-grid-title">
            Email: <span>{member.email}</span>
          </span>
          <span className="info-box-grid-title">
            Номер телефону: <span>{member.phone}</span>
          </span>
        </div>
      </div>*/}
    </div>
  );
};

export default TeamMember;
