import React, { useState } from 'react';
import SidebarStep2B2b from './SidebarStep2B2b';
import ContentStep2B2b from './ContentStep2B2b';

const RegistrationStep2B2b = ({ handleStep, loginValue, setLoginValue, sendVia, setSendVia }) => {
  return (
    <>
      <SidebarStep2B2b />
      <ContentStep2B2b
        handleStep={handleStep}
        setLoginValue={setLoginValue}
        loginValue={loginValue}
        sendVia={sendVia}
        setSendVia={setSendVia}
      />
    </>
  );
};

export default RegistrationStep2B2b;
