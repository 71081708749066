import React from 'react';

const SidebarTeamCreatePassword = () => {
  return (
    <div className="registration-sidebar">
      <div className="reg-sidebar-back-to">
        <img src="/icons/arrow-back.svg" />
        <span>Реєстрація в онлайн-кабінеті</span>
      </div>

      <span className="reg-sidebar-title">Вітаємо!</span>
      <span className="reg-sidebar-sub-title">
        Користувач Ім’я Прізвище надав вам доступ до особистого кабінету компанії “Назва компанії”.
      </span>
      <span className="reg-sidebar-sub-title">
        Аби продовжити користування, створіть пароль, заповнивши цю форму.
      </span>
      <span className="reg-sidebar-sub-title">
        Для безпеки ваших даних створіть пароль, що містить не менше 8 символів, а також в ньому
        мають бути букви і цифри.
      </span>
    </div>
  );
};

export default SidebarTeamCreatePassword;
