import React, { useState } from 'react';
import { Form, Popover } from 'antd';
import OutsideClickWrapper from '../../components/OutsideClickWrapper/OutsideClickWrapper';

const CalendarInput = ({
  daysData,
  gasInDayValue,
  gasValue,
  gasDistributed,
  setGasDistributed,
  index,
  setInputValue,
}) => {
  const [inputSpinner, setInputSpinner] = useState(false);
  const [value, setValue] = useState(gasInDayValue);

  const inputSpinnerHandler = () => {
    setInputSpinner((inputSpinner) => !inputSpinner);
  };

  const inputChange = (e) => {
    setValue(e.target.value);
  };

  const incrementValue = (event, index) => {
    if (gasDistributed < gasValue) {
      setValue(value + 1);
      setGasDistributed(gasDistributed + 1);
      setInputValue(value + 1);
      daysData.find((i) => i.indx === index).daylimit = value + 1;
    }
  };

  const decrementValue = (event, index) => {
    if (gasDistributed >= 0 && value > 0) {
      setValue(value - 1);
      setGasDistributed(gasDistributed - 1);
      setInputValue(value - 1);
      daysData.find((i) => i.indx === index).daylimit = value - 1;
    }
  };

  return inputSpinner ? (
    <OutsideClickWrapper close={inputSpinnerHandler}>
      <div className="input-spinner-container">
        <div className="input-spinner-button" onClick={(e) => decrementValue(e, index)}>
          <img src="/icons/minus.svg" />
        </div>
        <input
          id={index}
          className="input-spinner"
          maxLength="4"
          type="number"
          value={value}
          onChange={inputChange}
          disabled
        />
        <div className="input-spinner-button" onClick={(e) => incrementValue(e, index)} key={index}>
          <img src="/icons/plus.svg" />
        </div>
      </div>
    </OutsideClickWrapper>
  ) : (
    <Popover
      placement="bottom"
      content="Після 12:00 корегувати завтрашній день не можна,
        доступними для редагування є післязавтрашній і всі наступні дні"
      trigger="hover"
    >
      <div className="add-to-order" onClick={inputSpinnerHandler}>
        {value === '' ? '0' : value} m3
      </div>
    </Popover>
  );
};

export default CalendarInput;
