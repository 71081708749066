import React from 'react';
import NotificationModalItem from './NotificationModalItem';
import DrawerModal from '../../common/Drawer/Drawer';

const NewsNotification = ({ handleClick, openModal }) => {
  return (
    <DrawerModal
      width={379}
      placement="right"
      title="Новини компанії"
      iconTitle={<img src="/icons/message.svg" alt="message-icon" />}
      isDrawerVisible={openModal}
      handleClick={handleClick}
      className="notification-drawer"
    >
      <NotificationModalItem
        time="25.07.2021 / 15:22"
        title="Тариф «Твій газ на зиму» заморожено"
        subTitle="Обіцяний запуск тарифу «ТВІЙ ГАЗ НА ЗИМУ» у цьому сезоні не відбудеться.
        Причиною «заморожування» тарифу є стрімке зростання ціни на природний газ як в Україні, так і в Європі."
        more
      />
      <NotificationModalItem
        time="30.06.2021 / 11:22"
        title="Хто такий постачальник останньої надії?"
        subTitle="ПОН – це постачальник «останньої надії», якого гарантує вам держава.
        Він тимчасово забезпечує газом споживачів у Донецькій, Херсонській та Полтавській областях,
        у місті Кременець Тернопільської області, у Мелітополі та Мелітопольському районі
        Запорізької області, Коростишеві та Коростишівському районі Житомирської області."
        more
      />
      <NotificationModalItem
        time="21.06.2021 / 15:22"
        title="Яких змін на ринку природного газу чекати українцям у травні?"
        subTitle="Незважаючи на це, більшість постачальників опублікувала квітневу
        ціну на газ на рівні 6,99 грн за кубометр, хоча у деяких компаній ціна кубометра газу перевищила 8 грн."
        more
      />
    </DrawerModal>
  );
};

export default NewsNotification;
