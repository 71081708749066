import React, { useMemo, useState } from 'react';
import cx from 'classnames';
import TabLabel from '../../common/Tab/TabLabel';
import DashboardContractInfo from './DashboardContractInfo';
import { contractInfo1, contractInfo2, contractInfo3 } from './dashbordContract';

const DashboardContractData = ({ isGeneralTab = false }) => {
  const periodTab = [
    {
      label: 'Загальний період',
      content: () => <DashboardContractInfo data={contractInfo1} isGeneralTab={isGeneralTab} />,
    },
    {
      label: 'Попередній місяць',
      content: () => <DashboardContractInfo data={contractInfo2} isGeneralTab={isGeneralTab} />,
    },
    {
      label: 'Поточний місяць',
      content: () => <DashboardContractInfo data={contractInfo3} isGeneralTab={isGeneralTab} />,
    },
  ];

  const [activeTab, setActiveTab] = useState(0);

  const Content = useMemo(() => {
    return periodTab[activeTab].content;
  }, [activeTab, periodTab]);

  return (
    <div className={cx('dashboard-contract-data', { 'general-info': isGeneralTab })}>
      <div className="dashboard-contract-data__header">
        <div className="dashboard-contract-data__title">Дані по всім договорам</div>

        <div className="tab-label-list skew">
          {periodTab.map(({ label }, index) => (
            <TabLabel
              type="secondary"
              key={index}
              label={label}
              onClick={() => setActiveTab(index)}
              active={activeTab === index}
            />
          ))}
        </div>
      </div>

      <Content />
    </div>
  );
};

export default DashboardContractData;
