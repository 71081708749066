import React from 'react';
import { Drawer } from 'antd';

const DrawerModal = (props) => {
  const { width, placement, title, isDrawerVisible, className, handleClick, children } = props;

  return (
    <Drawer
      title={title}
      placement={placement}
      width={width}
      onClose={handleClick}
      visible={isDrawerVisible}
      closeIcon={<img src="/icons/modal-close.svg" />}
      className={className}
    >
      {children}
    </Drawer>
  );
};

export default DrawerModal;
