import React, { useState } from 'react';
import ModalWrapper from '../../common/Modal/ModalWrapper';
import InputText from '../../common/Inputs/InputText';
import FormDropdown from '../../common/FormDropdown/FormDropdown';
import Button from '../../common/Button/Button';
import { roles } from './Team';

const TeamEdit = ({ isModalEditVisible, handleEditModal, member }) => {
  return (
    isModalEditVisible && (
      <ModalWrapper
        className="classic-modal team-edit"
        isModalVisible={isModalEditVisible}
        handleClick={handleEditModal}
        modalWidth={600}
        closeIcon={<img src="/icons/modal-close-black.svg" />}
      >
        <div className="form-head">
          <div className="form-title">Редагування компанії</div>
          <div className="form-subtitle">{member.name}</div>
        </div>
        <form className="team-form">
          <InputText placeholder="Повна назва компанії" labelText="Повна назва компанії" />
          {/*<FormDropdown
            labelText="Права доступу до кабінету"
            defaultValue="Права доступу до кабінету"
            options={roles}
          />*/}

          <div className="classic-modal-footer-btn">
            <Button type="primary" text="Зберегти" />
          </div>
        </form>
      </ModalWrapper>
    )
  );
};
export default TeamEdit;
