import React, { useEffect, useState } from 'react';
import Button from '../../common/Button/Button';
import { Form } from 'antd';
import { useSelector } from 'react-redux';
import FieldEmail from '../Registration/FieldEmail';
import renderErrors from '../../helper/functions';
import { profileUserChangeLoginService } from '../../services/profile';
import InputPhone from '../../common/Inputs/InputPhone';

const UpdateUserContacts = ({
  isEmail,
  loginType,
  setApproveForm,
  setRenderContent,
  setNewUserContact,
}) => {
  const currenContact = useSelector(({ application }) => application.profile);

  const [form] = Form.useForm();

  const [error, setError] = useState({
    message: null,
    messages: null,
  });

  const handleForm = ({ newContact }) => {
    setNewUserContact(newContact);

    profileUserChangeLoginService({
      login: isEmail ? newContact : newContact,
      login_type: loginType,
      app_code: '1',
    })
      .then(() => setApproveForm(true))
      .catch((error) => {
        error.errors
          ? setError({
              messages: error.errors,
              message: null,
            })
          : setError({
              message: error.message,
              messages: null,
            });
      });
  };

  useEffect(() => {
    renderErrors(form, error.messages);
  }, [error.messages]);

  const RenderTitle = () => {
    if (isEmail) {
      return currenContact.email ? (
        <>
          Зміна поштової адреси
          <div className="email">{currenContact.email}</div>
        </>
      ) : (
        'Додавання поштової адреси'
      );
    }

    return currenContact.phone ? (
      <>
        Зміна телефону
        <div className="email">{currenContact.phone}</div>
      </>
    ) : (
      'Додавання  телефону'
    );
  };

  return (
    <div className="form">
      <span className="form__title">
        <RenderTitle />
      </span>

      <Form onFinish={handleForm}>
        {isEmail ? (
          <FieldEmail name="newContact" label="Нова поштова адреса" />
        ) : (
          <InputPhone name="newContact" label="Новий номер телефону" />
        )}

        <Button type="primary" typeHtml="submit" text="Продовжити" />
        {error.message && <div className="error">{error.message}</div>}
      </Form>

      <button className="underline-link" onClick={() => setRenderContent('userInfo')}>
        Повернутись назад
      </button>
    </div>
  );
};

export default UpdateUserContacts;
