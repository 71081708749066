export const contractInfo3 = [
  {
    title: 'Об’єм спожитого газу',
    amount: '105',
    currency: 'м³',
    color: 'black',
  },
  {
    title: 'Стан рахунку',
    amount: '-987',
    currency: 'грн.',
    color: 'red',
  },
  {
    title: 'Перевищення замовлення',
    amount: '0%',
    currency: null,
    color: 'black',
  },
];

export const contractInfo2 = [
  {
    title: 'Об’єм спожитого газу',
    amount: '204',
    currency: 'м³',
    color: 'black',
  },
  {
    title: 'Стан рахунку',
    amount: '+1 542',
    currency: 'грн.',
    color: 'green',
  },
  {
    title: 'Перевищення замовлення',
    amount: '0%',
    currency: null,
    color: 'black',
  },
];

export const contractInfo1 = [
  {
    title: 'Об’єм спожитого газу',
    amount: '307',
    currency: 'м³',
    color: 'black',
  },
  {
    title: 'Стан рахунку',
    amount: '+1 542',
    currency: 'грн.',
    color: 'green',
  },
  {
    title: 'Перевищення замовлення',
    amount: '0%',
    currency: null,
    color: 'black',
  },
];
