import React from 'react';
import StatusLabel from '../../../common/StatusLabel/StatusLabel';
import UploadFile from '../../../common/UploadFile/UploadFile';
import Button from '../../../common/Button/Button';

const OrderEnergiyaStep1 = ({ orderFilter, selectedPoint }) => {
  return (
    <>
      <div className="order-gas-create-header">
        <span className="order-gas-create-header-title">
          {`Сформувати замовлення на ${orderFilter.filterMonth} ${orderFilter.filterYear}`}
        </span>
        <StatusLabel
          icon
          color="gray-blue"
          text="Замовлення можна подати до 19 числа поточного місяця"
        />
      </div>
      <div className="info-green-box info-box-grid">
        <div>
          <span className="info-box-grid-title">
            ТКО: <span>00912</span>
          </span>
          <span className="info-box-grid-title">
            ОСР: <span>Чернівецький</span>
          </span>
        </div>
        <div>
          <span className="info-box-grid-title">
            Група: <span>А</span>
          </span>
          <span className="info-box-grid-title">
            Клас: <span>1</span>
          </span>
        </div>
      </div>

      <div className="order-energiya-type-A">
        <span className="order-energiya-create-sub-title">
          Для подання замовлення вам необхідно заповнити спеціальну Exсel-форму та завантажити її в
          кабінет.
        </span>

        <button type="button" className="underline-link">
          Скачати спецільну Exсel-форму
        </button>
      </div>

      <UploadFile label="Завантажте заповнену Exсel-форму з ПК або перетягніть файл сюди" />

      <Button text="Сформувати замовлення" type="primary" />
    </>
  );
};

export default OrderEnergiyaStep1;
