import React from 'react';
import SidebarSignIn from '../SignIn/SidebarSignIn';
import SignInOtpContent from './SignInOtpContent';

const SignInOtp = () => {
  return (
    <div className="registration">
      <SidebarSignIn />
      <SignInOtpContent />
    </div>
  );
};

export default SignInOtp;
