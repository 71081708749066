import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { login } from '../../../store/actions/auth';
import InputText from '../../../common/Inputs/InputText';
import Button from '../../../common/Button/Button';
import { Link, withRouter } from 'react-router-dom';
import { Form } from 'antd';
import TypeSignIn from './TypeSignIn';
import renderErrors from '../../../helper/functions';

const ContentSignIn = ({ loginAction }) => {
  const [form] = Form.useForm();
  const [error, setError] = useState({
    message: null,
    messages: null,
  });

  const handlerSubmit = (values) => {
    loginAction({
      ...values,
    }).catch((error) => {
      error.errors
        ? setError({
            messages: error.errors,
            message: null,
          })
        : setError({
            message: error.message,
            messages: null,
          });
    });
  };

  useEffect(() => {
    renderErrors(form, error.messages);
  }, [error.messages]);

  return (
    <div className="registration-content">
      <span className="reg-content-title-blue">Вхід в особистий кабінет</span>
      <Form onFinish={handlerSubmit}>
        {/*<TypeSignIn />*/}

        <InputText
          name="login"
          prefix={<img src="/icons/user-form.svg" />}
          labelText="Email або номер телефону"
          tooltipText="Номер телефону у форматі +380"
          rules={[{ required: true, message: 'Будь ласка введіть Email або номер телефону!' }]}
        />

        <InputText
          password
          placeholder="Введіть пароль"
          labelText="Введіть пароль"
          prefix={<img src="/icons/lock.svg" />}
          name="password"
          rules={[{ required: true, message: 'Будь ласка введіть пароль!' }]}
        />

        <Link to="/forgot-password" className="forgot-password">
          <button type="button" className="underline-link">
            Забули пароль?
          </button>
        </Link>

        <Button type="primary" typeHtml="submit" text="Зберегти і ввійти" />
        {error.message && <div className="error">{error.message}</div>}
      </Form>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginAction: (payload) => dispatch(login(payload)),
  };
};

export default withRouter(connect(null, mapDispatchToProps)(ContentSignIn));
