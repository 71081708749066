import React, { useState } from 'react';
import UpdateUserContactsForm from './UpdateUserContactsForm';
import ApproveNewLoginForm from './ApproveNewLoginForm';

const UpdateUserContacts = ({ loginTypeName, setRenderContent }) => {
  const [newUserContact, setNewUserContact] = useState('');
  const [isApproveForm, setApproveForm] = useState(false);

  const isEmail = loginTypeName === 'email';
  const loginType = isEmail ? '1' : '2';

  return isApproveForm ? (
    <ApproveNewLoginForm
      loginType={loginType}
      newUserContact={newUserContact}
      setRenderContent={setRenderContent}
    />
  ) : (
    <UpdateUserContactsForm
      isEmail={isEmail}
      loginType={loginType}
      setApproveForm={setApproveForm}
      setNewUserContact={setNewUserContact}
      setRenderContent={setRenderContent}
    />
  );
};

export default UpdateUserContacts;
