import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { instanceFetch } from '../../utils/instanceFetch';
import { middlewareUrl } from '../../services/config';
import authHeader from '../../utils/authHeader';
import { Spin } from 'antd';

const DashboardContractInfo = ({ documentsArr, date, totalDate }) => {
  const [consumptionData, setConsumptionData] = useState([]);
  const [loader, setLoader] = useState(true);
  const formattedDate = Number(date);

  /*const balancePeriodArray = documentsArr.map((item) => {
    console.log(item)
    return item.balance_value.filter((item) => item.period === formattedDate);
  });

  const energyAmountArray = balancePeriodArray.map((item) =>
    item.map((item) => item.currentBalance)
  );
  const energyPositiveArray = energyAmountArray.map((item) => item.filter((num) => num >= 0));
  const energyNegativeArray = energyAmountArray.map((item) => item.filter((num) => num < 0));
  const sumOfPositive = energyPositiveArray.reduce(
    (sum, number) => Number(sum) + Number(number),
    0
  );
  const sumOfNegative = energyNegativeArray.reduce((sum, number) => Number(sum) + Number(number));
  const totalSum = sumOfNegative + sumOfPositive;

  const consumptionAmountArray = consumptionData.map((item) => {
    return item.consumptions.map((consumption) => consumption.amount);
  });

  const totalConsumption = consumptionAmountArray.flat().reduce((sum, number) => sum + number, 0);*/

  useEffect(() => {
    for (let dashboardItem of documentsArr) {
      instanceFetch
        .post(
          `${middlewareUrl}/e-b2b/consumption/search`,
          {
            accountId: dashboardItem.id,
            periodFrom: totalDate ? 202101 : formattedDate,
            periodTo: formattedDate,
          },
          authHeader()
        )
        .then((response) => {
          setConsumptionData((array) => [...array, ...response]);
          setLoader(false);
        });
    }
  }, []);

  return (
    <div className="dashboard-contract-info">
      <div className="dashboard-contract-info-block">
        <div className="dashboard-contract-info-block__title">Договори</div>
        <div className="dashboard-contract-info-block__inner black">
          <span className="dashboard-contract-info-block__amount">{documentsArr.length}</span>
        </div>
      </div>
      {/*<div className="dashboard-contract-info-block">
        <div className="dashboard-contract-info-block__title">Об’єм спожитої електроенергії</div>
        <div className="dashboard-contract-info-block__inner black">
          <div className="dashboard-contract-info-block__amount">
            {loader ? (
              <span>{<Spin />}</span>
            ) : (
              <span>{totalConsumption === 0 ? 0 : totalConsumption}</span>
            )}
            <span className="dashboard-contract-info-block__currency ">кВт</span>
          </div>
        </div>
      </div>
      <div className="dashboard-contract-info-block">
        <div className="dashboard-contract-info-block__title">Стан рахунку</div>
        <div className={`dashboard-contract-info-block__inner ${totalSum < 0 ? 'red' : 'green'}`}>
          <span
            className={`dashboard-contract-info-block__amount ${totalSum === 0 ? 'black' : ''}`}
          >
            {totalSum}
          </span>
          <span
            className={`dashboard-contract-info-block__currency ${totalSum === 0 ? 'black' : ''}`}
          >
            грн
          </span>
        </div>
      </div>*/}
    </div>
  );
};

export default DashboardContractInfo;


