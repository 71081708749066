import React, { useState } from 'react';
import Calendar from '../../common/Calendar/Calendar';
import Progressbar from '../../common/Progressbar/Progressbar';
import Button from '../../common/Button/Button';

const OrderGasCalendar = ({
  orderFilter,
  gasVolume,
  setCalendarGasLeft,
  setCalendarVisible,
  submitCalendarForm,
  daysData,
  errorMessage,
}) => {
  const [gasLeft, setGasLeft] = useState(0);
  const gasTotal = Number(gasVolume);
  const gasPercent = (gasLeft / gasTotal) * 100;

  setCalendarGasLeft(gasLeft);

  return (
    <div className="order-gas-calendar box-shadow">
      <div className="order-gas-calendar-body">
        <div className="order-gas-calendar-header">
          <img src="/icons/arrow-back-black.svg" onClick={setCalendarVisible} />
          <span>
            Сформувати замовлення на {orderFilter.filterMonth} {orderFilter.filterYear}
          </span>
        </div>
        <div className="order-gas-progress-bar">
          <Progressbar text={`РОЗПОДІЛЕНО ${gasLeft}/${gasTotal} м3`} progress={gasPercent} />
        </div>
        <Calendar
          daysData={daysData}
          orderFilter={orderFilter}
          gasVolume={gasVolume}
          setGasLeft={setGasLeft}
        />
        <div className="order-gas-calendar-footer">
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          <Button text="Сформувати замовлення" type="primary" onClick={submitCalendarForm} />
          {/*<button type='button' className="underline-link">Очистити календар</button>*/}
        </div>
      </div>
    </div>
  );
};

export default OrderGasCalendar;
