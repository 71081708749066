import React from 'react';
import cx from 'classnames';

const Button = (props) => {
  const {
    text,
    type = 'primary',
    block,
    iconLeft,
    iconRight,
    iconCenter,
    disabled,
    className,
    onClick,
    children,
    typeHtml,
    ...otherProps
  } = props;

  const buttonClassName = cx(
    'btn',
    type && `${type}`,
    block && 'block',
    disabled && 'disabled',
    className
  );

  return (
    <button
      onClick={onClick}
      type={typeHtml}
      className={buttonClassName}
      disabled={disabled}
      {...otherProps}
    >
      {iconLeft && <img className="btn__icon-left" src={`/icons/${iconLeft}.svg`} />}
      {text && <span className="btn__text">{text}</span>}
      {children}
      {iconCenter && <img className="btn__icon-center" src={iconCenter} />}
      {iconRight && <img className="btn__icon-right" src={iconRight} />}

      {/*TODO: use if Svg work*/}
      {/*{iconLeft && <Svg className="btn__icon-left" name={iconLeft} />}*/}
      {/*{iconCenter && <Svg className="btn__icon-center" name={iconCenter} />}*/}
      {/*{iconRight && <Svg className="btn__icon-right" name={iconRight} />}*/}
    </button>
  );
};

Button.defaultProps = {
  typeHtml: 'button',
};

export default Button;
