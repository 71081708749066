import React from 'react';
import Button from '../../common/Button/Button';
import StatusLabel from '../../common/StatusLabel/StatusLabel';
import { Tooltip } from 'antd';

const TariffPlan = () => {
  return (
    <div className="tariff-plan">
      <div className="tariff-plan-header">
        <div className="tariff-plan-header__title">Тарифні плани</div>
      </div>

      <div className="tariff-plan__content">
        <div className="tariff-plan-table">
          <div className="tariff-plan-table-header">
            <div className="tariff-plan-table-header__title empty " />
            <div className="tariff-plan-table-header__title">
              Газ без небалансів
              <Tooltip placement="top" title="Газ без небалансів">
                <img src="/icons/help.svg" alt="help-icon" />
              </Tooltip>
              <StatusLabel text="Активний" color="green" />
            </div>
            <div className="tariff-plan-table-header__title">Газ із ПСГ</div>
          </div>

          <div className="tariff-plan-table__inner">
            <div className="tariff-plan-table__col">
              <div className="tariff-plan-table__text price-title">Ціна за м³</div>
            </div>
            <div className="tariff-plan-table__col price">
              <div className="tariff-plan-table__value">7,99 грн.</div>
              <div className="tariff-plan-table__text month">щомісяця</div>
              <div className="tariff-plan-table__text year">фіксована протягом року</div>
            </div>
            <div className="tariff-plan-table__col price">
              <div className="tariff-plan-table__value">7,99 грн.</div>
              <div className="tariff-plan-table__text month">щомісяця</div>
              <div className="tariff-plan-table__text year">фіксована протягом року</div>
            </div>
          </div>

          <div className="tariff-plan-table__inner">
            <div className="tariff-plan-table__col">
              <div className="tariff-plan-table__text">Коли можна підключити</div>
            </div>
            <div className="tariff-plan-table__col">
              <div className="tariff-plan-table__text">У будь-який момент</div>
            </div>
            <div className="tariff-plan-table__col">
              <div className="tariff-plan-table__text">У будь-який момент</div>
            </div>
          </div>

          <div className="tariff-plan-table__inner">
            <div className="tariff-plan-table__col">
              <div className="tariff-plan-table__text">Термін дії</div>
            </div>
            <div className="tariff-plan-table__col">
              <div className="tariff-plan-table__text">12 місяців</div>
            </div>
            <div className="tariff-plan-table__col">
              <div className="tariff-plan-table__text">Необмежений</div>
            </div>
          </div>

          <div className="tariff-plan-table__inner">
            <div className="tariff-plan-table__col">
              <div className="tariff-plan-table__text">Умови підключення</div>
            </div>
            <div className="tariff-plan-table__col">
              <div className="tariff-plan-table__text">Без обмежень</div>
            </div>
            <div className="tariff-plan-table__col">
              <div className="tariff-plan-table__text">Без обмежень</div>

              <Button type="outline-primary" text="Залишити заявку" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TariffPlan;
