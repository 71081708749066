import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Button from '../../common/Button/Button';
import { Tooltip } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import ModalWrapper from '../../common/Modal/ModalWrapper';
import { middlewareUrl } from '../../services/config';
import { instanceFetch } from '../../utils/instanceFetch';
import authHeader from '../../utils/authHeader';
import { getUserInfo } from '../../store/actions/userInfo';
import { getGasB2BCompanies } from '../../store/actions/companies';
import { decode, encode } from 'base64-arraybuffer';
import { signaturePdf, signaturePdfMeta } from '../../assets/files/signPdf';

const GasB2B = ({ history }) => {
  const [isVisiblePopup, setVisiblePopup] = useState(false);
  const [isVisibleSignature, setVisibleSignature] = useState(false);
  const [signError, setSignError] = useState('');

  const handleModalVisible = () => {
    setVisiblePopup(!isVisiblePopup);
  };

  useEffect(() => {
    if (isVisiblePopup) {
      const uint = new Uint8Array(decode(signaturePdf));
      const asBase64String = true;
      const signAlgo = EndUser.SignAlgo.DSTU4145WithGOST34311;
      const signType = EndUser.SignType.CAdES_T;

      /*function base64Decode(str) {
        var str = window.atob(str);
        var len = str.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
          bytes[i] = str.charCodeAt(i);
        }
        return bytes;
      }*/

      const onConfirmKSPOperation = (event) => {
        var node = '';
        node += '<a href="' + event.url + '">';
        node +=
          '<img src="data:image/bmp;base64,' +
          event.qrCode +
          '" style="padding: 10px; background: white;">';
        node += '</a>';

        document.getElementById('qr-code-block').innerHTML = node;
        document.getElementById('qr-code-block').style.display = 'block';
      };

      let euSign = new EndUser(
        'sign-widget-parent' /* Ідентифікатор батківського елементу */,
        'sign-widget' /* Ідентифікатор елементу iframe */,
        'https://eu.iit.com.ua/sign-widget/v20220527/' /* URI для завантаження iframe */,
        EndUser.FormType.ReadPKey /* Тип форми iframe */
      );

      setTimeout(() => {
        signDocument();
      }, 10000);
      const signDocument = () => {
        euSign
          .ReadPrivateKey()
          .then((res) => {
            return euSign.AddEventListener(
              EndUser.EventType.ConfirmKSPOperation,
              onConfirmKSPOperation
            );
          })
          .then(() => {
            euSign
              .SignData(uint, false, asBase64String, signAlgo, null, signType)
              .then((sign) => {
                instanceFetch
                  .post(
                    `${middlewareUrl}/b2b/account-company/store`,
                    {
                      company: sign
                    },
                    authHeader()
                  )
                  .then((res) => {
                    console.log(res);
                    handleModalVisible();
                    setTimeout(() => {
                      window.location.reload();
                    }, 100);
                  });

                document.getElementById('qr-code-block').style.display = 'none';
                // document.getElementById('textAreaSign').value = sign;
              })
              .catch((e) => {
                document.getElementById('qr-code-block').style.display = 'none';
                console.log(
                  'Виникла помилка при підписі даних. ' + 'Опис помилки: ' + (e.message || e)
                );
                setSignError(
                  'Виникла помилка при підписі даних. ' + 'Опис помилки: ' + (e.message || e)
                );
              });
          })
          .catch(function (e) {
            console.log(
              'Виникла помилка при зчитуванні ос. ключа. ' + 'Опис помилки: ' + (e.message || e)
            );
          });
      };
    }
  }, [isVisiblePopup]);

  return (
    <div>
      {isVisibleSignature ? (
        <div className="documents-signature">
          <div className="documents-signature-header">
            <div className="documents-signature-header__title">Заява на приєднання</div>
            <div className="documents-signature-header__buttons">
              <Button
                type="primary"
                typeHtml="submit"
                text="Підписати через КЕП"
                onClick={() => {
                  setVisiblePopup(!isVisiblePopup);
                }}
              />
            </div>
          </div>
          <div className="documents-signature__iframe">
            <iframe
              src={signaturePdfMeta + signaturePdf}
              width="100%"
              height="532"
              frameBorder="0"
            />
            {/*<Iframe
          frameBorder='0'
          width='100%'
          height='532'
          src='https://cms.gaszbut.dev.aurocraft.com/uploads/39572642_5_2022_07_21_DOZ_62012187_14305909_37364a78f7.pdf'
        />*/}
          </div>
        </div>
      ) : (
        <div className="empty-profile">
          <div className="empty-profile__top">
            <img className="" src="/icons/confirm1.svg" />
            <div className="empty-profile__title">Ваш бізнес-аккаунт ще не активовано</div>
            <div className="empty-profile__subtitle">
              Якщо ви маєте існуючі контракти із нашою компанією, ви можете активувати їх
              самостійно:{' '}
            </div>
          </div>
          <div className="empty-profile-widget">
            <div className="empty-profile-widget__wrap">
              <div className="empty-profile-widget__header">Додати компанію</div>
              <div className="empty-profile-widget__subheader">
                Авторизуйте компанію шляхом підписання заяви на приєднання з допомогою вашого КЕП та
                отримуйте інформацію за діючими договорами
              </div>
            </div>
            <div className="empty-profile-widget__btn">
              <Button
                type="button"
                className="btn"
                onClick={() => setVisibleSignature(!isVisibleSignature)}
              >
                <img src="/icons/plus-black.svg" className="btn__icon-left" alt="plus-icon" />
                <span>Підписати заяву на приєднання</span>
              </Button>
            </div>
          </div>
        </div>
      )}

      {isVisiblePopup && (
        <ModalWrapper
          className="add-iit-form"
          isModalVisible={isVisiblePopup}
          handleClick={handleModalVisible}
          closeIcon={<img src="/icons/modal-close-black.svg" />}
        >
          <div id="sign-widget-parent" className="iit-widget"></div>
          <div id="qr-code-block"></div>
          <textarea id="textAreaData"></textarea>
          <div className="sign-modal-error">{signError}</div>
        </ModalWrapper>
      )}
    </div>
  );
};

const mapStateToProps = (store) => {
  const { application } = store;
  return {
    userInfo: application.userInfo,
    accountCompanies: application.accountCompanies,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserInfoAction: () => dispatch(getUserInfo()),
    getGasB2BCompanies: (payload) => dispatch(getGasB2BCompanies(payload)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GasB2B));
