import React, { useState } from 'react';
import moment from 'moment';
import FormDropdown from '../../common/FormDropdown/FormDropdown';
import { Table } from 'antd';

const yearsList = ['2022', '2021', '2020'];
const monthsList = moment.months();
const currentYear = moment().format('YYYY');
const currentMonth = moment().format('MMMM');

const columns = [
  {
    title: 'Скільки Ви заплатили Твоєму Газзбуту',
    dataIndex: 'paid',
    className: 'paid',
  },
  {
    title: 'Скільки ми передали розподілювачу',
    dataIndex: 'handed',
    className: 'handed',
  },
];

const data = [];

const renderCell = (amount) => (
  <>
    <span className="dashboard-distributor__amount">{amount}</span>
    <span className="dashboard-distributor__currency">грн.</span>
  </>
);

for (let i = 0; i < 1; i++) {
  data.push({
    key: i,
    paid: renderCell('20 100'),
    handed: renderCell('20 100'),
  });
}

const DistributorPaymentHistory = () => {
  const [currentFilterYear, setCurrentFilterYear] = useState(currentYear);
  const [currentFilterMonth, setCurrentFilterMonth] = useState(currentMonth);

  return (
    <div className="dashboard-distributor">
      <div className="dashboard-distributor__wrapper">
        <div className="dashboard-distributor__inner">
          <div className="dashboard-distributor__title">Історія оплати розподілювачу</div>

          <div className="dashboard-distributor__text">
            Ви можете не хвилюватися за оплату послуг розподілювача, адже ми передаємо йому 100%
            коштів.
          </div>
        </div>

        <div className="dashboard-distributor__filters">
          <form className="form-selected-filter">
            <FormDropdown
              options={yearsList}
              handleSelectChange={(value) => setCurrentFilterYear(value)}
            />
          </form>

          <form className="form-selected-filter">
            <FormDropdown
              options={monthsList}
              handleSelectChange={(value) => setCurrentFilterMonth(value)}
            />
          </form>
        </div>
      </div>

      <div className="custom-ant-table documents-bill">
        <Table bordered columns={columns} dataSource={data} />
      </div>
    </div>
  );
};

export default DistributorPaymentHistory;
